import {
  Box,
  Button,
  Text,
  Icon,
  Flex,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { HiPlus } from "react-icons/hi";
import { ReactComponent as NoDataIcon } from "src/assets/icon/no-data-icon.svg";
import { FiEdit3 } from "react-icons/fi";
import { useState, useEffect } from "react";
import AddWebhookComponent from "src/components/modal/add-webhook/add-webhook.modal";
import { ReactComponent as DeleteIcon } from "src/assets/icon/delete-icon.svg";
import { useGetListWebhookQuery } from "src/hook/webhook/webhook";
import { WebhookType } from "./webhook.type";
import ConfirmDeletWebhookComponent from "src/components/modal/confirm-delete-webhook/confirm-delete-webhook.modal";
import SuccessDeleteComponent from "src/components/modal/success-delete/success-delete.modal";
import EditWebhookComponent from "src/components/modal/edit-webhook/edit-webhook.modal";
import { useGetUserDetailQuery } from "src/hook/user/user";
import { useAppSelector } from "src/redux";

enum ModelType {
  ADD_WEBHOOK = "ADD_WEBHOOK",
  DELETE_WEBHOOK = "DELETE_WEBHOOK",
  CONFIRM_SUCCESS = "CONFIRM_SUCCESS",
  EDIT_WEBHOOK = "EDIT_WEBHOOK",
}

const WebhookComponent = () => {
  const [webhook, setWebhook] = useState<WebhookType | null>(null);
  const [listWebhoobs, setListWebhook] = useState<WebhookType[]>([]);
  const [modalOpen, setModelOpen] = useState<ModelType | "">("");
  const [filterParams, setFilterParams] = useState<string>("");

  const filterCompanies = useAppSelector((state) => state?.headerFilter?.companyId)
  const { data: userDetailData } = useGetUserDetailQuery();
  const { data: listWebhoobData, isLoading } = useGetListWebhookQuery({ companyId: filterParams });
  const handleActionWebhookClick = (
    webhook: WebhookType,
    modelType: ModelType
  ) => {
    setWebhook(() => webhook);
    setModelOpen(() => modelType);
  };
  const handleDeleteWebhookSuccess = () => {
    setModelOpen(() => ModelType.CONFIRM_SUCCESS);
  };
  const handleCloseModel = () => {
    setModelOpen(() => "");
  };

  useEffect(() => {
    setListWebhook(() => listWebhoobData?.data);
  }, [listWebhoobData]);

  useEffect(() => {
    if (filterCompanies?.length) {
      setFilterParams(filterCompanies.map((companyId) => `companyId[]=${companyId}`).join('&'))
    } else {
      setFilterParams("")
    }
  }, [filterCompanies])
  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#FBFBFB"}
        overflow={"hidden"}
        p={"74px 90px 74px 40px"}
      >
        <Flex
          mb={"15px"}
          alignItems={"center"}
          justifyContent={"space-between"}
          wrap={"wrap"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            Webhooks
          </Text>
          <Button
            style={{ display: userDetailData?.data?.role?.id !== 3 ? "inline-block" : "none" }}
            variant={"solid"}
            w={"300px"}
            mr={"8px"}
            bgColor={"#0A47BA"}
            color={"#FFFFFF"}
            borderRadius={"37px"}
            height={"55px"}
            fontSize={"20px"}
            _hover={{
              bgColor: "#0A47BA",
              opacity: "0.8",
            }}
            leftIcon={
              <Icon as={HiPlus} width={"22px"} height={"22px"} mr={"8px"} />
            }
            onClick={() => {
              setModelOpen(() => ModelType.ADD_WEBHOOK);
            }}
          >
            Add webhook URL
          </Button>
        </Flex>

        {isLoading && (
          <Flex w={"100%"} height={"100%"} justify={"center"} align={"center"}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        )}

        {listWebhoobs?.length === 0 && !isLoading ? (
          <Flex direction={"column"} alignItems="center">
            <Box my={"19px"}>
              <NoDataIcon />
            </Box>
            <Text color={"#BEC3C7"}>
              {"There's no data recorded cuerrently"}
            </Text>
          </Flex>
        ) : (
          <Flex direction={"column"} gap={"30px"} mt={"40px"}>
            <Text fontSize={"24px"} fontFamily={"Avenir Black"}>
              Webhooks documentation
            </Text>
            {listWebhoobs?.map((item) => {
              return (
                <Flex key={item?.id} gap={"52px"} flex={1}>
                  <Flex
                    height={"45px"}
                    direction={"column"}
                    justifyContent={"center"}
                  >
                    <Text
                      fontSize={"18px"}
                      fontFamily={"Avenir Heavy"}
                      w={"150px"}
                    >
                      Webhooks URL
                    </Text>
                  </Flex>
                  <Flex gap={"30px"} minW={"0px"} w={"100%"}>
                    <Box minW={"0px"} flexShrink={2} flexBasis={"100%"}>
                      <Box
                        bgColor={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        padding={"10px 16px"}
                        border={`2px solid ${item?.active ? "#0A47BA" : "red"}`}
                      >
                        <Tooltip label={item?.url}>
                          <Text
                            fontFamily={"Avenir Book"}
                            fontSize={"18px"}
                            lineHeight={"25px"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                          >
                            {item?.url}
                          </Text>
                        </Tooltip>
                      </Box>
                      <Text mt={"8px"}>
                        {item?.active
                          ? "This url is subcribed to 3 events"
                          : "This Webhook URL is Disabled"}
                      </Text>
                    </Box>
                    <Box
                      display={userDetailData?.data?.role?.id !== 3 ? "flex" : "none"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      color="#0A47BA"
                      height={"45px"}
                      onClick={() => {
                        handleActionWebhookClick(item, ModelType.EDIT_WEBHOOK);
                      }}
                    >
                      <Icon
                        as={FiEdit3}
                        width={"20px"}
                        height={"20px"}
                        mr={"5px"}
                      />
                      <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
                        Edit
                      </Text>
                    </Box>
                    <Box
                      display={userDetailData?.data?.role?.id !== 3 ? "flex" : "none"}
                      alignItems={"center"}
                      cursor={"pointer"}
                      color={"#E74D3D"}
                      height={"45px"}
                      onClick={() => {
                        handleActionWebhookClick(
                          item,
                          ModelType.DELETE_WEBHOOK
                        );
                      }}
                    >
                      <Icon
                        as={DeleteIcon}
                        width={"20px"}
                        height={"20px"}
                        mr={"5px"}
                      />
                      <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
                        Delete
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        )}
      </Box>

      {modalOpen === ModelType.ADD_WEBHOOK && (
        <AddWebhookComponent onClose={handleCloseModel} isOpen={true} />
      )}
      <ConfirmDeletWebhookComponent
        idWebhook={webhook?.id}
        isOpen={modalOpen === ModelType.DELETE_WEBHOOK}
        onClose={handleCloseModel}
        setOpenSuccess={handleDeleteWebhookSuccess}
      />
      <SuccessDeleteComponent
        isOpen={modalOpen === ModelType.CONFIRM_SUCCESS}
        onClose={handleCloseModel}
        statusSuccess={"Webhook is deleted successfully"}
      />
      {modalOpen === ModelType.EDIT_WEBHOOK && (
        <EditWebhookComponent
          webhook={webhook as WebhookType}
          isOpen={modalOpen === ModelType.EDIT_WEBHOOK}
          onClose={handleCloseModel}
        />
      )}
    </Box>
  );
};
export default WebhookComponent;
