export enum AuthApi {
  LOGIN = "/auth/login",
  SIGN_UP = "/auth/register",
  FORGOT_PASSWORD = "/auth/forgot-password",
  CHECK_LINK_RESET = "/auth/reset-password/check",
  RESET_PASSWORD = "/auth/reset-password"
}

export interface AUTH_BODY {
  email: string;
  password: string;
}

export interface AUTH_FORGOT_PASSWORD_BODY {
  email: string;
}

export interface AUTH_CHECK_LINK_RESET_BODY {
  token: string;
  email: string;
}

export interface AUTH_RESET_PASSWORD_BODY extends AUTH_BODY {
  token: string;
}