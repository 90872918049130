export const CONNECTOR_TYPE = [
    {id: 1, value: "Type 2"},
    {id: 2, value: "J1772"},
    {id: 3, value: "CCS1"},
    {id: 4, value: "CCS2"},
    {id: 5, value: "CHAdeMO"},
]

export const CONNECTOR_FORMAT = [
    {id: 1, value: "Cable"},
    {id: 2, value: "Socket"}
]
   

export const CONNECTOR_POWER_TYPE = [
    {id: 1, value: "DC"},
    {id: 2, value: "AC Single Phase"},
    {id: 3, value: "AC Three Phase"}
]