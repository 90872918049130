import * as yup from "yup";

export const schema = yup.object().shape({
      id: yup.string().test('my test',
      'This field is required',
      (text) => {
          if (text === "") {
              return false
          } else {
              return true
          }
      })
    })


