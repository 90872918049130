export enum DashboardApi {
    GET_DASHBOARD = "/session/dashboard",
    GET_STATION_USAGE_DASHBOARD = "/session/dashboard/station-usage",
  }
  
  
  
//   export interface DashboardType {
//     companyName?: string;
//     createdAt: string;
//     email?: string;
//     id: string;
//     phoneNumber?: string | number;
//     updatedAt: string
//   }
  