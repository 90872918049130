import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Select,
  Text,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { useGetSessionQuery } from "src/hook/playground/playground";
import { useForm } from "react-hook-form";
import { FormValues } from "./playground.type";
import { useAppDispatch } from "src/redux";
import { schema } from "./playground.schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { getDataPlayground } from "src/hook/playground/playground";

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const StopSessionComponent = (props: Props) => {
  const dispatch = useAppDispatch();
  const { isOpen, onClose } = props;
  const cancelRef = useRef();
  const { data } = useGetSessionQuery();
  const [listSession, setListSession] = useState<any>([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    dispatch(getDataPlayground(data.id));
    onClose();
  };

  useEffect(() => {
    setListSession(data?.data ? data?.data : []);
  }, [data]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef as any}
      onClose={onClose}
    >
      <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
      <AlertDialogContent
        maxH={"83%"}
        minW={"56%"}
        color={"#171E38"}
        bg={"#F2F5FC"}
        top={"30%"}
        m={0}
        overflow={"hidden"}
      >
        <AlertDialogCloseButton
          _focusVisible={{
            outline: "none",
            border: "none",
          }}
          size={"lg"}
        />
        <AlertDialogBody pt={"38px"} pl={"40px"} pb={"0px"}>
          <Box alignItems={"center"} gap={"24px"}>
            <Text fontSize="40px" fontFamily={"Avenir Black"}>
              Select Session
            </Text>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box maxW={"90%"} borderRadius={"8px"} mt={"10px"}>
              <Box color={"#171E38"} top={"50%"} m={0}>
                <Box pt={"30px"} pb={"0px"}>
                  <Box
                    display={"flex"}
                    mb={"40px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily={"Avenir Heavy"}
                      fontSize={"18px"}
                      w={"25%"}
                    >
                      Session
                    </Text>
                    <Flex flexDirection={"column"} w={"100%"}>
                      <Select
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        variant={"base"}
                        size={"lg"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        {...register("id")}
                      >
                        <option
                          value={""}
                          style={{
                            fontSize: "18px",
                            fontFamily: "Avenir Book",
                          }}
                        >
                          Select session
                        </option>
                        {listSession.map((item) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            style={{
                              fontSize: "18px",
                              fontFamily: "Avenir Book",
                            }}
                          >
                            [{item?.status}] {item?.id} ({item?.createdAt})
                          </option>
                        ))}
                      </Select>
                      {errors?.id && (
                        <Text
                          fontSize={"12px"}
                          mt={"4px"}
                          ml={"4px"}
                          color={"red.400"}
                          fontFamily={"Avenir Medium"}
                        >
                          {errors?.id.message}
                        </Text>
                      )}
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              mt={"40px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontFamily={"Avenir Black"}
              mb={"25px"}
            >
              <Button
                type="submit"
                variant={"solid"}
                bg={"#0A47BA"}
                w={"191px"}
                height={"55px"}
                borderRadius={"37px"}
                mr={"16px"}
                color={"#FFFFFF"}
                fontSize={"20px"}
                _hover={{
                  bg: "#0A47BA",
                  opacity: 0.8,
                }}
                _disabled={{
                  bg: "#BEC3C7",
                  _hover: {
                    bg: "#BEC3C7",
                    opacity: "1",
                  },
                }}
              >
                Get session
              </Button>
            </Box>
          </form>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default StopSessionComponent;
