import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axios from 'axios';

import { baseQueryWithReauth } from "src/services";
import {
  BODY_CREATE_CONNECTOR,
  BODY_UPDATE_CONNECTOR,
  BODY_SIMULATE_CONNECTOR,
  ConnectorApi,
  ICHARGE_STATION_PARAMS,
} from "./connector.type";

export const connectorAPI = createApi({
  reducerPath: "connectorAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getConnector: builder.query<any, void>({
      query: (id) => ({
        url: `${ConnectorApi.GET_CONNECTOR}/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),

    updateConnector: builder.mutation<
      any,
      { body: BODY_UPDATE_CONNECTOR; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${ConnectorApi.UPDATE_CONNECTOR}/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    simulateConnector: builder.mutation<
      any,
      { body: BODY_SIMULATE_CONNECTOR; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${ConnectorApi.SIMULATE_CONNECTOR}/${id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    createConnector: builder.mutation<any, { body: BODY_CREATE_CONNECTOR; id: string | undefined }>({
      query: ({ body, id }) => ({
        url: `${ConnectorApi.CREATE_CONNECTOR}/${id}`,
        method: "POST",
        body: body,
      }),
    }),
    deleteConnector: builder.mutation<any, string>({
      query: (id) => ({
        url: `${ConnectorApi.DELETE_CONNECTOR}/${id}`,
        method: "DELETE",
      }),
    }),
    getListConnectorByChargeStation: builder.query<any, ICHARGE_STATION_PARAMS[]>({
      query: (items) => {
        let temp
        if (items.every(item => !item.check)) {
          temp = items
        } else {
          temp = items?.filter(item => item.check)
        }
        let result = ''
        temp?.forEach((item, index) => {
          if (index === 0) {
            result += `?chargeStationId[]=${item.value}`
            return
          }
          result += `&chargeStationId[]=${item.value}`
        })
        return {
          url: `${ConnectorApi.GET_LIST_CONNECTOR_BY_CHARGE_STATION}${result}`,
          method: "GET",
        }
      },
    })
  }),
});

export const {
  useGetConnectorQuery,
  useCreateConnectorMutation,
  useUpdateConnectorMutation,
  useSimulateConnectorMutation,
  useDeleteConnectorMutation,
  useLazyGetListConnectorByChargeStationQuery
} = connectorAPI;

export const getConnectorQRCode = async (id: string) => {
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${ConnectorApi.GET_QR_CODE}/${id}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}