import { Box, Button, Text } from "@chakra-ui/react";
import { ReactComponent as LogoutIcon } from "src/assets/icon/logout-icon.svg";

// import { ReactComponent as CopyIcon } from "src/assets/icon/copy-icon.svg";
import { useGetUserDetailQuery } from "src/hook/user/user";

const AccountSettingComponent = () => {

  const { data } = useGetUserDetailQuery();

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#FBFBFB"}
        overflow={"hidden"}
        p={"74px 40px 74px 40px"}
      >
        <Box
          mb={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            Account details
          </Text>

          <Button
            variant={"solid"}
            mr={"8px"}
            bgColor={"#0A47BA"}
            color={"#FFFFFF"}
            borderRadius={"37px"}
            height={"55px"}
            w={"154px"}
            fontSize={"20px"}
            _hover={{
              bgColor: "#0A47BA",
              opacity: "0.8",
            }}
            onClick={() => {
              localStorage.removeItem("token");
              window.location.pathname = "/login"
            }}
          >
            <LogoutIcon />
            <Text ml={"8px"}>Log out</Text>
          </Button>
        </Box>

        {data?.data && (
          <Box>
            <Box pb={"40px"}
            // borderBottom={"1px solid #BEC3C7"}
            >
              <Box color={"#020D21"} fontSize={"18px"}>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"17%"}>
                    Full name
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {data?.data.lastName && data?.data.firstName
                      ? `${data?.data.firstName} ${data?.data.lastName}`
                      : "Default"}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"17%"}>
                    Email
                  </Text>
                  <Text
                    fontFamily={"Avenir Book"}
                    maxW={"40%"}
                    wordBreak={"break-word"}
                  >
                    {data?.data?.email}
                  </Text>
                </Box>
                {/* <Box display={"flex"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"17%"}>
                    User ID
                  </Text>
                  <Box maxW={"50.5%"}>
                    <Text
                      fontFamily={"Avenir Book"}
                      wordBreak={"break-word"}
                      mb={"16px"}
                    >
                      {data?.data.id}
                    </Text>
                    <Button
                      fontSize={"20px"}
                      fontFamily={"Avenir Black"}
                      w={"262px"}
                      h={"55px"}
                      borderRadius={"28px"}
                      border={"1px solid #1f2c4f"}
                      bg={"unset"}
                    >
                      <CopyIcon
                        stroke="#020D21"
                        width={"27px"}
                        height={"27px"}
                      />
                      <Text ml={"28px"}>Copy user ID</Text>
                    </Button>
                  </Box>
                </Box> */}
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"17%"}>
                    Role
                  </Text>
                  <Text
                    fontFamily={"Avenir Book"}
                    maxW={"40%"}
                    wordBreak={"break-word"}
                  >
                    {data?.data.role.name}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"17%"}>
                    Organisation name
                  </Text>
                  <Text
                    fontFamily={"Avenir Book"}
                    maxW={"40%"}
                    wordBreak={"break-word"}
                  >
                    {data?.data?.company?.companyName
                      ? data?.data?.company.companyName
                      : "madison-technology.com"}
                  </Text>
                </Box>
                {/* <Box display={"flex"} mb={"25px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"17%"}>
                    Organisation ID
                  </Text>
                  <Box maxW={"50.5%"}>
                    <Text
                      fontFamily={"Avenir Book"}
                      wordBreak={"break-word"}
                      mb={"16px"}
                    >
                      {data?.data?.company?.id}
                    </Text>
                    <Button
                      fontSize={"20px"}
                      fontFamily={"Avenir Black"}
                      w={"262px"}
                      h={"55px"}
                      borderRadius={"28px"}
                      border={"1px solid #1f2c4f"}
                      bg={"unset"}
                    >
                      <CopyIcon
                        stroke="#020D21"
                        width={"27px"}
                        height={"27px"}
                      />
                      <Text ml={"28px"}>Copy client key</Text>
                    </Button>
                  </Box>
                </Box> */}
                {/* <Text
                  color={"#E74D3D"}
                  fontSize={"18px"}
                  fontFamily={"Avenir Medium"}
                  textDecoration={"underline"}
                >
                  Reset password
                </Text> */}
              </Box>
            </Box>
            {/* <Box>
              <Text
                fontSize={"38px"}
                lineHeight={"52px"}
                fontWeight={"500"}
                color={"#171E38"}
                w={"fit-content"}
                fontFamily={"Avenir Black"}
                my={"22.5px"}
              >
                Organisation details
              </Text>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"17%"}>
                  Name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Input
                  w={"35%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  value={
                    data?.data.company?.companyName
                      ? data?.data.company?.companyName
                      : "madison-technology.com"
                  }
                />
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"17%"}>
                  Email<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Input
                  w={"35%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  value={
                    data?.data?.company?.email
                      ? data?.data?.company?.email
                      : "madison-technology.com"
                  }
                />
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"17%"}>
                  Phone
                </Text>
                <Input
                  w={"35%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  value={
                    data?.data?.company?.phoneNumber
                      ? data?.data?.company?.phoneNumber
                      : ""
                  }
                />
              </Box>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"17%"}>
                  Country
                </Text>
                <Input
                  w={"35%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                />
              </Box>
              <Box display={"flex"} mb={"16px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"17%"}>
                  Payment plan
                </Text>
                <Input
                  w={"35%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                />
              </Box>
              <Button
                ml={"17%"}
                fontSize={"20px"}
                fontFamily={"Avenir Black"}
                w={"262px"}
                h={"55px"}
                borderRadius={"28px"}
                border={"1px solid #1f2c4f"}
                bg={"unset"}
              >
                <Text>Update payment plan</Text>
              </Button>
            </Box> */}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AccountSettingComponent;
