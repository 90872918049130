import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useSimulateConnectorMutation } from "src/hook/connector/connector";
import { getToastAttribute, ToastAttribute } from "src/constants";
import { simulateActions } from "src/hook/connector/connector.type";

interface Props {
  currentConnector: string;
  currentStatus: string;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const checkDisabledStatus = (currentStatus: string, nextStatus: string) => {
  switch (currentStatus) {
    case 'Available': if (nextStatus === 'Preparing' || nextStatus === 'Faulted') {
      return false
    } else {
      return true
    }
    case 'Preparing': if (nextStatus === 'Available' || nextStatus === 'Faulted') {
      return false
    } else {
      return true
    }
    case 'Finishing': if (nextStatus === 'Available' || nextStatus === 'Faulted') {
      return false
    } else {
      return true
    }
    case 'Faulted': if (nextStatus === 'Available') {
      return false
    } else {
      return true
    }
    case 'Charging': if (nextStatus === 'Available' || nextStatus === 'Faulted') {
      return false
    } else {
      return true
    }
  }
}

const SimulateEventComponent = (props: Props) => {
  const { isOpen, onClose, currentConnector, currentStatus, refetch } = props;
  const [status, setStatus] = useState("");
  const cancelRef = useRef();

  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const [simulateConnector] = useSimulateConnectorMutation();

  const handleSimulateEvent = () => {
    simulateConnector({ body: { status: status }, id: currentConnector })
      .unwrap()
      .then(() => {
        showToast({
          title: "Connector update.",
          dis: "Connector is successfully updated.",
        });
        refetch();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Connector update.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  }

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"} pb={"0px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Simulate event
            </Text>

            <Box display={"flex"} mb={"40px"} alignItems={"center"}>
              <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                Action
              </Text>

              <Select
                w={"42%"}
                border={"0px"}
                bg={"white"}
                borderRadius={"10px"}
                name={"role"}
                variant={"base"}
                size={"lg"}
                height={"45px"}
                fontFamily={"Avenir Book"}
                fontSize={"18px"}
                placeholder="Select an event"
                onChange={(e) => setStatus(e.target.value)}
              >
                {simulateActions.map((action) => {
                  return <option
                    style={{
                      fontSize: "18px",
                      fontFamily: "Avenir Book",
                      marginBottom: "10px",
                    }}
                    key={action.value}
                    value={action.value}
                    disabled={checkDisabledStatus(currentStatus, action.value)}
                  >
                    {action.label}
                  </option>
                })}

              </Select>
            </Box>
            <Box display={"flex"} justifyContent={"center"} mb={"25px"}>
              <Button
                type="submit"
                variant={"solid"}
                bg={"#0A47BA"}
                w={"191px"}
                height={"55px"}
                borderRadius={"37px"}
                mr={"16px"}
                color={"#FFFFFF"}
                fontSize={"20px"}
                _hover={{
                  bg: "#0A47BA",
                  opacity: 0.8,
                }}
                onClick={handleSimulateEvent}
                isDisabled={!status}
              >
                Submit
              </Button>
            </Box>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SimulateEventComponent;
