import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { RowType } from "src/template/network/charge-stations/charge-stations.type";
import { getQRCode } from "src/hook/charge-station/charge-station";
interface Props {
  idCharge: RowType | null;
  isOpen: boolean;
  onClose: () => void;
}

const ChargeStationDetailsComponent = (props: Props) => {
  const toast = useToast();
  const { isOpen, onClose, idCharge } = props;
  const cancelRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Charge station details
            </Text>
            {idCharge && (
              <Box color={"#2E3F51"} fontSize={"18px"}>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"} flexShrink={0}>
                    Name
                  </Text>
                  <Text fontFamily={"Avenir Book"}>{idCharge?.name}</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"} flexShrink={0}>
                    Location
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {idCharge?.location?.name}
                  </Text>
                </Box>
                <Box h={"1px"} w="full" my={"20px"} bgColor={"#BEC3C7"}></Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Connection URL
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {idCharge?.connectionUrl}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Endpoint
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {idCharge?.staticEndpoint}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Security setting
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {JSON.parse(idCharge?.security ?? "{}")?.type}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Manufacturer
                  </Text>
                  <Text fontFamily={"Avenir Book"}>ABB</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Serial number
                  </Text>
                  <Text fontFamily={"Avenir Book"}>-</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Firmware
                  </Text>
                  <Text fontFamily={"Avenir Book"}>1.0.0</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    ICCID
                  </Text>
                  <Text fontFamily={"Avenir Book"}>-</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"24px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    IMSI
                  </Text>
                  <Text fontFamily={"Avenir Book"}>-</Text>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontFamily={"Avenir Black"}
                  mb={"25px"}
                >
                  <Button
                    variant={"outline"}
                    borderColor={"#020E20"}
                    w={"300px"}
                    height={"54px"}
                    borderRadius={"37px"}
                    mr={"16px"}
                    fontSize={"20px"}
                    onClick={() => {
                      setIsLoading(() => true);
                      getQRCode(idCharge?.id)
                        .then((blob) => {
                          const blogUrl = window.URL.createObjectURL(
                            new Blob([blob])
                          );
                          const fileName = (idCharge?.id || "") + ".png";
                          const aTag = document.createElement("a");
                          aTag.href = blogUrl;
                          aTag.setAttribute("download", fileName);
                          document.body.appendChild(aTag);
                          aTag.click();
                          aTag.remove();
                          setIsLoading(() => false);
                        })
                        .catch(() => {
                          toast({
                            title: "Download QR Code",
                            description: "Download QR Code failed",
                          });
                          setIsLoading(() => false);
                        });
                    }}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  >
                    Download QR code
                  </Button>
                  {/* <Button
                    type="submit"
                    variant={"solid"}
                    bg={"#0A47BA"}
                    w={"30%"}
                    height={"54px"}
                    borderRadius={"37px"}
                    mr={"16px"}
                    color={"#FFFFFF"}
                    fontSize={"20px"}
                    _hover={{
                      bg: "#0A47BA",
                      opacity: 0.8,
                    }}
                  >
                    Download as CSV
                  </Button> */}
                </Box>
              </Box>
            )}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ChargeStationDetailsComponent;
