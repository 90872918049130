import { Box } from "@chakra-ui/react";
import { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import TemplateLayout from "src/components/layout";
import LoginComponent from "src/template/login";

const LoginPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) navigate("/network/location");
  }, []);
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Login | Documents</title>
        </Helmet>
        <TemplateLayout>
          <Box height={"calc((100vh - 86px) - 70px)"}>
            <Box
              height={"full"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <LoginComponent />
            </Box>
          </Box>
        </TemplateLayout>
      </HelmetProvider>
    </>
  );
};

export default LoginPage;
