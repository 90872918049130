import {
  Box,
  Button,
  Text,
  Icon,
  Switch,
  useDisclosure,
  useOutsideClick,
  Tooltip,
  useToast,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useMemo, useState, useRef, useEffect } from "react";
import TableComponent from "src/components/custom-table";
import { HiPlus, HiDesktopComputer } from "react-icons/hi";
import { ReactComponent as DownloadIcon } from "src/assets/icon/download-icon.svg";
import { ReactComponent as RefreshIcon } from "src/assets/icon/refresh-icon.svg";
import { ReactComponent as OptionIcon } from "src/assets/icon/option-icon.svg";
import { RowType, ConnectorType } from "./charge-stations.type";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import AddNewChargeStation from "../../../components/modal/add-new-charge-station/add-new-charge-stations.modal";
import AddNewConnectorComponent from "../../../components/modal/add-connector/add-new-connector.modal";
import EditChargeStationComponent from "../../../components/modal/edit-charge-station/edit-charge-station.modal";
import SimulateEventComponent from "src/components/modal/simulate-event/simulate-event.modal";
import ChargeStationDetailsComponent from "src/components/modal/charge-station-details/charge-station-details.modal";
import ConfirmDeleteChargeComponent from "src/components/modal/confirm-delete-charge/confirm-delete-charge.modal";
import SuccessDeleteComponent from "src/components/modal/success-delete/success-delete.modal";
import EditConnectorComponent from "../../../components/modal/edit-connector/edit-connector.modal";
import ConfirmDeleteConnectorComponent from "../../../components/modal/confirm-delete-connector/confirm-delete-connector.modal";
import {
  useStartSessionMutation,
  useStopSessionMutation,
} from "src/hook/session/session";
import {
  useAttachSimulatorMutation,
  useGetListChargeStationQuery,
  useTerminateSimulatorMutation,
  // useUpdateChargeStationMutation,
  downloadChargeCsv,
} from "src/hook/charge-station/charge-station";
import {
  getToastAttribute,
  PaginationType,
  ToastAttribute,
} from "src/constants";
import { useNavigate } from "react-router-dom";
import { downloadCsv } from "src/constants/download-csv";
import { useGetUserDetailQuery } from "src/hook/user/user";
import { useAppSelector } from "src/redux";
import ConnectorDetailsComponent from "src/components/modal/connector-details/connector-details.modal";

enum ModalType {
  SIMULATE_EVENT = "SIMULATE_EVENT",
  EDIT_CHARGE = "EDIT_CHARGE",
  ADD_CONNECTOR = "ADD_CONNECTOR",
  EDIT_CONNECTOR = "EDIT_CONNECTOR",
  CHARGE_DETAIL = "CHARGE_DETAIL",
  CONNECTOR_DETAIL = "CONNECTOR_DETAIL",
  CONFIRM_DELETE_CHARGE = "CONFIRM_DELETE_CHARGE",
  CONFIRM_DELETE_CONNECTOR = "CONFIRM_DELETE_CONNECTOR",
  DELETE_CHARGE_SUCCESS = "DELETE_CHARGE_SUCCESS",
  DELETE_CONNECTOR_SUCCESS = "DELETE_CONNECTOR_SUCCESS",
}

const ChargeStationsComponent = () => {
  const navigate = useNavigate();
  const [listChargeStation, setListChargeStation] = useState<RowType[]>([]);
  const [listExpand, setListExpand] = useState<string[]>([]);
  const [connectorId, setConnectorId] = useState<ConnectorType | null>(null);
  const [simulator, setSimulator] = useState<string>("");
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState<ModalType | "">("");
  const [currentConnector, setCurrentConnector] = useState<any>();
  const [currentStatus, setCurrentStatus] = useState("");
  const [optionId, setOptionId] = useState("-1");
  const [idCharge, setIdcharge] = useState<RowType | null>(null);
  const [filterParams, setFilterParams] = useState("");
  const [pageIndex, setPageIndex] = useState<PaginationType>({
    page: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageCount: 0,
  });
  const ref = useRef();

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startSession, { isLoading: startSessionLoading }] =
    useStartSessionMutation();
  const [stopSession, { isLoading: stopSessionLoading }] =
    useStopSessionMutation();
  // TODO: uncomment when integrate API
  // const [updateChargeStation, { isLoading: updatePublic }] =
  //   useUpdateChargeStationMutation();

  const filterCompanies = useAppSelector((state) => state?.headerFilter?.companyId)
  const { data: userDetailData } = useGetUserDetailQuery();

  const {
    data,
    isFetching,
    refetch: getListChargeStation,
  } = useGetListChargeStationQuery({
    page: pageIndex.page,
    companyId: filterParams
  });

  const [attachSimulator] = useAttachSimulatorMutation();
  const [terminateSimulator] = useTerminateSimulatorMutation();

  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const handleCloseModal = () => {
    setModelOpen("");
  };

  useOutsideClick({
    ref: ref as any,
    handler: () => {
      optionId !== "-1" && setOptionId("-1");
      // connectorId !== null && setConnectorId(null);
    },
  });

  const openMoreOption = (id: string) => {
    if (id === optionId) {
      setOptionId("-1");
    } else {
      setOptionId(id);
    }
  };

  const handleDownloadCsv = async () => {
    setIsDownloadLoading(true);
    await downloadChargeCsv(filterParams)
      .then((data) => {
        downloadCsv(data, "charge-station");
      })
      .catch(() => {
        showToast({
          title: "Export data",
          dis: "Export data failed",
          sts: "error",
        });
      });
    setIsDownloadLoading(false);
  };

  useEffect(() => {
    if (filterCompanies?.length) {
      setFilterParams(filterCompanies.map((companyId) => `companyId[]=${companyId}`).join('&'))
    } else {
      setFilterParams("")
    }
  }, [filterCompanies])

  const actionComponent = (row: RowType) => {
    const isCanTerminate = simulator === row?.id && row?.isSimulator;
    const isCannotAddCOnnector =
      (row?.isSimulator && row?.connectors?.length >= 1) ||
      row?.connectors?.length >= 3;
    const handleTerminate = (row: RowType) => {
      if (isCanTerminate) {
        terminateSimulator(row?.id)
          .unwrap()
          .then(() => {
            showToast({
              title: "Terminate Simulator",
              dis: "Terminate Simulator Successfully.",
            });
            setListChargeStation((pre: RowType[]) => {
              return pre.map((item) => {
                if (item?.id === row?.id) {
                  return { ...item, online: false };
                }
                return { ...item };
              });
            });
            setSimulator("");
          })
          .catch((err) => {
            showToast({
              title: "Terminate Simulator",
              dis: err?.data?.message,
              sts: "error",
            });
          });
        return;
      }
      if (simulator === "" && row?.isSimulator) {
        attachSimulator(row?.id)
          .then(() => {
            showToast({
              title: "Attach Simulator",
              dis: "Attach Simulator successfully.",
              sts: "success",
            });
            setListChargeStation((pre: RowType[]) => {
              return pre.map((item) => {
                if (item?.id === row?.id) {
                  return { ...item, online: true };
                }
                return { ...item };
              });
            });
            setSimulator(() => row?.id);
          })
          .catch((err) => {
            showToast({
              title: "Attach Simulator",
              dis: err?.data?.message,
              sts: "error",
            });
          });
      }
    };

    return (
      <Flex color={"#0A47BA"} justify={"space-between"}>
        {userDetailData?.data?.role?.id !== 3 && (
          <Flex>
            <Box
              display={"flex"}
              alignItems={"center"}
              mr={"20px"}
              cursor={"pointer"}
            >
              <Icon as={FiEdit3} width={"20px"} height={"20px"} mr={"5px"} />
              <Text
                fontSize={"18px"}
                fontFamily={"Avenir Heavy"}
                onClick={(e) => {
                  e.stopPropagation();
                  setIdcharge(row);
                  setModelOpen(ModalType.EDIT_CHARGE);
                  setOptionId("");
                }}
              >
                Edit
              </Text>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              mr={"20px"}
              cursor={"pointer"}
              onClick={() => {
                setIdcharge(row);
                setModelOpen(ModalType.CONFIRM_DELETE_CHARGE);
              }}
            >
              <Icon
                as={RiDeleteBinLine}
                width={"20px"}
                height={"20px"}
                mr={"5px"}
              />
              <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
                Delete
              </Text>
            </Box>
          </Flex>
        )}
        <Menu autoSelect={false}>
          <MenuButton as={Box} cursor={"pointer"}>
            <OptionIcon
              stroke="#0A47BA"
              onClick={() => openMoreOption(row?.id)}
              cursor={"pointer"}
            />
          </MenuButton>

          {(isCanTerminate || !simulator) && row?.isSimulator ? (
            <MenuList
              w={"320px"}
              outline={"none"}
              right={"60px"}
              border={"none"}
              boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              p={"5px"}
              color={"#2E3F51"}
            >
              {userDetailData?.data?.role?.id !== 3 &&
                <MenuItem
                  mb={"2px"}
                  pl={"17px"}
                  fontFamily={"Avenir Book"}
                  py={"10px"}
                  position={"relative"}
                  borderRadius={"8px"}
                  transition={"all 0.3s ease"}
                  color={isCannotAddCOnnector ? "#BEC3C7" : ""}
                  _hover={{
                    bgColor: "rgb(195 213 44 / 20%)",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIdcharge(row);
                    if (!isCannotAddCOnnector) {
                      setModelOpen(ModalType.ADD_CONNECTOR);
                      setOptionId("-1");
                      setListExpand([...listExpand, row?.id]);
                    }
                  }}
                >
                  Add connector
                </MenuItem>}
              <MenuItem
                mb={"2px"}
                pl={"17px"}
                fontFamily={"Avenir Book"}
                py={"10px"}
                borderRadius={"8px"}
                position={"relative"}
                _hover={{
                  bgColor: "rgb(195 213 44 / 20%)",
                }}
                color={
                  (isCanTerminate || !simulator) && row?.isSimulator
                    ? ""
                    : "#BEC3C7"
                }
                onClick={() => handleTerminate(row)}
              >
                {isCanTerminate ? "Terminate simulator" : "Attach simulator"}
              </MenuItem>
            </MenuList>
          ) : (
            <>
              {userDetailData?.data?.role?.id !== 3 &&
                <MenuList
                  w={"320px"}
                  outline={"none"}
                  right={"60px"}
                  border={"none"}
                  boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
                  p={"5px"}
                  color={"#2E3F51"}
                >
                  <MenuItem
                    mb={"2px"}
                    pl={"17px"}
                    fontFamily={"Avenir Book"}
                    py={"10px"}
                    position={"relative"}
                    borderRadius={"8px"}
                    transition={"all 0.3s ease"}
                    color={isCannotAddCOnnector ? "#BEC3C7" : ""}
                    _hover={{
                      bgColor: "rgb(195 213 44 / 20%)",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIdcharge(row);
                      if (!isCannotAddCOnnector) {
                        setModelOpen(ModalType.ADD_CONNECTOR);
                        setOptionId("-1");
                        setListExpand([...listExpand, row?.id]);
                      }
                    }}
                  >
                    Add connector
                  </MenuItem>
                </MenuList>
              }
            </>
          )}
        </Menu>
      </Flex>
    );
  };

  const statusAction = (row: RowType) => {
    return (
      <Box fontSize={"18px"}>
        <Text
          w={"88px"}
          fontFamily={"Avenir Medium"}
          color={row?.online ? "#0A47BA" : "#E74D3D"}
        >
          {row?.online ? "Online" : "Offline"}
        </Text>
      </Box>
    );
  };

  // const accessAction = (row: RowType) => {
  //   const handlePublic = () => {
  //     const body = {
  //       location: { id: row?.location?.id },
  //       security: JSON.parse(row?.security),
  //       public: !row?.public,
  //     };
  //     updateChargeStation({ body: body, id: row?.id })
  //       .unwrap()
  //       .then(() => {
  //         showToast({
  //           title: "Charge Station updated.",
  //           dis: "Charge Station is updated successfully.",
  //         });
  //         getListChargeStation();
  //       })
  //       .catch(() => {
  //         showToast({
  //           title: "Charge Station updated.",
  //           dis: "Charge Station is updated failed.",
  //           sts: "error",
  //         });
  //       });
  //   };
  //   return (
  //     <Box fontSize={"18px"} display={"flex"} alignItems={"center"}>
  //       <Box>
  //         <Text
  //           w={"72px"}
  //           fontFamily={"Avenir Medium"}
  //           color={row?.public ? "#0A47BA" : "#E74D3D"}
  //         >
  //           {row?.public ? "Public" : "Private"}
  //         </Text>
  //       </Box>

  //       <Switch
  //         colorScheme={"#0A47BA"}
  //         size="md"
  //         isChecked={row?.public}
  //         isDisabled={updatePublic}
  //         onChange={handlePublic}
  //       />
  //     </Box>
  //   );
  // };

  const convertName = (row: RowType) => {
    return (
      <Box
        position={"relative"}
        h={"100%"}
        display={"flex"}
        alignItems={"center"}
        ml={"24px"}
      >
        <ChevronRightIcon
          ml={"-24px"}
          bgColor={"transparent"}
          cursor={"pointer"}
          _hover={{ backgroundColor: "transparent" }}
          onClick={() => {
            if (listExpand.includes(row?.id)) {
              setListExpand((pre) => {
                const newPre = [...pre];
                return newPre.filter((item) => item !== row?.id);
              });
            } else {
              setListExpand([...listExpand, row?.id]);
            }
          }}
          transition="transform 0.3s ease"
          transform={listExpand.includes(row?.id) ? "rotate(90deg)" : "none"}
          p={"0px"}
        />
        <Tooltip label={row?.name || ""}>
          <Text
            pl={"12px"}
            textDecoration={"underline"}
            w={"fit-content"}
            fontSize={"17px"}
            cursor={"pointer"}
            fontFamily={"Avenir Heavy"}
            height={"full"}
            whiteSpace={"nowrap"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
            color={"#0A47BA"}
            maxW={"150px"}
            onClick={() => {
              setIdcharge(row);
              setModelOpen(ModalType.CHARGE_DETAIL);
              setOptionId("-1");
            }}
          >
            {row?.name || ""}
          </Text>
        </Tooltip>
      </Box>
    );
  };

  const convertConnectors = (row: RowType) => {
    return (
      <Text
        w={"fit-content"}
        fontSize={"17px"}
        fontFamily={"Avenir Heavy"}
        color={"#0A47BA"}
      >
        {row?.connectors?.length || 0}
      </Text>
    );
  };

  const convertLocation = (row: RowType) => {
    return (
      <Tooltip label={row?.location?.name}>
        <Text
          w={"fit-content"}
          fontSize={"18px"}
          fontFamily={"Avenir Book"}
          maxW={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {row?.location?.name}
        </Text>
      </Tooltip>
    );
  };

  const convertCSID = (row: RowType) => {
    return (
      <Flex>
        <Text>{row.staticEndpoint}</Text>
        {row?.isSimulator && (
          <Icon
            as={HiDesktopComputer}
            width={"22px"}
            height={"22px"}
            ml={"8px"}
          />
        )}
      </Flex>
    );
  };

  const columns = useMemo(
    () => {
      const columnValue = [
        {
          Header: "Name",
          accessor: (row: RowType) => convertName(row),
        },
        {
          Header: "Location",
          accessor: (row: RowType) => convertLocation(row),
        },
        {
          Header: "CS ID",
          accessor: (row: RowType) => convertCSID(row),
        },
        {
          Header: "Status",
          accessor: (row: RowType) => statusAction(row),
        },
        // {
        //   Header: "Access",
        //   accessor: (row: RowType) => accessAction(row),
        // },
        {
          Header: "Connectors",
          accessor: (row: RowType) => convertConnectors(row),
        },
        {
          Header: "Action",
          accessor: (row: RowType) => actionComponent(row),
        },
      ]

      return columnValue;
    },
    [optionId, listExpand, simulator]
  );

  const convertConnectorId = (row) => {
    return (
      <Tooltip label={row?.original?.id || ""}>
        <Text
          maxW={"160px"}
          fontSize={"17px"}
          cursor={"pointer"}
          fontFamily={"Avenir Heavy"}
          color={"#0A47BA"}
          textOverflow={"ellipsis"}
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          onClick={() => {
            setConnectorId(row?.original);
            setModelOpen(ModalType.CONNECTOR_DETAIL);
            setOptionId("-1");
          }}
        >
          {row?.original?.id}
        </Text>
      </Tooltip>
      // <Text
      //   maxW={"160px"}
      //   fontSize={"17px"}
      //   fontFamily={"Avenir Heavy"}
      //   color={"#0A47BA"}
      //   textOverflow={"ellipsis"}
      //   overflow={"hidden"}
      //   whiteSpace={"nowrap"}
      // >
      //   {`${row?.original?.id}`}
      // </Text>
    );
  };

  const convertConnectorAttribute = (row, attribute, blueText = false) => {
    return (
      <Tooltip label={row?.original[attribute] || row[attribute]}>
        <Text
          w={"fit-content"}
          fontSize={"17px"}
          fontFamily={blueText ? "Avenir Heavy" : "Avenir Book"}
          color={blueText ? "#0A47BA" : ""}
          textOverflow={"ellipsis"}
          whiteSpace={"nowrap"}
          fontWeight={"100"}
        >
          {row?.original[attribute] || row[attribute]}
        </Text>
      </Tooltip>
    );
  };

  // const convertConnectorRate = (row) => {
  //   return (
  //     <Text
  //       w={"fit-content"}
  //       fontSize={"17px"}
  //       fontFamily={"Avenir Book"}
  //       textOverflow={"ellipsis"}
  //       whiteSpace={"nowrap"}
  //       fontWeight={"100"}
  //     >
  //       {row?.original?.rate?.name || row?.rate?.name}
  //     <convertConnectorAction/Text>
  //   );
  // };

  const convertConnectorAction = (row) => {
    const isActive = row?.original?.active || row?.active;
    const rowId = row?.original?.id || row?.id;
    // const isPhysical = !listChargeStation.find((item) => {
    //   return item?.subRows?.find(
    //     (subItem: any) => subItem.id === row?.original?.id
    //   );
    // })?.isSimulator;

    const handleStartSession = () => {
      if (startSessionLoading || stopSessionLoading) {
        return;
      }
      // if (isPhysical) {
      //   showToast({
      //     title: "Action",
      //     dis: "Unable to Start/Stop session with a physical charge station.",
      //     sts: "info",
      //   });
      //   return;
      // }
      if (!isActive) {
        startSession({ connector: { id: rowId } })
          .unwrap()
          .then(() => {
            showToast({
              title: "Start session.",
              dis: "Start session successfully.",
            });
            getListChargeStation();
          })
          .catch(() => {
            showToast({
              title: "Connector creation.",
              dis: "Start session failed.",
              sts: "error",
            });
          });
      } else {
        stopSession({ connector: { id: rowId } })
          .unwrap()
          .then(() => {
            showToast({
              title: "Stop session.",
              dis: "Stop session successfully.",
            });
            getListChargeStation();
          })
          .catch(() => {
            showToast({
              title: "Connector creation.",
              dis: "Stop session failed.",
              sts: "error",
            });
          });
      }
    };

    return (
      <Flex fontSize={"18px"} alignItems={"center"} justify={"space-between"}>
        <Flex>
          <Box>
            <Text
              w={"55px"}
              fontFamily={"Avenir Medium"}
              color={isActive ? "#0A47BA" : "#E74D3D"}
            >
              {isActive ? "Start" : "Stop"}
            </Text>
          </Box>

          <Switch
            colorScheme={"#0A47BA"}
            size="md"
            mr={"15px"}
            isChecked={isActive ? true : false}
            onChange={handleStartSession}
          />
        </Flex>

        {userDetailData?.data?.role?.id !== 3 && (
          <Menu autoSelect={false}>
            <MenuButton as={Box} cursor={"pointer"}>
              <OptionIcon
                cursor={"pointer"}
                stroke="#0A47BA"
                onClick={() => setConnectorId(row?.original?.id || row?.id)}
              />
            </MenuButton>
            <MenuList
              w={"320px"}
              bg={"#ffffff"}
              boxShadow={"rgba(0, 0, 0, 0.24) 0px 3px 8px"}
              borderRadius={"10px"}
              p={"5px"}
              fontSize={"18px"}
              ref={ref as any}
              position={"relative"}
              _after={{
                position: "absolute",
                content: '""',
                w: "20px",
                h: "32px",
                right: 0,
                top: "-32px",
              }}
              cursor={"pointer"}
              onClick={() => openMoreOption(row?.id)}
            >
              <MenuItem
                mb={"2px"}
                fontFamily={"Avenir Book"}
                pl={"17px"}
                py={"10px"}
                position={"relative"}
                borderRadius={"8px"}
                transition={"all 0.3s ease"}
                _hover={{
                  bgColor: "rgb(195 213 44 / 20%)",
                }}
                onClick={() => {
                  setCurrentConnector(row?.original?.id as any);
                  setModelOpen(ModalType.EDIT_CONNECTOR);
                }}
              >
                Edit Connector
              </MenuItem>
              <MenuItem
                mb={"2px"}
                fontFamily={"Avenir Book"}
                pl={"17px"}
                py={"10px"}
                position={"relative"}
                borderRadius={"8px"}
                transition={"all 0.3s ease"}
                _hover={{
                  bgColor: "rgb(195 213 44 / 20%)",
                }}
                onClick={() => {
                  setCurrentConnector(row?.original?.id as any);
                  setModelOpen(ModalType.CONFIRM_DELETE_CONNECTOR);
                }}
              >
                Delete
              </MenuItem>
              <MenuItem
                mb={"2px"}
                fontFamily={"Avenir Book"}
                pl={"17px"}
                py={"10px"}
                position={"relative"}
                borderRadius={"8px"}
                transition={"all 0.3s ease"}
                _hover={{
                  bgColor: "rgb(195 213 44 / 20%)",
                }}
                onClick={() => {
                  sessionStorage.setItem("sessionId", row?.original?.id);
                  navigate("/logs/sessions");
                }}
              >
                Show session logs
              </MenuItem>
              <MenuItem
                mb={"2px"}
                fontFamily={"Avenir Book"}
                pl={"17px"}
                py={"10px"}
                position={"relative"}
                borderRadius={"8px"}
                transition={"all 0.3s ease"}
                _hover={{
                  bgColor: "rgb(195 213 44 / 20%)",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setCurrentStatus(row?.original?.status)
                  setCurrentConnector(row?.original?.id as any);
                  setModelOpen(ModalType.SIMULATE_EVENT);
                }}
              >
                Simulate event
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>
    );
  };

  const connectorColumns = useMemo(
    () => {
      const columnValue = [
        {
          Header: "Connector ID",
          accessor: (row: ConnectorType) => convertConnectorId(row),
        },
        {
          Header: "Status",
          accessor: (row: ConnectorType) =>
            convertConnectorAttribute(row, "status"),
        },
        // {
        //   Header: "Rate",
        //   accessor: (row) => convertConnectorRate(row),
        // },
        {
          Header: "Format",
          accessor: (row: ConnectorType) =>
            convertConnectorAttribute(row, "format"),
        },
        {
          Header: "Type",
          accessor: (row: ConnectorType) =>
            convertConnectorAttribute(row, "type", true),
        },
        {
          Header: "Power Type (kW)",
          accessor: (row: ConnectorType) =>
            convertConnectorAttribute(row, "powerType", true),
        },
        {
          Header: "Power",
          accessor: (row: ConnectorType) =>
            convertConnectorAttribute(row, "power"),
        },
        {
          Header: "Action",
          accessor: (row: ConnectorType) => convertConnectorAction(row),
        },
      ];

      return columnValue;
    },
    [
      connectorId,
      listExpand,
      listChargeStation,
      stopSessionLoading,
      startSessionLoading,
    ]
  );

  useEffect(() => {
    if (data?.data?.data) {
      const tmp = data?.data?.data?.map((item: RowType) => {
        if (item?.isSimulator && item?.online) {
          setSimulator(() => item?.id);
        }
        return {
          ...item,
          subRows: item?.connectors || [],
        };
      });
      setListChargeStation(tmp);
    } else {
      setListChargeStation([]);
    }
    const panigation = data?.data?.meta;
    if (panigation) {
      setPageIndex({
        page: panigation?.page,
        hasNextPage: panigation?.hasNextPage,
        hasPreviousPage: panigation?.hasPreviousPage,
        pageCount: panigation?.pageCount,
      });
    }
  }, [data]);

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#ffffff"}
        overflow={"hidden"}
        p={"74px 40px 74px 40px"}
      >
        <Box
          mb={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"38px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            lineHeight={"52px"}
            fontFamily={"Avenir Black"}
          >
            Charge stations
          </Text>
          <Box>
            <Button
              style={{ display: userDetailData?.data?.role?.id !== 3 ? "inline-block" : "none" }}
              variant={"solid"}
              mr={"8px"}
              bgColor={"#0A47BA"}
              color={"#FFFFFF"}
              borderRadius={"37px"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              _hover={{
                bgColor: "#0A47BA",
                opacity: "0.8",
              }}
              leftIcon={
                <Icon as={HiPlus} width={"22px"} height={"22px"} mr={"8px"} />
              }
              onClick={onOpen}
            >
              Create
            </Button>

            <Button
              variant={"outline"}
              mr={"8px"}
              color={"#124BB7"}
              borderRadius={"37px"}
              borderColor={"#124BB7"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              onClick={() => window.location.reload()}
              _hover={{
                opacity: "0.8",
              }}
            >
              <RefreshIcon stroke="#0a47ba" />
              <Text ml={"16px"}>Refresh</Text>
            </Button>

            <Button
              variant={"outline"}
              color={"#124BB7"}
              borderRadius={"37px"}
              borderColor={"#124BB7"}
              isLoading={isDownloadLoading}
              isDisabled={isDownloadLoading}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              _hover={{
                opacity: "0.8",
              }}
              onClick={handleDownloadCsv}
            >
              <DownloadIcon />
              <Text ml={"16px"}>Export</Text>
            </Button>
          </Box>
        </Box>
        <TableComponent
          isLoading={isFetching}
          columns={columns as any}
          data={listChargeStation}
          name={"location"}
          childColumns={connectorColumns as any}
          listExpand={listExpand}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          noDataText={{
            currentTable: "No Charge Station Added",
            childTable: "No Connector Added",
          }}
        />
      </Box>

      {isOpen && (
        <AddNewChargeStation
          isOpen={isOpen}
          onClose={onClose}
          setPageIndex={setPageIndex}
          userDetailData={userDetailData}
        />
      )}
      {ModalType.SIMULATE_EVENT === modelOpen && (
        <SimulateEventComponent
          isOpen={true}
          onClose={handleCloseModal}
          currentConnector={currentConnector}
          currentStatus={currentStatus}
          refetch={getListChargeStation}
        />
      )}

      {ModalType.CHARGE_DETAIL === modelOpen && (
        <ChargeStationDetailsComponent
          idCharge={idCharge}
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}

      {ModalType.CONNECTOR_DETAIL === modelOpen && (
        <ConnectorDetailsComponent
          connectorId={connectorId}
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}

      {ModalType.ADD_CONNECTOR === modelOpen && (
        <AddNewConnectorComponent
          idCharge={idCharge?.id}
          isOpen={true}
          onClose={handleCloseModal}
          refetch={getListChargeStation}
        />
      )}

      {ModalType.EDIT_CONNECTOR === modelOpen && (
        <EditConnectorComponent
          isOpen={true}
          onClose={handleCloseModal}
          currentConnector={currentConnector}
          refetch={getListChargeStation}
        />
      )}

      {ModalType.EDIT_CHARGE === modelOpen && (
        <EditChargeStationComponent
          idCharge={idCharge?.id as any}
          isOpen={true}
          onClose={handleCloseModal}
          refetch={getListChargeStation}
          userDetailData={userDetailData}
        />
      )}

      {ModalType.CONFIRM_DELETE_CHARGE === modelOpen && (
        <ConfirmDeleteChargeComponent
          idCharge={idCharge?.id}
          isOpen={true}
          onClose={handleCloseModal}
          setOpenSuccess={() => {
            setModelOpen(ModalType.DELETE_CHARGE_SUCCESS);
          }}
          listCharge={listChargeStation}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setSimulator={setSimulator}
          setListExpand={setListExpand}
        />
      )}

      {ModalType.CONFIRM_DELETE_CONNECTOR === modelOpen && (
        <ConfirmDeleteConnectorComponent
          currentConnector={currentConnector}
          isOpen={true}
          onClose={handleCloseModal}
          setOpenSuccess={() => {
            setModelOpen(ModalType.DELETE_CONNECTOR_SUCCESS);
          }}
          refetch={getListChargeStation}
        />
      )}

      {ModalType.DELETE_CHARGE_SUCCESS === modelOpen && (
        <SuccessDeleteComponent
          statusSuccess="Charge station successfully deleted"
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}

      {ModalType.DELETE_CONNECTOR_SUCCESS === modelOpen && (
        <SuccessDeleteComponent
          statusSuccess="Connector successfully deleted"
          isOpen={true}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  );
};

export default ChargeStationsComponent;
