import { ChevronDownIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  Spinner,
  Text,
  useOutsideClick,
} from "@chakra-ui/react";
import useDebounce from "src/custom-hook/useDebound";

type SelectType = {
  value: string;
  label: string;
  check: boolean;
}

type SelectProps = {
  list: SelectType[];
  title?: string;
  setList?: any;
  isFetching?: boolean;
};
export const MultiSelectComponent = (props: SelectProps) => {
  const { list, title, setList, isFetching } = props;
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredList, setFilteredList] = useState<SelectType[]>(list);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const debouncedSearch = useDebounce(search, 300);
  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuOpen(false),
  });

  const handleCLick = (item) => {
    setList((pre: SelectType[]) => {
      return pre.map((selectItem) => {
        if (selectItem.value === item.value) {
          return { ...selectItem, check: !selectItem.check };
        }
        return selectItem;
      });
    });
  };

  const handleFilter = (value: string) => {
    if (value.trim()) {
      const items = list.filter((item) => item.label?.toLowerCase()?.includes(value.trim().toLowerCase()));
      setFilteredList(() => items);
    } else {
      setFilteredList(() => list);
    }
  };

  const handleUnselectAll = () => {
    const initialList = list.map(item => { return { ...item, check: false } })
    setFilteredList(() => initialList)
    setList(() => initialList)
  }

  useEffect(() => {
    if (!search.trim()) {
      setFilteredList(() => list);
    } else {
      setFilteredList(() => {
        const temp = list.filter((selectItem) =>
          selectItem?.label?.toLowerCase().includes(search)
        );
        return temp;
      });
    }
  }, [list]);

  useEffect(() => {
    handleFilter(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <Flex gap={"8px"} ref={menuRef}>
      <Menu closeOnSelect={false} isOpen={isMenuOpen}>
        <MenuButton
          as={Button}
          colorScheme="white"
          _hover={{
            bg: "#0A47BA10",
          }}
          borderRadius={"29px"}
          minWidth={"320px"}
          height={"55px"}
          position={"relative"}
          overflow={"hidden"}
          borderWidth={"1px"}
          borderColor={"#0A47BA"}
          boxSizing={"border-box"}
          outline={"none"}
          display="flex"
          onClick={() => {
            setMenuOpen((pre) => !pre);
          }}
        >
          <Box display={"flex"} height={"full"}>
            <Text color={"black"} fontFamily={"Avenir Book"} fontWeight="300">
              {title}
            </Text>
          </Box>
          <Box
            position={"absolute"}
            top={"0px"}
            height={"full"}
            bg={"#0A47BA"}
            w={"65px"}
            right="0px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ChevronDownIcon border="0px" width={"32px"} height={"32px"} />
          </Box>
        </MenuButton>
        <MenuList width="320px" borderRadius={"8px"}>
          {isFetching ? (
            <Flex
              h={"120px"}
              w={"full"}
              justify={"center"}
              gap={"8px"}
              align={"center"}
            >
              <Spinner size="lg" color="blue.500" />
              <Text>Loading...</Text>
            </Flex>
          ) : (
            <MenuOptionGroup width="320px" type="checkbox">
              <Box px={"8px"}>
                <InputGroup size="md">
                  <Input
                    ref={inputRef}
                    placeholder="Search"
                    outline="none"
                    borderWidth={"2px"}
                    borderRadius={"8px"}
                    _focus={{
                      outline: "none",
                      borderColor: "#0A47BA",
                      outlineColor: "",
                      boxShadow: "none",
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    pr={"50px"}
                    _hover={{ borderColor: "#0A47BA" }}
                  />
                  <InputRightElement mr={"10px"}>
                    {search ? (
                      <CloseIcon
                        border="0px"
                        width={"20px"}
                        height={"20px"}
                        color={"#0A47BA"}
                        onClick={() => setSearch("")}
                        cursor="pointer"
                      />
                    ) : (
                      <SearchIcon
                        border="0px"
                        width={"20px"}
                        height={"20px"}
                        color={"#0A47BA"}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
              </Box>
              <Box
                maxH={"350px"}
                overflow={"auto"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-track": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "gray",
                    borderRadius: "20px",
                  },
                }}
                position="relative"
              >
                {list?.some(item => item.check) &&
                  <Box
                    px="10px"
                    py="4px"
                    _hover={{ bgColor: "gray.100" }}
                    cursor="pointer"
                    onClick={handleUnselectAll}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <CloseIcon
                      border="0px"
                      width={"15px"}
                      height={"15px"}
                      color={"#0A47BA"}
                      mr={"8px"}
                    />
                    <Text
                      whiteSpace={"nowrap"}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      maxW={"270px"}
                      fontWeight={600}
                    >
                      Unselect All
                    </Text>
                  </Box>
                }
                {filteredList?.length > 0 ? (
                  <CheckboxGroup colorScheme="#0A47BA">
                    {filteredList?.map((item: SelectType) => (
                      <Box
                        key={item?.value}
                        px="10px"
                        py="4px"
                        _hover={{ bgColor: "gray.100" }}
                      >
                        <Checkbox
                          size="md"
                          w={"full"}
                          isChecked={item?.check}
                          iconColor="#0A47BA"
                          onChange={() => handleCLick(item)}
                        >
                          <Text
                            whiteSpace={"nowrap"}
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            maxW={"270px"}
                          >
                            {item?.label}
                          </Text>
                        </Checkbox>
                      </Box>
                    ))}
                  </CheckboxGroup>
                ) : (
                  <Flex p={"10px"} justifyContent={"center"}>
                    <Text>No data</Text>
                  </Flex>
                )}
              </Box>
            </MenuOptionGroup>
          )}
        </MenuList>
      </Menu>
    </Flex>
  );
};
