import { Box, Image, Tooltip } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import Logo from "src/assets/logo.png";
import { leftbarList } from "../left-bar/leftbarItems";
import { useGetUserDetailQuery } from "src/hook/user/user";
import { CompanyFilter } from "../company-filter";

const HeaderComponent = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const email = localStorage.getItem("email") || "";
  const { data: userDetailData } = useGetUserDetailQuery();

  return (
    <>
      {pathname.includes("login") ||
        pathname.includes("sign-up") ||
        pathname.includes("password") ||
        pathname.includes("term-policy") ? (
        <Box
          height={"86px"}
          w={"full"}
          display={"flex"}
          alignItems={"end"}
          justifyContent={"center"}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            onClick={() => navigate("*")}
          >
            <Image src={Logo} w={"130px"} mr={"8px"} cursor={"pointer"} />
          </Box>
        </Box>
      ) : (
        <Box
          w={"full"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          px={"90px"}
          height={"86px"}
        >
          <Link to={`${leftbarList[0].name}/${leftbarList[0].child_list[0].name}`}>
            <Image src={Logo} w={"80px"} />
          </Link>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            {userDetailData && userDetailData?.data?.role?.id === 1 && <CompanyFilter />}
            <Tooltip label={email} placement={"auto"}>
              <Box
                w={"48px"}
                h={"48px"}
                ml={"30px"}
                rounded={"full"}
                bgColor={"#C3D52C"}
                color={"#FCFCFC"}
                fontFamily={"Avenir Black"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {email?.slice(0, 1)?.toUpperCase()}
              </Box>
            </Tooltip>
          </Box>
        </Box>
      )}
    </>
  );
};

export default HeaderComponent;
