export interface RowType {
  id: string;
  createdAt: string;
  origin: string;
  chargeStation: {
    name: string;
  };
  message: string;
}

export const ORIGIN_TYPE = {
  FROM_CS: "FROM_CS",
  FROM_CP: "FROM_CP"
}
