export enum LocationApi {
  GET_LIST_LOCATION = "/location",
  CREATE_LOCATION = "/location",
  UPDATE_LOCATION = "/location",
  GET_LIST_LOCATION_NO_PAGINATION = '/location/list',
  DOWNLOAD_CSV = "/location/download/csv"
}

export interface BODY_GET_LIST_LOCATION {
  take?: number | "full";
  page?: number | "full";
  companyId?: string;
}

export interface BODY_CREATE_LOCATION {
  name: string;
  long: number;
  lat: number;
  timezone: string;
  address: {
    streetNumber: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
  };
  notes: string;
}

export interface BODY_UPDATE_LOCATION {
  name: string;
  long: number;
  lat: number;
  timezone: string;
  status?: boolean;
  address: {
    streetNumber: string;
    postalCode: string;
    city: string;
    state: string;
    country: string;
  };
  notes: string;
}

export interface BODY_SEND_REQUEST_BRIEF {
  briefId: number;
  title: string;
  feedback: string;
}

export interface RESPON_SEND_REQUEST_BRIEF {
  message: string;
  status: number;
}

export interface BODY_NEXT_USER {
  briefId: number;
  userId: number;
}

export interface BODY_NEXT_DEPARTMENT {
  briefId: number;
  departmentId: number;
}

export interface BODY_CREATE_STEP {
  typeId: number;
  steps: {
    deparmentId: number;
    stepOrder: number;
    description: string;
  }[];
}

export interface BODY_CREATE_BRIEF_TYPE {
  name: string;
  description: string;
}