import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState, useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormValues } from "./edit-charge-station.type";
import { schema } from "./edit-charge-station.schema";
import {
  useUpdateChargeStationMutation,
  useLazyGetChargeStationQuery,
} from "src/hook/charge-station/charge-station";
import { useGetListLocationQuery } from "src/hook/location/location";
import { CHARGE_STATION_SECURITY } from "src/constants/charge-station";
import { getToastAttribute, ToastAttribute } from "src/constants";
import { useGetListCompanyQuery } from "src/hook/company/company";

interface Props {
  idCharge: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  userDetailData?: any;
}

const EditChargeStation = (props: Props) => {
  const { isOpen, onClose, idCharge, refetch, userDetailData } = props;
  const [listLocation, setListLocation] = useState<any>([]);
  const [isSimulator, setIsSimulator] = useState<string>();
  const cancelRef = useRef();

  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors, isDirty },
  } = useForm<FormValues>({
    defaultValues: {
      security: { type: "none" },
      role: userDetailData?.data?.role?.id,
      company: { id: "" },
    },
    resolver: yupResolver(schema),
  });

  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const companyId = useWatch({ control, name: "company.id" })

  const [getCharge] = useLazyGetChargeStationQuery();
  const { data: location } = useGetListLocationQuery({
    page: "full",
    take: "full",
    companyId: companyId && `companyId[]=${companyId}`
  })
  const [updateChargeStation, { isLoading }] = useUpdateChargeStationMutation();
  const { data: companies } =
    useGetListCompanyQuery();

  const onSubmit = (data) => {
    const { company, role, ...rest } = data;
    updateChargeStation({ body: rest, id: idCharge })
      .unwrap()
      .then(() => {
        showToast({
          title: "Charge Station update.",
          dis: "Charge Station is successfully updated.",
        });
        refetch();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Charge Station update.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  useEffect(() => {
    setListLocation(location?.data?.data ? location?.data?.data : []);
    getCharge(idCharge)
      .unwrap()
      .then((data) => {
        setIsSimulator(data?.data?.isSimulator ? "true" : "false");
        setValue("location.id", data?.data?.location?.id);
        setValue("name", data?.data?.name);
        setValue("description", data?.data?.description);
        if (data?.data?.security) {
          const security = JSON.parse(data?.data?.security);
          setValue("security.type", security.type);
        }
      });
  }, [location]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          maxH={"83%"}
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          m={0}
          overflowY={"auto"}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Edit charge station
            </Text>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Charge station type
                  <span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Select
                  w={"42%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  variant={"base"}
                  size={"lg"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  isDisabled
                  value={isSimulator}
                  placeholder={""}
                >
                  <option value="" hidden></option>
                  <option value={"true"}>Simulator</option>
                  <option value={"false"}>Physical</option>
                </Select>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Charge station security
                  <span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Select
                  w={"42%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  variant={"base"}
                  size={"lg"}
                  textTransform={"capitalize"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("security.type", {
                    shouldUnregister: true,
                  })}
                >
                  {CHARGE_STATION_SECURITY.map((item) => (
                    <option
                      key={item.id}
                      style={{
                        fontSize: "18px",
                        fontFamily: "Avenir Book",
                      }}
                    >
                      {item.value}
                    </option>
                  ))}
                </Select>
              </Box>
              {userDetailData?.data?.role?.id === 1 &&
                <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                  <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                    Company name<span style={{ color: "#E74D3D" }}>*</span>
                  </Text>
                  <Box w={"42%"}>
                    <Select
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      textTransform={"capitalize"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("company.id", {
                        shouldUnregister: true,
                      })}
                    >
                      {companies?.data?.map((item) => (
                        item?.companyName && <option key={item?.id} value={item?.id}>{item?.companyName}</option>
                      ))}
                    </Select>
                    {errors.company && (
                      <Text
                        fontSize={"12px"}
                        mt={"4px"}
                        ml={"4px"}
                        color={"red.400"}
                        fontFamily={"Avenir Medium"}
                      >
                        {errors.company.message}
                      </Text>
                    )}
                  </Box>
                </Box>}
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Location<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Flex flexDirection={"column"} w={"42%"}>
                  <Select
                    w={"100%"}
                    maxW={'500px'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("location.id", {
                      shouldUnregister: true,
                    })}
                  >
                    <option value="" hidden></option>
                    {listLocation.map((item) => (
                      <option key={item?.id} value={item?.id} >
                        {item?.name}
                      </option>
                    ))}
                  </Select>
                  {errors?.location?.id && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors?.location?.id?.message}
                    </Text>
                  )}
                </Flex>

                <Box
                  position={"absolute"}
                  right={"16px"}
                  top={"50%"}
                  transform={"translateY(-50%)"}
                  zIndex={2}
                ></Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Flex flexDirection={"column"} w={"42%"}>
                  <Input
                    w={"100%"}
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("name", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors?.name && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors?.name?.message}
                    </Text>
                  )}
                </Flex>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Notes
                </Text>
                <Input
                  w={"42%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("description", {
                    shouldUnregister: true,
                  })}
                />
              </Box>

              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  variant={"outline"}
                  borderColor={"#020E20"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#BEC3C7",
                    opacity: 0.8,
                  }}
                  onClick={() => reset()}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isDisabled={!isDirty || isLoading}
                  isLoading={isLoading}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                >
                  Update
                </Button>
              </Box>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default EditChargeStation;
