import { useState, useRef, useEffect } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  useOutsideClick,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import es from 'date-fns/locale/es';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import './custom.css';

registerLocale('es', es)

type RangePickerProps = {
  isDefault?: boolean
  setStartDay: (date: any) => void;
  setStopDay: (date: any) => void;
  closeIcon?: boolean
}

export const RangePicker = ({ setStartDay, setStopDay, isDefault = false, closeIcon = true }: RangePickerProps) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setStartDay(moment(start).format('YYYY-MM-DD'));
      setStopDay(moment(end).format('YYYY-MM-DD'));
      setMenuOpen(false);
    }
  };

  const handleClearDate = () => {
    setStartDate(null);
    setEndDate(null);
    setStartDay(null);
    setStopDay(null);
  }

  useOutsideClick({
    ref: menuRef,
    handler: () => setMenuOpen(false),
  });

  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);
    if (isDefault) {
      setStartDate(sevenDaysAgo);
      setEndDate(today);
    }
  }, [])
  return (
    <Flex gap={"8px"} ref={menuRef} alignItems="center"
      justifyContent="center" position={"relative"}>
      <Menu closeOnSelect={false} isOpen={isMenuOpen}>
        <MenuButton
          as={Button}
          colorScheme="white"
          _hover={{
            bg: "#0A47BA10",
          }}
          borderRadius={"29px"}
          minWidth={"320px"}
          height={"55px"}
          position={"relative"}
          overflow={"hidden"}
          borderWidth={"1px"}
          borderColor={"#0A47BA"}
          boxSizing={"border-box"}
          outline={"none"}
          display="flex"
          onClick={() => {
            setMenuOpen((pre) => !pre);
          }}
        >
          <Box display={"flex"} height={"full"}>
            <Text color={"black"} fontFamily={"Avenir Book"} fontWeight="300" mr={"10px"}>
              {!startDate && "Date Range"}
              {startDate && moment(startDate).format('MMM DD, YYYY')}{endDate && " - " + moment(endDate).format("MMM DD, YYYY")}
            </Text>
          </Box>
          <Box
            position={"absolute"}
            top={"0px"}
            height={"full"}
            bg={"#0A47BA"}
            w={"65px"}
            right="0px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <ChevronDownIcon border="0px" width={"32px"} height={"32px"} />
          </Box>
        </MenuButton>
        <MenuList minW="320px" p={0} border="none" opacity={0}>
          <ReactDatePicker
            calendarClassName="full"
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            dateFormat="MM/yyyy"
            dateFormatCalendar={"MMM yyyy"}
            selectsRange
            inline
            isClearable
            closeOnScroll
          />
        </MenuList>
      </Menu>
      {endDate && closeIcon &&
        <CloseIcon
          position={"absolute"}
          left={"230px"}
          cursor={"pointer"}
          color="black"
          onClick={(e) => {
            e.stopPropagation();
            handleClearDate();
          }}
        />
      }
    </Flex>
  );
};
