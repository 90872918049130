import { useMemo, useState, useEffect } from "react";
import { Box, Text, Icon, Button, Tooltip, Spinner } from "@chakra-ui/react";
import { HiMiniClock } from "react-icons/hi2";
import { FaLeaf } from "react-icons/fa";
import { BsFillLightningFill } from "react-icons/bs";
import { CHARGER_STATUS } from "src/constants/dashboard";
import TableComponent from "src/components/custom-table";
import moment from "moment";
import { ReactComponent as RefreshIcon } from "src/assets/icon/refresh-icon.svg";
import { useAppSelector } from "src/redux";
import { getCurrentTimeZoneOffset } from "src/constants/timezone";
import { RangePicker } from "src/components/daterange-picker";
import { MultiSelectComponent } from "src/components/multi-select/multi-select.component";
import { useGetListLocationQuery } from "src/hook/location/location";
import { Link } from "react-router-dom";
import {
  ItemInterface,
  SelectType,
} from "src/template/logs/sessions/session.type";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Label,
  Tooltip as ToolTipChart,
} from "recharts";
import {
  useGetListDashboardQuery,
  useGetListStationUsageDashboardQuery,
} from "src/hook/dashboard/dashboard";

const DashboardComponent = () => {
  const filterCompanies = useAppSelector(
    (state: any) => state?.headerFilter?.companyId
  );

  const today = new Date();
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(today.getDate() - 7);

  const [startDay, setStartDay] = useState(
    moment(sevenDaysAgo).format("YYYY-MM-DD")
  );
  const [stopDay, setStopDay] = useState(moment(today).format("YYYY-MM-DD"));

  const [listLocation, setListLocation] = useState<SelectType[]>([]);
  const [queryDashboardParams, setQueryDashboardParams] = useState(
    `startDay=${startDay}&stopDay=${stopDay}&timezone=${getCurrentTimeZoneOffset().toString()}`
  );

  const [queryStationUsageParams, setQueryStationUsageParams] = useState(
    `startDay=${startDay}&stopDay=${stopDay}&timezone=${getCurrentTimeZoneOffset().toString()}`
  );
  const { data: listLocationData, isFetching: isLocationFetching } =
    useGetListLocationQuery({
      page: "full",
      take: "full",
      companyId:
        filterCompanies
          .map((companyId: string) => `companyId[]=${companyId}`)
          .join("&") || "",
    });

  const { data: dashboardData, isFetching: isFetchingDashboard } =
    useGetListDashboardQuery(queryDashboardParams);

  const { data: stationUsageData, isFetching: isFetchingStationUsage } =
    useGetListStationUsageDashboardQuery(queryStationUsageParams);

  useEffect(() => {
    const list = listLocationData?.data?.data?.map((item: ItemInterface) => ({
      value: item?.id,
      label: item?.name,
      check: false,
    }));
    setListLocation(() => list);
  }, [listLocationData]);

  useEffect(() => {
    const queryParams: any = {
      startDay: startDay,
      stopDay: stopDay,
      timezone: getCurrentTimeZoneOffset().toString(),
    };

    console.log(startDay, stopDay);

    if (filterCompanies?.length) {
      queryParams.companyId = filterCompanies;
    }

    const queryString = new URLSearchParams();

    for (const key in queryParams) {
      if (Array.isArray(queryParams[key])) {
        queryParams[key].forEach((value: any) => {
          queryString.append(`${key}[]`, value);
        });
      } else {
        queryString.append(key, queryParams[key]);
      }
    }

    setQueryDashboardParams(queryString.toString());
  }, [filterCompanies, startDay, stopDay]);

  useEffect(() => {
    const queryParams: any = {
      startDay: startDay,
      stopDay: stopDay,
      timezone: getCurrentTimeZoneOffset().toString(),
    };

    if (filterCompanies?.length) {
      queryParams.companyId = filterCompanies;
    }

    if (listLocation?.length) {
      const temp: any = [];
      listLocation?.forEach((item) => {
        if (item?.check) {
          temp.push(item?.value);
        }
      });

      queryParams.locationId = temp;
    }

    const queryString = new URLSearchParams();

    for (const key in queryParams) {
      if (Array.isArray(queryParams[key])) {
        queryParams[key].forEach((value: any) => {
          queryString.append(`${key}[]`, value);
        });
      } else {
        queryString.append(key, queryParams[key]);
      }
    }

    setQueryStationUsageParams(queryString.toString());
  }, [filterCompanies, startDay, stopDay, listLocation]);

  const timeDisplay = (time: number | string) => {
    const hours = Math.floor(Number(time) / 60); // Whole hours
    const minutes = (Number(time) % 60).toFixed(2);

    return (
      <Box display="flex" alignItems={"end"}>
        {hours > 0 && (
          <>
            <Text fontSize="44px" lineHeight={1} fontFamily={"Avenir Heavy"}>
              {hours}
            </Text>
            <Text
              fontSize="20px"
              lineHeight={"26px"}
              fontFamily={"Avenir Heavy"}
              ml={1}
            >
              h
            </Text>
          </>
        )}
        <Text
          fontSize="44px"
          lineHeight={1}
          fontFamily={"Avenir Heavy"}
          ml={hours > 0 ? 2 : 0}
        >
          {minutes}
        </Text>
        <Text
          fontSize="20px"
          lineHeight={"26px"}
          fontFamily={"Avenir Heavy"}
          ml={1}
        >
          min
        </Text>
      </Box>
    );
  };

  const convertStartTime = (row: any) => {
    return (
      <Text
        w={"fit-content"}
        fontSize={"16px"}
        fontFamily={"Avenir Book"}
        maxW={"150px"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        {moment(row?.startTime).format("YYYY-MM-DD")}
      </Text>
    );
  };
  const convertStageOfCharge = (row: any) => {
    return (
      <Text
        w={"fit-content"}
        fontSize={"16px"}
        fontFamily={"Avenir Book"}
        maxW={"150px"}
        whiteSpace={"nowrap"}
        overflow={"hidden"}
        textOverflow={"ellipsis"}
      >
        {row?.status}
      </Text>
    );
  };
  const convertLocation = (row: any) => {
    return (
      <Tooltip label={row?.locationName}>
        <Text
          textDecoration={"underline"}
          color={"#0A47BA"}
          w={"fit-content"}
          fontSize={"16px"}
          fontFamily={"Avenir Heavy"}
          maxW={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {row?.locationName}
        </Text>
      </Tooltip>
    );
  };
  const convertChargeStation = (row: any) => {
    return (
      <Tooltip label={row?.chargeStationName}>
        <Text
          textDecoration={"underline"}
          color={"#0A47BA"}
          w={"fit-content"}
          fontSize={"16px"}
          fontFamily={"Avenir Heavy"}
          maxW={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {row?.chargeStationName}
        </Text>
      </Tooltip>
    );
  };

  const columns = useMemo(() => {
    const columnValue = [
      {
        Header: "Start time",
        accessor: (row: any) => convertStartTime(row),
      },
      {
        Header: "Stage of charge",
        accessor: (row: any) => convertStageOfCharge(row),
      },
      {
        Header: "Location",
        accessor: (row: any) => convertLocation(row),
      },
      {
        Header: "Charge station",
        accessor: (row: any) => convertChargeStation(row),
      },
    ];

    return columnValue;
  }, [dashboardData?.data]);

  return (
    <Box w={"full"} bgColor={"#ffffff"} p={"74px 90px 74px 40px"}>
      <Box>
        <Box
          mb={"32px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            Dashboard
          </Text>
          <Box display={"flex"} gap={"12px"}>
            <Button
              variant={"solid"}
              // mr={"8px"}
              bgColor={"#0A47BA"}
              color={"#FFFFFF"}
              borderRadius={"37px"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              onClick={() => window.location.reload()}
              _hover={{
                bgColor: "#0A47BA",
                opacity: "0.8",
              }}
            >
              <RefreshIcon stroke="#FFFFFF" />
              <Text ml={"16px"}>Refresh</Text>
            </Button>
            <RangePicker
              setStartDay={setStartDay}
              setStopDay={setStopDay}
              isDefault={true}
              closeIcon={false}
            />
          </Box>
        </Box>
        {isFetchingDashboard ? (
          <Box
            display={"flex"}
            h={"120px"}
            w={"full"}
            justifyContent={"center"}
            gap={"8px"}
            alignItems={"center"}
          >
            <Spinner size="lg" color="blue.500" />
          </Box>
        ) : (
          <Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              gap={"12px"}
              mb={"20px"}
              h={"220px"}
            >
              <Box
                w={"34%"}
                h={"full"}
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
                p={"20px"}
              >
                <Box>
                  <Text
                    fontSize="24px"
                    lineHeight={"29px"}
                    fontFamily={"Avenir Heavy"}
                  >
                    Charger status
                  </Text>
                  <Box>
                    {dashboardData?.data?.connectorStatus?.connectorStatus?.map(
                      (v: any) => (
                        <Box
                          key={v.label}
                          display={"flex"}
                          alignItems={"center"}
                          mt={2}
                        >
                          <Box
                            w={"14px"}
                            h={"14px"}
                            bg={CHARGER_STATUS.get(v?.label)?.color}
                            rounded={"full"}
                          ></Box>
                          <Text
                            fontSize={"16px"}
                            ml={2}
                            fontFamily={"Avenir Book"}
                          >
                            {v.label} ({v.value})
                          </Text>
                        </Box>
                      )
                    )}
                  </Box>
                </Box>
                <PieChart width={180} height={180}>
                  <Pie
                    data={dashboardData?.data?.connectorStatus?.connectorStatus?.map(
                      (v: any) => {
                        return { label: v.label, value: Number(v.value) };
                      }
                    )}
                    innerRadius={60}
                    outerRadius={80}
                    paddingAngle={1}
                    dataKey="value"
                  >
                    <Label
                      content={() => (
                        <foreignObject
                          width={180}
                          height={180}
                          style={{ pointerEvents: "none" }}
                        >
                          <Box
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Text
                              display="flex"
                              fontSize="16px"
                              fontFamily="Avenir Heavy"
                              flexDirection="column"
                              alignItems="center"
                            >
                              Total
                              <Text fontSize="26px" fontFamily="Avenir Heavy">
                                {dashboardData?.data?.connectorStatus?.total}
                              </Text>
                            </Text>
                          </Box>
                        </foreignObject>
                      )}
                      position="center"
                    />
                    {dashboardData?.data?.connectorStatus?.connectorStatus?.map(
                      (status: any, index: any) => (
                        <Cell
                          key={`cell-${index}`}
                          style={{ outline: "none" }}
                          fill={CHARGER_STATUS.get(status.label)?.color}
                        />
                      )
                    )}
                  </Pie>
                </PieChart>
              </Box>
              <Box
                position={"relative"}
                w={"22%"}
                h={"full"}
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                display={"flex"}
                justifyContent={"right"}
                alignItems={"center"}
              >
                <Icon
                  as={HiMiniClock}
                  boxSize={48}
                  color="gray.200"
                  mr={"20px"}
                  transform="scaleX(-1)"
                />
                <Box
                  position={"absolute"}
                  w={"90%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"left"}
                  gap={"30px"}
                >
                  <Text
                    fontSize="24px"
                    lineHeight={"29px"}
                    fontFamily={"Avenir Heavy"}
                    mt={"-12px"}
                  >
                    Avg. session length
                  </Text>

                  <Text
                    fontFamily={"Avenir Heavy"}
                    fontSize={"44px"}
                    color={"#0A47BA"}
                  >
                    {timeDisplay(dashboardData?.data?.averageLength)}
                  </Text>
                </Box>
              </Box>
              <Box
                position={"relative"}
                w={"22%"}
                h={"full"}
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                display={"flex"}
                justifyContent={"right"}
                alignItems={"center"}
              >
                <Icon as={BsFillLightningFill} boxSize={40} color="gray.200" />
                <Box
                  position={"absolute"}
                  w={"90%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"left"}
                  gap={"20px"}
                >
                  <Text
                    fontSize="24px"
                    lineHeight={"29px"}
                    fontFamily={"Avenir Heavy"}
                  >
                    Total energy used
                  </Text>

                  <Text
                    fontFamily={"Avenir Heavy"}
                    fontSize={"44px"}
                    color={"#0A47BA"}
                  >
                    {dashboardData?.data?.totalEnergyUsed}
                    <span
                      style={{ fontSize: "20px", fontFamily: "Avenir Heavy" }}
                    >
                      kWh
                    </span>
                  </Text>
                </Box>
              </Box>
              <Box
                position={"relative"}
                w={"22%"}
                h={"full"}
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                display={"flex"}
                justifyContent={"right"}
                alignItems={"center"}
              >
                <Icon as={FaLeaf} boxSize={44} color="gray.200" mr={"30px"} />
                <Box
                  position={"absolute"}
                  w={"90%"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"left"}
                  gap={"20px"}
                >
                  <Text
                    fontSize="24px"
                    lineHeight={"29px"}
                    fontFamily={"Avenir Heavy"}
                  >
                    Co2 saved
                  </Text>

                  <Text
                    fontFamily={"Avenir Heavy"}
                    fontSize={"44px"}
                    color={"#0A47BA"}
                  >
                    {dashboardData?.data?.co2Saved}
                    <span
                      style={{ fontSize: "20px", fontFamily: "Avenir Heavy" }}
                    >
                      kg
                    </span>
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box
              w={"full"}
              h={"400px"}
              display={"flex"}
              justifyContent={"space-between"}
              gap={"12px"}
              mb={"20px"}
            >
              <Box
                w={"50%"}
                h={"full"}
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                padding={"10px 20px 40px 0"}
              >
                <Text
                  fontSize="24px"
                  lineHeight={"32px"}
                  fontFamily={"Avenir Heavy"}
                  mb={"10px"}
                  ml={"20px"}
                >
                  Total sessions
                </Text>
                {dashboardData?.data?.totalSessions?.length ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={dashboardData?.data?.totalSessions}>
                      <XAxis dataKey="period" />
                      <YAxis />
                      <ToolTipChart cursor={{ fill: "transparent" }} />
                      <Bar
                        dataKey="value"
                        fill="#0A47BA"
                        maxBarSize={60}
                        activeBar={{ opacity: "0.85" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Box
                    display={"flex"}
                    h={"120px"}
                    w={"full"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text>No result found</Text>
                  </Box>
                )}
              </Box>
              <Box
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                w={"50%"}
                h={"full"}
                padding={"10px 20px"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={"10px"}
                >
                  <Text
                    fontSize="24px"
                    lineHeight={"32px"}
                    fontFamily={"Avenir Heavy"}
                    mb={"10px"}
                  >
                    Recent charging sessions
                  </Text>
                  <Link to={"/logs/sessions"}>
                    <Button
                      variant={"outline"}
                      color={"#124BB7"}
                      borderRadius={"37px"}
                      borderColor={"#124BB7"}
                      height={"28px"}
                      w={"100px"}
                      fontSize={"20px"}
                      _hover={{
                        opacity: "0.8",
                      }}
                    >
                      <Text fontSize={"16px"}>View all</Text>
                    </Button>
                  </Link>
                </Box>

                <TableComponent
                  isLoading={false}
                  columns={columns as any}
                  data={dashboardData?.data?.recentSession}
                  name={"recent-session"}
                  showFooter={false}
                />
              </Box>
            </Box>
            <Box
              w={"full"}
              h={"400px"}
              display={"flex"}
              justifyContent={"space-between"}
              gap={"12px"}
            >
              <Box
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                w={"50%"}
                h={"full"}
                padding={"10px 20px 63px 0"}
              >
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  mb={"10px"}
                >
                  <Text
                    fontSize="24px"
                    lineHeight={"32px"}
                    fontFamily={"Avenir Heavy"}
                    mb={"10px"}
                    ml={"20px"}
                  >
                    Station usage
                  </Text>
                  <MultiSelectComponent
                    list={listLocation}
                    setList={setListLocation}
                    title="Location"
                    isFetching={isLocationFetching}
                  />
                </Box>
                {isFetchingStationUsage ? (
                  <Box
                    display={"flex"}
                    h={"120px"}
                    w={"full"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Spinner size="lg" color="blue.500" />
                  </Box>
                ) : (
                  <Box w={"full"} h={"327px"}>
                    {stationUsageData?.data?.stationUsage?.length ? (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={stationUsageData?.data?.stationUsage}>
                          <XAxis dataKey="period" />
                          <YAxis />
                          <ToolTipChart cursor={{ fill: "transparent" }} />
                          <Bar
                            dataKey="value"
                            fill="#0A47BA"
                            maxBarSize={60}
                            activeBar={{ opacity: "0.85" }}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <Box
                        display={"flex"}
                        h={"120px"}
                        w={"full"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Text>No result found</Text>
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              <Box
                bgColor={"white"}
                boxShadow={"5px 7px 21px #00000026"}
                rounded={"10px"}
                w={"50%"}
                h={"full"}
                padding={"33px 20px 40px 0"}
              >
                <Text
                  fontSize="24px"
                  lineHeight={"32px"}
                  fontFamily={"Avenir Heavy"}
                  mb={"10px"}
                  ml={"20px"}
                >
                  Total sessions
                </Text>
                {dashboardData?.data?.peakDemand?.length ? (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={dashboardData?.data?.peakDemand}>
                      <XAxis dataKey="period" />
                      <YAxis />
                      <ToolTipChart cursor={{ fill: "transparent" }} />
                      <Bar
                        dataKey="value"
                        fill="#0A47BA"
                        maxBarSize={60}
                        activeBar={{ opacity: "0.85" }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                ) : (
                  <Box
                    display={"flex"}
                    h={"120px"}
                    w={"full"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Text>No result found</Text>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DashboardComponent;
