export enum ConnectorApi {
  CREATE_CONNECTOR = "/connector",
  UPDATE_CONNECTOR = "/connector",
  SIMULATE_CONNECTOR = "/connector/simulator",
  GET_CONNECTOR = "/connector",
  DELETE_CONNECTOR = "/connector",
  GET_LIST_CONNECTOR_BY_CHARGE_STATION = "/connector",
  GET_QR_CODE = "/connector/qr-code"
}

export interface BODY_CREATE_CONNECTOR {
  connectors: [
    {
      rate: { id: string },
      format: string,
      type: string,
      powerType: string,
      power: number,
    },
  ],
}

export interface BODY_UPDATE_CONNECTOR {
  rate: { id: string },
  format: string,
  type: string,
  powerType: string,
  power: number,
}
export interface BODY_SIMULATE_CONNECTOR {
  status: string
}

export interface ICHARGE_STATION_PARAMS {
  value: string;
  check?: boolean;
}

export enum SIMULATE_EVENT {
  AVAILABLE = 'Available',
  PREPARING = 'Preparing',
  FINISHING = 'Finishing',
  FAULTED = 'Faulted',
}

export const simulateActions = [
  { value: SIMULATE_EVENT.AVAILABLE, label: SIMULATE_EVENT.AVAILABLE },
  { value: SIMULATE_EVENT.PREPARING, label: SIMULATE_EVENT.PREPARING },
  { value: SIMULATE_EVENT.FINISHING, label: SIMULATE_EVENT.FINISHING },
  { value: SIMULATE_EVENT.FAULTED, label: SIMULATE_EVENT.FAULTED },
]