import { useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import TemplateLayout from "src/components/layout";
import CheckEmailComponent from "src/template/check-email";
import ForgotPasswordComponent from "src/template/forgot-password";
import ResendEmailComponent from "src/template/resend-email";

const ForgotPasswordPage = () => {
  const [step, setStep] = useState(1);
  const [requireValue, setRequiredValue] = useState({ email: "" });

  const handleGetEmail = (data) => {
    setRequiredValue({ email: data });
  }

  const renderStep = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <ForgotPasswordComponent setStep={() => setStep(2)} setEmail={handleGetEmail} />
        );
      case 2:
        return <CheckEmailComponent setStep={() => setStep(3)} email={requireValue.email} />;
      case 3:
        return <ResendEmailComponent email={requireValue.email} />;
      default:
        return (
          <ForgotPasswordComponent setStep={() => setStep(2)} setEmail={handleGetEmail} />
        );
    }
  }, [step]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <TemplateLayout>
          <Box height={"calc((100vh - 86px) - 70px)"}>
            <Box
              height={"full"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {renderStep}
            </Box>
          </Box>
        </TemplateLayout>
      </HelmetProvider>
    </>
  );
};

export default ForgotPasswordPage;
