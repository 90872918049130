import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import { authAPI } from "../hook/auth/auth";
import { locationAPI } from "src/hook/location/location";
import { userAPI } from "src/hook/user/user";
import { chargeStationApi } from "src/hook/charge-station/charge-station";
import { connectorAPI } from "src/hook/connector/connector";

import sidebarSlice from "src/hook/sidebar/sidebar";
import playgroundSlice from "src/hook/playground/playground";
import headerSlice from "src/hook/header/header";
import { webhookApi } from "src/hook/webhook/webhook";
import { sessionAPI } from "src/hook/session/session";
import { logAPI } from "src/hook/logs/logs";
import { playgroundAPI } from "src/hook/playground/playground";
import { companyAPI } from "src/hook/company/company";
import { chargerHistoryAPI } from "src/hook/charger-history/charger-history";
import { dashboardAPI } from "src/hook/dashboard/dashboard";

export const store = configureStore({
  reducer: {
    [locationAPI.reducerPath]: locationAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [userAPI.reducerPath]: userAPI.reducer,
    [chargeStationApi.reducerPath]: chargeStationApi.reducer,
    [connectorAPI.reducerPath]: connectorAPI.reducer,
    [webhookApi.reducerPath]: webhookApi.reducer,
    [sessionAPI.reducerPath]: sessionAPI.reducer,
    [logAPI.reducerPath]: logAPI.reducer,
    [chargerHistoryAPI.reducerPath]: chargerHistoryAPI.reducer,
    [playgroundAPI.reducerPath]: playgroundAPI.reducer,
    [companyAPI.reducerPath]: companyAPI.reducer,
    [dashboardAPI.reducerPath]: dashboardAPI.reducer,
    sidebar: sidebarSlice.reducer,
    playground: playgroundSlice.reducer,
    headerFilter: headerSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      authAPI.middleware,
      locationAPI.middleware,
      userAPI.middleware,
      chargeStationApi.middleware,
      connectorAPI.middleware,
      sessionAPI.middleware,
      logAPI.middleware,
      chargerHistoryAPI.middleware,
      webhookApi.middleware,
      playgroundAPI.middleware,
      companyAPI.middleware,
      dashboardAPI.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
