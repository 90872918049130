export enum WebhookApi {
    GET_LIST = "/webhook",
    CREATE = "/webhook",
    UPDATE = "/webhook",
    DELETE = "/webhook"
}

export interface WEBHOOK_BODY {
    url: string;
}

export interface WEBHOOK_GET_LIST_BODY {
    companyId?: string;
}

export interface WEBHOOK_UPDATE_BODY {
    id: string;
    url: string;
    active: boolean;
}
