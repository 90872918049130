import { alertAnatomy } from '@chakra-ui/anatomy';
import {
    createMultiStyleConfigHelpers,
} from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(alertAnatomy.keys);

const customSuccess = definePartsStyle({
    container: {
        background: '#C3D52C',
    },
    title: {
        color: '#020D21',
    },
});


export const alertTheme = defineMultiStyleConfig({
    variants: { customSuccess },
});