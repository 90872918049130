export const getListTimezone = () => {
    const listTimezoneItem: string[] = [];
    for (let i = -12; i <= 12; i++) {
        if (i >= 0) {
            listTimezoneItem.push(`UTC+${i.toString().padStart(2, "0")}:00`);
        } else {
            listTimezoneItem.push(
                `UTC-${Math.abs(i).toString().padStart(2, "0")}:00`
            );
        }
    }
    return listTimezoneItem
}

export function getCurrentTimeZoneOffset() {
    const offset = new Date().getTimezoneOffset();
    const hourOffset = -(offset / 60);

    return hourOffset;
}