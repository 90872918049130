import * as yup from "yup";

export const schema = yup.object().shape({
  name: yup.string().trim().required("Name is a required field"),
  role: yup.number(),
  company: yup.object().when('role', (role: any) => {
    if (role[0] === 1 || role === 1) {
      return yup.object().shape({
        id: yup.string().trim()
      }).test("company", "Company is not valid", function (value) {
        if (!value.id) {
          return this.createError({
            path: "company",
            message: "Company is a required field",
          });
        }
        return true;
      })
    }
    return yup.object().notRequired().strip()
  }),
  long: yup.number(),
  lat: yup.number(),
  address: yup
    .object()
    .shape({
      streetNumber: yup.string().trim(),
      postalCode: yup.string().trim(),
      city: yup.string().trim(),
      state: yup.string().trim(),
      country: yup.string(),
    })
    .test("address", "Address is not valid", function (value) {
      if (
        !value.streetNumber ||
        !value.postalCode ||
        !value.city ||
        !value.state ||
        !value.country
      ) {
        return this.createError({
          path: "address",
          message: "All address fields must be filled in",
        });
      }
      return true;
    }),
  timezone: yup.string().required(),
  notes: yup.string(),
});
