import { Box } from "@chakra-ui/react";
import { FC } from "react";
import { Props } from "./custom-table.type";
import CustomTable from "./table.component";

const TableComponent: FC<Props> = ({
  data,
  isLoading,
  columns,
  name,
  pageIndex,
  ...rest
}) => {

  return (
    <Box
      minH={
        name === "document-step" ? "unset" : "75px"
      }
      h={"auto"}
      id={"table"}
      position={"relative"}
    >
      <CustomTable
        columns={columns}
        data={data}
        isLoading={isLoading}
        name={name}
        pageIndexDefault={pageIndex}
        {...rest}
      />
    </Box>
  );
};

export default TableComponent;
