export const CURL_START_SESSION = (token: string | null, idConnector: any) =>{
   return `curl --location 'https://api.evcharging-stg.madlab.tech/session' \n
    --header 'Content-Type: application/json' \n
    --header 'Authorization: Bearer ${token}' \n
    --data {
        "connector": {
            "id": "${idConnector}"
        }
    }`
}

export const CURL_STOP_SESSION = (token: string | null, idConnector: any) =>{
    return `curl --location --request PATCH 'https://api.evcharging-stg.madlab.tech/session' \n
    --header 'Content-Type: text/plain' \n
    --header 'Authorization: Bearer ${token}' \n
    --data {
        "connector": {
            "id": "${idConnector}"
        }
    }`
 }

 export const CURL_GET_SESSION = ( idConnector: any, token: string | null) =>{
    return `curl --location 'https://api.evcharging-stg.madlab.tech/session/${idConnector}' \n
    --header 'Authorization: Bearer ${token}'`
 }