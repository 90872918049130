import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";

export const BaseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers) => {
    const token =
      typeof window !== "undefined" && localStorage.getItem("token");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result: any = await BaseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (typeof window !== undefined) {
      localStorage.removeItem("token");
      // navigate("/login");
    }
  }
  return result;
};
