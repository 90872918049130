export enum SessionApi {
  START_SESSION = "/session",
  STOP_SESSION = "/session",
  GET_LIST_SESSION = "/session",
  GET_SESSION_BY_ID = "/session",
  DOWNLOAD_CSV = "/session/download/csv"
}
interface FilterType {
  value: string;
  label: string;
  check: boolean;
}
export interface GET_LIST_SESSION_TYPE {
  listLocation?: FilterType[];
  listCS: FilterType[];
  listConnector: FilterType[];
  page?: number;
  take?: number;
  companyIdList?: string[];
  startDay?: string;
  stopDay?: string;
  timezone?: string
}