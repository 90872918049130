import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Input,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef } from "react";
import { useForm } from "react-hook-form";
import { schema } from "./add-webhook.schema";
import { FormValues } from "./add-webhook.type";
import { useCreateWebhookMutation } from "src/hook/webhook/webhook";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}

const LIST_SUBCRIBE = [
  "Select all",
  "session.updated",
  "session.started",
  "session.ended",
];

const AddWebhookComponent = (props: Props) => {
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const [createWebhook, { isLoading }] = useCreateWebhookMutation();
  const { isOpen, onClose } = props;
  const ref = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const handleSubmitForm = (data: FormValues) => {
    createWebhook(data)
      .then(() => {
        showToast({
          title: "Create Webhook",
          dis: "Create webhook successfully.",
        });
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Create Webhook",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={ref as any}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"1088px"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody p={"40px"}>
            <Text fontFamily={"Avenir Black"} fontSize={"40px"} height={"80px"}>
              Add Webhook URL
            </Text>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Flex mb={"40px"} alignItems={"center"} mt={"15px"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  URL<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box height={"40px"}>
                  <Input
                    required
                    w={"440px"}
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    placeholder={"Enter your url"}
                    {...register("url", {
                      shouldUnregister: true,
                    })}
                  ></Input>
                  {errors.url && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.url.message}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex mb={"40px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Subcribe to<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Menu>
                  <MenuButton
                    disabled
                    as={Box}
                    w={"440px"}
                    bgColor={"white"}
                    borderRadius={"10px"}
                    px={"16px"}
                    display={"flex"}
                    height={"45px"}
                    alignItems={"center"}
                    pos={"relative"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                  >
                    <Text
                      color={"black"}
                      fontFamily={"Avenir Book"}
                      fontWeight="300"
                      fontSize={"18px"}
                    >
                      {LIST_SUBCRIBE[0]}
                    </Text>
                    <Box
                      position={"absolute"}
                      top={"0px"}
                      height={"full"}
                      bg={"#0A47BA"}
                      w={"65px"}
                      right="0px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ChevronDownIcon
                        border="0px"
                        width={"32px"}
                        height={"32px"}
                        color={"white"}
                      />
                    </Box>
                  </MenuButton>
                  <MenuList w={"440px"}>
                    {LIST_SUBCRIBE.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          _hover={{ bgColor: "#C3D52C20" }}
                          fontSize={"18px"}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex justifyContent={"center"}>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                >
                  Create
                </Button>
              </Flex>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddWebhookComponent;
