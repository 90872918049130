import {
  Box,
  Button,
  Text,
  Icon,
  Switch,
  useDisclosure,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useState, useEffect } from "react";
import TableComponent from "src/components/custom-table";
import { HiPlus } from "react-icons/hi";
import { ReactComponent as DownloadIcon } from "src/assets/icon/download-icon.svg";
import { RowType } from "./location.type";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import AddNewLocationComponent from "../../../components/modal/add-new-location/add-new-location.modal";
import ConfirmDeleteLocationComponent from "src/components/modal/confirm-delete-location/confirm-delete-location.modal";
import SuccessDeleteComponent from "src/components/modal/success-delete/success-delete.modal";
import {
  useGetListLocationQuery,
  useUpdateLocationMutation,
  downloadLocationCsv,
} from "src/hook/location/location";
import UpdateLocationComponent from "src/components/modal/update-location/update-location.modal";
import { PaginationType } from "src/constants";
import { downloadCsv } from "src/constants/download-csv";
import { getToastAttribute, ToastAttribute } from "src/constants";
import { useGetUserDetailQuery } from "src/hook/user/user";
import { useAppSelector } from "src/redux";

const LocationComponent = () => {
  const [updateLocation] = useUpdateLocationMutation();
  const [filterParams, setFilterParams] = useState("");
  const [listLocation, setListLocation] = useState<RowType[]>([]);
  const [idLocation, setIdLocation] = useState();
  const [pageIndex, setPageIndex] = useState<PaginationType>({
    page: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageCount: 0,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const {
    isOpen: isOpenConfirmDelete,
    onOpen: onOpenConfirmDelete,
    onClose: onCloseConfirmDelete,
  } = useDisclosure();
  const {
    isOpen: isOpenSuccess,
    onOpen: onOpenSuccess,
    onClose: onCloseSuccess,
  } = useDisclosure();
  const {
    isOpen: isOpenEdit,
    onOpen: onOpenEdit,
    onClose: onCloseEdit,
  } = useDisclosure();

  const filterCompanies = useAppSelector(
    (state) => state?.headerFilter?.companyId
  );
  const { data, isFetching, isLoading, refetch } = useGetListLocationQuery({
    page: pageIndex.page,
    companyId: filterParams,
  });

  useEffect(() => {
    if (filterCompanies?.length) {
      setFilterParams(
        filterCompanies.map((companyId) => `companyId[]=${companyId}`).join("&")
      );
    } else {
      setFilterParams("");
    }
  }, [filterCompanies]);

  const { data: userDetailData } = useGetUserDetailQuery();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const handleDownloadCsv = async () => {
    setIsDownloadLoading(true);
    await downloadLocationCsv(filterParams)
      .then((data) => {
        downloadCsv(data, "location");
      })
      .catch(() => {
        showToast({
          title: "Export data",
          dis: "Export data failed",
          sts: "error",
        });
      });
    setIsDownloadLoading(false);
  };

  const changeStatus = (e) => {
    setListLocation((pre) => {
      const newList = pre.map((elm) => {
        if (elm.id === e.target.id) {
          updateLocation({
            body: {
              ...elm,
              active: !elm.active,
            },
            id: elm.id,
          } as any);
          return {
            ...elm,
            active: !elm.active,
          };
        }
        return elm;
      });
      return newList;
    });
  };

  const actionComponent = (row: RowType) => {
    return (
      <Box color={"#0A47BA"} display={"flex"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          mr={"20px"}
          cursor={"pointer"}
          onClick={() => {
            setIdLocation(row as any);
            onOpenEdit();
          }}
        >
          <Icon as={FiEdit3} width={"20px"} height={"20px"} mr={"5px"} />
          <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
            Edit
          </Text>
        </Box>

        <Box
          display={"flex"}
          alignItems={"center"}
          cursor={"pointer"}
          onClick={() => {
            setIdLocation(row as any);
            onOpenConfirmDelete();
          }}
        >
          <Icon
            as={RiDeleteBinLine}
            width={"20px"}
            height={"20px"}
            mr={"5px"}
          />
          <Text fontSize={"18px"} fontFamily={"Avenir Heavy"}>
            Delete
          </Text>
        </Box>
      </Box>
    );
  };

  const statusAction = (row: RowType) => {
    return (
      <Box fontSize={"18px"} display={"flex"} alignItems={"center"}>
        <Box>
          <Text
            w={"88px"}
            fontFamily={"Avenir Medium"}
            color={row?.active ? "#0A47BA" : "#E74D3D"}
          >
            {row?.active ? "Enabled" : "Disabled"}
          </Text>
        </Box>

        <Switch
          id={row?.id}
          colorScheme={"#0A47BA"}
          size="md"
          isChecked={row?.active}
          onChange={(e) => changeStatus(e)}
        />
      </Box>
    );
  };

  const convertName = (row: RowType) => {
    return (
      <Tooltip label={row?.name}>
        <Text
          textDecoration={"underline"}
          w={"fit-content"}
          fontSize={"18px"}
          fontFamily={"Avenir Heavy"}
          color={"#0A47BA"}
          maxW={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
        >
          {row?.name}
        </Text>
      </Tooltip>
    );
  };

  const convertAddress = (row: RowType) => {
    return (
      <Tooltip label={(row?.address as any).streetNumber}>
        <Text
          maxW={"150px"}
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}
          lineHeight={"30px"}
          fontFamily={"Avenir Book"}
        >
          {(row?.address as any).streetNumber}
        </Text>
      </Tooltip>
    );
  };

  const columns = useMemo(() => {
    const columnValue = [
      {
        Header: "Name",
        accessor: (row: RowType) => convertName(row),
      },
      {
        Header: "Address",
        accessor: (row: RowType) => convertAddress(row),
      },
      {
        Header: "City",
        accessor: "address.city",
      },
      {
        Header: "No. of chargers",
        accessor: "chargeStations.length",
      },
      {
        Header: "Status",
        accessor: (row: RowType) => statusAction(row),
      },
      {
        Header: "Action",
        accessor: (row: RowType) => actionComponent(row),
      },
    ];
    if (userDetailData?.data?.role?.id === 3) columnValue.splice(-2, 2);

    return columnValue;
  }, [listLocation]);

  useEffect(() => {
    setListLocation(data?.data?.data ? data?.data?.data : []);
    const panigation = data?.data?.meta;
    if (panigation) {
      setPageIndex({
        page: panigation?.page,
        hasNextPage: panigation?.hasNextPage,
        hasPreviousPage: panigation?.hasPreviousPage,
        pageCount: panigation?.pageCount,
      });
    }
  }, [data]);

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#ffffff"}
        overflow={"hidden"}
        p={"74px 90px 74px 40px"}
      >
        <Box
          mb={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            Locations
          </Text>
          <Box>
            <Button
              style={{
                display:
                  userDetailData?.data?.role?.id !== 3
                    ? "inline-block"
                    : "none",
              }}
              variant={"solid"}
              mr={"8px"}
              bgColor={"#0A47BA"}
              color={"#FFFFFF"}
              borderRadius={"37px"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              _hover={{
                bgColor: "#0A47BA",
                opacity: "0.8",
              }}
              leftIcon={
                <Icon as={HiPlus} width={"22px"} height={"22px"} mr={"8px"} />
              }
              onClick={onOpen}
            >
              Create
            </Button>

            <Button
              variant={"outline"}
              color={"#124BB7"}
              borderRadius={"37px"}
              borderColor={"#124BB7"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              _hover={{
                opacity: "0.8",
              }}
              isLoading={isDownloadLoading}
              isDisabled={isDownloadLoading}
              onClick={handleDownloadCsv}
            >
              <DownloadIcon />
              <Text ml={"16px"}>Export</Text>
            </Button>
          </Box>
        </Box>
        <TableComponent
          isLoading={isFetching || isLoading}
          columns={columns as any}
          data={listLocation}
          name={"location"}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
        />
      </Box>

      {isOpen && (
        <AddNewLocationComponent
          isOpen={isOpen}
          onClose={onClose}
          refetch={refetch}
          userDetailData={userDetailData}
          setPageIndex={setPageIndex}
        />
      )}
      {isOpenEdit && (
        <UpdateLocationComponent
          currentLocation={idLocation as any}
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          setListLocation={setListLocation}
          setPageIndex={setPageIndex}
          listLocation={listLocation}
        />
      )}
      {isOpenConfirmDelete && (
        <ConfirmDeleteLocationComponent
          idLocation={(idLocation as any)?.id}
          isOpen={isOpenConfirmDelete}
          onClose={onCloseConfirmDelete}
          setOpenSuccess={onOpenSuccess}
          setListLocation={setListLocation}
          setPageIndex={setPageIndex}
          listLocation={listLocation}
          refetch={refetch}
        />
      )}
      {isOpenSuccess && (
        <SuccessDeleteComponent
          statusSuccess="Location successfully deleted"
          isOpen={isOpenSuccess}
          onClose={onCloseSuccess}
        />
      )}
    </Box>
  );
};

export default LocationComponent;
