export interface SelectType {
    value: string;
    label: string;
    check: boolean;
}

export interface ItemInterface {
    id: string;
    name: string;
}

export interface SessionInterface {
    connector: {
        id: string;
    }
    status: string;
    id: string;
    chargeStation: {
        location: {
            name: string;
        }
        name: string;
    }
    duration: number;
    cost: number;
    consumption: number;
    createdAt: string;
    updatedAt: string;
}

export const SESSION_STATUS = {
    ENDED: "Ended",
    STARTED: "Started"
}