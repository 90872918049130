export enum LogApi {
  GET_LOG = "/ocpp-log",
  EXPORT_LOG_CSV = "/ocpp-log/export"
}

export interface GET_LOG_PARAMS {
  page?: number;
  take?: number;
  search?: string;
  companyIdList?: string[];
  messageList?: string[];
  chargeStationIdList?: string[];
  startDay?: string;
  stopDay?: string;
  timezone?: string
}