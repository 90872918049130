import chakraTheme from "@chakra-ui/theme";

const { Select } = chakraTheme.components;

const SelectTheme = {
  ...Select,
  variants: {
    base: {
      icon: {
        w: "4rem",
        right: "0",
        background: "#0A47BA !important",
        borderRightRadius: "lg",
        color: "white",
        fontSize: "3xl",
      },
    },
  },
};

export default SelectTheme;
