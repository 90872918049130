import { FC } from "react";
import { Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import FooterComponent from "../footer";
import HeaderComponent from "../header";
import LeftBar from "../left-bar";
type Props = {
  children: JSX.Element;
};

const LIST_PAGE_DONT_SHOW_SIDEBAR = [
  "login",
  "sign-up",
  "password",
  "users/create",
  "update",
];

const LIST_PAGE_SHOW_FOOTER = ["login", "sign-up", "password"];

const TemplateLayout: FC<Props> = ({ children }) => {
  const pathname = useLocation().pathname;
  const showSideBar = () => {
    return (
      LIST_PAGE_DONT_SHOW_SIDEBAR.findIndex((item) => pathname.includes(item)) <
      0
    );
  };

  const showFooter = () => {
    return (
      LIST_PAGE_SHOW_FOOTER.findIndex((item) => pathname.includes(item)) >= 0
    );
  };
  return (
    <Box overflow={"hidden"}>
      <HeaderComponent />
      <Box display={"flex"}>
        {showSideBar() && <LeftBar />}
        <Box
          flexDir={"column"}
          display={"flex"}
          ml={showFooter() ? "0px" : "80px"}
          transition={"all 0.3s ease"}
          flex={1}
          flexGrow={1}
          justifyContent={"space-between"}
          h={"calc(100vh - 86px)"}
          overflowY={"auto"}
          overflowX={"hidden"}
          css={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "20px",
            },
          }}
        >
          {children}
          {showFooter() && <FooterComponent />}
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateLayout;
