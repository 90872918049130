import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import { baseQueryWithReauth } from "src/services";

import {
  GET_LOG_PARAMS,
  LogApi,
} from "./log.type";

const handleLogsParams = (data: GET_LOG_PARAMS) => {
  let params = "";
  data?.companyIdList?.forEach((item) => {
    if (item) {
      params += "&companyId[]=" + item
    }
  })
  data?.messageList?.forEach((item) => {
    if (item) {
      params += "&messages[]=" + item
    }
  })
  data?.chargeStationIdList?.forEach((item) => {
    if (item) {
      params += "&chargeStationIds[]=" + item
    }
  })
  data?.startDay && (params += "&startDay=" + data.startDay)
  data?.stopDay && (params += "&stopDay=" + data.stopDay)
  data?.timezone && (params += "&timezone=" + data.timezone)
  return params
}

export const logAPI = createApi({
  reducerPath: "logAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getLog: builder.query<any, GET_LOG_PARAMS>({
      query: (body) => {
        const params = handleLogsParams(body)
        return {
          url: `${LogApi.GET_LOG}?take=${body?.take || 10}&page=${body.page}${params}`,
          method: "GET",
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetLogQuery,
} = logAPI;

export const exportLogsCsv = async (data: GET_LOG_PARAMS) => {
  const params = handleLogsParams(data)
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${LogApi.EXPORT_LOG_CSV}?${params}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}