import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";

import { DashboardApi } from "./dashboard.type";

export const dashboardAPI = createApi({
  reducerPath: "dashboardAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getListDashboard: builder.query<any, any>({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        
        return {
          url: `${DashboardApi.GET_DASHBOARD}?${queryString}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),
    getListStationUsageDashboard: builder.query<any, any>({
      query: (params) => {
        const queryString = new URLSearchParams(params).toString();
        return {
          url: `${DashboardApi.GET_STATION_USAGE_DASHBOARD}?${queryString}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetListDashboardQuery,
  useGetListStationUsageDashboardQuery,
} = dashboardAPI;
