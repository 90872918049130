import { Box } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import TemplateLayout from "src/components/layout";
import ChargerHistory from "src/template/logs/charger-history";

const ChargerHistoryPage = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Charger History | Documents</title>
        </Helmet>
        <TemplateLayout>
          <Box minHeight={"calc(100vh - 86px)"}>
            <Box
              minHeight={"calc(100vh - 86px)"}
              display={"flex"}
              justifyContent={"center"}
            >
              <ChargerHistory />
            </Box>
          </Box>
        </TemplateLayout>
      </HelmetProvider>
    </>
  );
};

export default ChargerHistoryPage;
