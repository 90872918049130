import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { FormValues } from "./edit-connector.type";
import { useForm } from "react-hook-form";
import {
  useUpdateConnectorMutation,
  useGetConnectorQuery,
} from "src/hook/connector/connector";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./edit-connector.schema";
import {
  CONNECTOR_TYPE,
  CONNECTOR_FORMAT,
  CONNECTOR_POWER_TYPE,
} from "src/constants/connector";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  currentConnector: any;
  refetch: () => void;
}

const EditConnectorComponent = (props: Props) => {
  const { isOpen, onClose, currentConnector, refetch } = props;
  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const { data } = useGetConnectorQuery(currentConnector);
  const [updateConnector, { isLoading }] = useUpdateConnectorMutation();
  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    reset,
  } = useForm<FormValues>({
    defaultValues: {
      rate: { id: "07af1f5d-9e59-4048-a3d3-20d06b1873f7" },
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    let newData;
    if (data.power) {
      newData = { ...data, power: parseFloat(data.power) };
    }
    updateConnector({ body: newData, id: currentConnector })
      .unwrap()
      .then(() => {
        showToast({
          title: "Connector update.",
          dis: "Connector is successfully updated.",
        });
        refetch();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Connector update.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };
  useEffect(() => {
    setValue("type", data?.data?.type);
    setValue("format", data?.data?.format);
    setValue("powerType", data?.data?.powerType);
    setValue("power", data?.data?.power);
  }, [data]);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef as any}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
      <AlertDialogContent
        maxH={"83%"}
        minW={"56%"}
        color={"#171E38"}
        bg={"#F2F5FC"}
        m={0}
      >
        <AlertDialogCloseButton
          _focusVisible={{
            outline: "none",
            border: "none",
          }}
          size={"lg"}
        />
        <AlertDialogHeader>
          <Box alignItems={"center"} gap={"24px"} pt={"16px"} pl={"14px"}>
            <Text fontSize="24px" fontFamily={"Avenir Black"}>
              Edit Connector
            </Text>
          </Box>
        </AlertDialogHeader>
        <AlertDialogBody
          pl={"40px"}
          pb={"0px"}
          overflow={"auto"}
          css={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "20px",
            },
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              maxW={"67%"}
              bg={"#dae3f5"}
              p={"25px"}
              borderRadius={"8px"}
              mt={"20px"}
            >
              <Box color={"#171E38"} top={"50%"} m={0}>
                <Box pt={"30px"} pb={"0px"}>
                  {/* <Box
                    display={"flex"}
                    mb={"40px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily={"Avenir Heavy"}
                      fontSize={"18px"}
                      w={"25%"}
                    >
                      Rate<span style={{ color: "#E74D3D" }}>*</span>
                    </Text>
                    <Select
                      w={"63.7%"}
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("rate.id")}
                    >
                      <option
                        style={{
                          fontSize: "18px",
                          fontFamily: "Avenir Book",
                        }}
                      >
                        07af1f5d-9e59-4048-a3d3-20d06b1873f7
                      </option>
                    </Select>
                  </Box> */}

                  <Box
                    display={"flex"}
                    mb={"40px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily={"Avenir Heavy"}
                      fontSize={"18px"}
                      w={"25%"}
                    >
                      Format<span style={{ color: "#E74D3D" }}>*</span>
                    </Text>
                    <Select
                      w={"63.7%"}
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("format")}
                    >
                      {CONNECTOR_FORMAT.map((item) => (
                        <option
                          key={item.id}
                          style={{
                            fontSize: "18px",
                            fontFamily: "Avenir Book",
                          }}
                        >
                          {item.value}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    display={"flex"}
                    mb={"40px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily={"Avenir Heavy"}
                      fontSize={"18px"}
                      w={"25%"}
                    >
                      Type<span style={{ color: "#E74D3D" }}>*</span>
                    </Text>
                    <Select
                      w={"63.7%"}
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("type")}
                    >
                      {CONNECTOR_TYPE.map((item) => (
                        <option
                          key={item.id}
                          style={{
                            fontSize: "18px",
                            fontFamily: "Avenir Book",
                          }}
                        >
                          {item.value}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    display={"flex"}
                    mb={"40px"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily={"Avenir Heavy"}
                      fontSize={"18px"}
                      w={"25%"}
                    >
                      Power type<span style={{ color: "#E74D3D" }}>*</span>
                    </Text>
                    <Select
                      w={"63.7%"}
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("powerType")}
                    >
                      {CONNECTOR_POWER_TYPE.map((item) => (
                        <option
                          key={item.id}
                          style={{
                            fontSize: "18px",
                            fontFamily: "Avenir Book",
                          }}
                        >
                          {item.value}
                        </option>
                      ))}
                    </Select>
                  </Box>

                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                  >
                    <Text
                      fontFamily={"Avenir Heavy"}
                      fontSize={"18px"}
                      w={"25%"}
                    >
                      Power (kW)<span style={{ color: "#E74D3D" }}>*</span>
                    </Text>
                    <Box w={"63.7%"}>
                      <Input
                        type="number"
                        step="any"
                        onWheel={(event) => event.currentTarget.blur()}
                        w={"100%"}
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        pr={"45px"}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("power")}
                      />
                      {errors.power && (
                        <Text
                          fontSize={"12px"}
                          mt={"4px"}
                          ml={"4px"}
                          color={"red.400"}
                          fontFamily={"Avenir Medium"}
                        >
                          {errors.power.message}
                        </Text>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              mt={"40px"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontFamily={"Avenir Black"}
              mb={"25px"}
            >
              <Button
                variant={"outline"}
                borderColor={"#020E20"}
                w={"191px"}
                height={"55px"}
                borderRadius={"37px"}
                mr={"16px"}
                fontSize={"20px"}
                onClick={() => reset()}
              >
                Reset
              </Button>
              <Button
                type="submit"
                variant={"solid"}
                bg={"#0A47BA"}
                w={"191px"}
                height={"55px"}
                borderRadius={"37px"}
                mr={"16px"}
                color={"#FFFFFF"}
                fontSize={"20px"}
                _hover={{
                  bg: "#0A47BA",
                  opacity: 0.8,
                }}
                isDisabled={!isDirty || isLoading}
                isLoading={isLoading}
                _disabled={{
                  bg: "#BEC3C7",
                  _hover: {
                    bg: "#BEC3C7",
                    opacity: "1",
                  },
                }}
              >
                Update
              </Button>
            </Box>
          </form>
        </AlertDialogBody>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default EditConnectorComponent;
