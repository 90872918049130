import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";

import { CompanyApi } from "./company.type";

export const companyAPI = createApi({
  reducerPath: "companyAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getListCompany: builder.query<any, void>({
      query: () => ({
        url: CompanyApi.GET_COMPANY,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetListCompanyQuery,
} = companyAPI;
