import { textStyles } from "./components/text";
import { styles } from "./global-style";
import { extendTheme } from "@chakra-ui/react";

import { inputStyles } from "./components/input";
import { switchTheme } from "./components/switch";
import SelectTheme from "./components/select";
import ListTheme from "./components/list";
import { alertTheme } from "./components/toast";

const theme = extendTheme({
  styles,
  components: {
    Input: inputStyles,
    Text: textStyles,
    Switch: switchTheme,
    Select: SelectTheme,
    List: ListTheme,
    Alert: alertTheme,
  },
});

export default theme;
