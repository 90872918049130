import { useState, useEffect } from "react";
import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import "./reset.css";
import schema from "./reset.schema";
import { FormValues } from "./reset.type";
import { useResetPasswordUserMutation } from "src/hook/auth/auth";
import { useValidatePasswords } from "src/constants/validate";
import RuleList from "./rules-list";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface ResetPasswordProps {
  email: string;
  token: string;
  setModal: (modal: string) => void;
}

const ResetPasswordComponent = ({ email, token, setModal }: ResetPasswordProps) => {
  const [messError, setMessError] = useState("");
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const { passwordValidation, updatePassword, setIsMatch } = useValidatePasswords();
  const [resetPasswordUser, { isLoading }] = useResetPasswordUserMutation();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const submitForm = (value: FormValues) => {
    setMessError("");
    if (isLoading) return;
    resetPasswordUser({ email: email, token: token, password: value.password })
      .unwrap()
      .then(() => {
        setModal("success")
      })
      .catch((error) =>
        showToast({
          title: "Error reset password",
          dis: error?.data?.message,
          sts: "error",
        })
      );
  };

  useEffect(() => {
    setIsMatch(isValid);
  }, [isValid]);

  return (
    <Box height={"calc((100vh - 100px) - 70px)"} mb={"60px"}>
      <Box
        display={"flex"}
        h={"fit-content"}
        justifyContent={"center"}
        mx={"auto"}
        mb={"16px"}
        color={"orange.600"}
        px={"20px"}
        py={"5px"}
        w={"fit-content"}
        bgColor={"gray.200"}
        borderRadius={"md"}
        height={"34px"}
        opacity={messError.length > 0 ? "1" : "0"}
      >
        <Text className="error-text" fontFamily={"Avenir Heavy"}>
          {messError + "."}
        </Text>
      </Box>
      <Box
        display={"flex"}
        h={"full"}
        justifyContent={"center"}
        color={"#020D21"}
      >
        <Box
          maxW={"765px"}
          px={"162px"}
          pt={"30px"}
          pb={"30px"}
          bg={"#F2F5FC"}
          rounded={"10px"}
          flexBasis={"765px"}
          flexShrink={0}
          flexGrow={0}
          overflowY={"auto"}
        >
          <Box textAlign={"center"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              lineHeight={"55px"}
            >
              Reset Password
            </Text>
          </Box>
          <form onSubmit={handleSubmit(submitForm)}>
            <input style={{ display: "none" }} />
            <input type="password" style={{ display: "none" }} />
            <Box mb={"12px"}>
              <Text fontFamily={"Avenir Heavy"} mb={"4px"} fontSize={"18px"}>
                Create your password
              </Text>
              <Input
                fontFamily={"Avenir Book"}
                bg={"white"}
                borderRadius={"lg"}
                border={
                  messError.length > 0 || errors.password
                    ? "1px solid #E74D3D"
                    : "1px solid #ffffff"
                }
                height={"2.75rem"}
                _focusVisible={{
                  border:
                    messError.length > 0 || errors.password
                      ? "1px solid #E74D3D"
                      : "1px solid #ffffff",
                }}
                _placeholder={{
                  color: "#BEC3C7",
                }}
                type={"password"}
                fontSize={"18px"}
                p={"10px 17px"}
                {...register("password", {
                  onChange: (e) => {
                    updatePassword(e.target.value);
                    trigger("password");
                  },
                })}
                _hover={{
                  border:
                    errors.password || messError.length > 0
                      ? "1px solid #E74D3D"
                      : "1px solid #ffffff",
                }}
              />
              {errors.password && (
                <Text
                  fontSize={"14px"}
                  fontFamily={"Avenir Book"}
                  color={"#E74D3D"}
                >
                  {errors.password.message}
                </Text>
              )}
            </Box>
            <Box mb={"14px"}>
              <Text fontFamily={"Avenir Heavy"} mb={"4px"} fontSize={"18px"}>
                Confirm password
              </Text>
              <Input
                fontFamily={"Avenir Book"}
                bg={"white"}
                borderRadius={"lg"}
                border={
                  messError.length > 0 || errors.confirmPassword
                    ? "1px solid #E74D3D"
                    : "1px solid #ffffff"
                }
                height={"2.75rem"}
                _focusVisible={{
                  border:
                    messError.length > 0 || errors.confirmPassword
                      ? "1px solid #E74D3D"
                      : "1px solid #ffffff",
                }}
                _placeholder={{
                  color: "#BEC3C7",
                }}
                type={"password"}
                fontSize={"18px"}
                p={"10px 17px"}
                {...register("confirmPassword", {
                  onChange: (e) => {
                    updatePassword(e.target.value);
                    trigger("confirmPassword");
                  },
                })}
                _hover={{
                  border:
                    errors.confirmPassword || messError.length > 0
                      ? "1px solid #E74D3D"
                      : "1px solid #ffffff",
                }}
              />
              {errors.confirmPassword && (
                <Text
                  fontSize={"14px"}
                  fontFamily={"Avenir Book"}
                  color={"#E74D3D"}
                >
                  {errors.confirmPassword.message}
                </Text>
              )}
            </Box>
            <RuleList
              color={passwordValidation.color}
              variant={passwordValidation.variant}
            />
            <Box mx={"12px"}>
              <Button
                fontFamily={"Avenir Black"}
                background={"#0A47BA"}
                borderRadius={"full"}
                h={"54px"}
                isLoading={isLoading}
                fontSize={"20px"}
                w={"full"}
                color={"white"}
                _hover={{
                  opacity: 0.8,
                }}
                _disabled={{
                  bg: "#BEC3C7",
                  _hover: {
                    bg: "#BEC3C7",
                    opacity: "1",
                  },
                }}
                isDisabled={
                  !isValid || passwordValidation.variant !== "successValidate"
                }
                type={"submit"}
              >
                Next
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default ResetPasswordComponent;
