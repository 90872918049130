import * as yup from "yup";

const  checkFloat = /^\s*(?=.*[1-9])\d*(?:\.\d{1,})?\s*$/

export const schema = yup.object().shape({
  connectors: yup.array().of(
    yup.object().shape({
    rate: yup
    .object()
    .shape({
     id: yup.string()
    }),
  format: yup.string(),
  type: yup.string(),
  powerType: yup.string(),
  power: yup.string().required("This field is required").matches(checkFloat, "Power must be greater than 0."),
})
  )
});
