import { Box, Button, Text, Tooltip, useToast } from "@chakra-ui/react";

import { ReactComponent as CopyIcon } from "src/assets/icon/copy-icon.svg";
import { ReactComponent as NoteIcon } from "src/assets/icon/note-icon.svg";

const ApiTokensComponent = () => {
  const toast = useToast();
  const token = localStorage.getItem("token");

  const copyTextNoti = (id: string, text: string) => {
    navigator.clipboard.writeText(id);
    toast({
      title: `${text} successfully.`,
      status: "info",
      duration: 1500,
      isClosable: true,
      position: "top",
    });
  };
  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#FBFBFB"}
        overflow={"hidden"}
        p={"74px 40px 74px 40px"}
      >
        <Box mb={"15px"}>
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            API token
          </Text>
        </Box>

        <Box pb={"40px"}>
          <Box color={"#020D21"} fontSize={"18px"}>
            <Box display={"flex"} mb={"16px"}>
              <Box minW={"180px"} display={"flex"} mr={"10px"}>
                <Text fontFamily={"Avenir Heavy"} mr={"8px"} top={"0"}>
                  API auth token
                </Text>
                <Tooltip
                  label="For quickly testing an endpoint via the API explorer. Not for use in production as it will expire shortly."
                  aria-label="A tooltip"
                  w={"318px"}
                  fontSize={"12px"}
                  color={"#020D21"}
                  bgColor={"white"}
                  fontFamily={"Avenir Book"}
                  boxShadow={"5px 7px 21px #00000026"}
                  borderRadius={"10px"}
                  height={"51px"}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box w={"20px"} h={"20px"} pt={1}>
                    <NoteIcon />
                  </Box>
                </Tooltip>
              </Box>
              <Box>
                <Text
                  fontFamily={"Avenir Book"}
                  wordBreak={"break-word"}
                  mb={"16px"}
                  maxW={"42%"}
                >
                  {token}
                </Text>
                <Button
                  fontSize={"20px"}
                  fontFamily={"Avenir Black"}
                  w={"262px"}
                  h={"55px"}
                  borderRadius={"28px"}
                  border={"1px solid #1f2c4f"}
                  bg={"unset"}
                  mb={"16px"}
                  onClick={() => copyTextNoti(`${token}`, "Copy auth token")}
                >
                  <CopyIcon stroke="#020D21" width={"27px"} height={"27px"} />
                  <Text ml={"28px"}>Copy auth token</Text>
                </Button>
                {/* <Box mb={"8px"}>
                  <Button
                    fontSize={"20px"}
                    fontFamily={"Avenir Black"}
                    w={"262px"}
                    h={"55px"}
                    borderRadius={"28px"}
                    bg={"unset"}
                    mr={"16px"}
                    variant={"solid"}
                    bgColor={"#0A47BA"}
                    border={"none"}
                    color={"#FFFFFF"}
                    _hover={{
                      bgColor: "#0A47BA",
                      opacity: 0.8,
                    }}
                  >
                    <Text>Documentation</Text>
                  </Button>
                  <Button
                    fontSize={"20px"}
                    fontFamily={"Avenir Black"}
                    w={"262px"}
                    h={"55px"}
                    borderRadius={"28px"}
                    border={"1px solid #1f2c4f"}
                    bg={"unset"}
                  >
                    <Text>Try API explorer</Text>
                  </Button>
                </Box> */}
              </Box>
            </Box>
            <Text
              mb={"40px"}
              fontSize={"18px"}
              fontFamily={"Avenir Medium"}
              color={"#E74D3D"}
              ml={"190px"}
            >
              For quickly testing an endpoint via the API explorer. Not for use
              in production as it will expire shortly.
            </Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ApiTokensComponent;
