import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Flex,
  Input,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  MenuOptionGroup,
  Icon,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { schema } from "./edit-webhook-schema";
import { FormValues } from "./edit-webhook.type";
import { useUpdateWebhookMutation } from "src/hook/webhook/webhook";
import { ReactComponent as CopyIcon } from "src/assets/icon/copy-icon.svg";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface Props {
  onClose: () => void;
  isOpen: boolean;
  webhook: FormValues;
}

const LIST_SUBCRIBE = [
  "Select all",
  "session.updated",
  "session.started",
  "session.ended",
];

const EditWebhookComponent = (props: Props) => {
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const [updateWebhook, { isLoading }] = useUpdateWebhookMutation();
  const { isOpen, onClose, webhook } = props;
  const [showSecret, setShowSecret] = useState(false);
  const ref = useRef();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    trigger,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    defaultValues: webhook,
  });

  const copyTextNoti = (id: string, text: string) => {
    navigator.clipboard.writeText(id);
    toast({
      title: `${text} success.`,
      status: "info",
      duration: 1500,
      isClosable: true,
      position: "top",
    });
  };

  const handleSubmitForm = (data: FormValues) => {
    updateWebhook({ ...data, id: webhook.id })
      .unwrap()
      .then(() => {
        showToast({
          title: "Webhook update",
          dis: "Webhook is successfully updated.",
        });
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Webhook update",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={ref as any}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"1088px"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody p={"40px"}>
            <Text fontFamily={"Avenir Black"} fontSize={"40px"} height={"80px"}>
              Edit Webhook URL
            </Text>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Flex mb={"40px"} alignItems={"center"} mt={"15px"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  URL<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Flex gap={"8px"}>
                  <Box height={"40px"}>
                    <Input
                      required
                      w={"440px"}
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("url")}
                    ></Input>
                    {errors.url && (
                      <Text
                        fontSize={"12px"}
                        mt={"4px"}
                        ml={"4px"}
                        color={"red.400"}
                        fontFamily={"Avenir Medium"}
                        fontStyle={"18px"}
                      >
                        {errors.url.message}
                      </Text>
                    )}
                  </Box>
                  <Menu>
                    <MenuButton
                      disabled
                      as={Box}
                      w={"240px"}
                      bgColor={"white"}
                      borderRadius={"10px"}
                      px={"16px"}
                      display={"flex"}
                      height={"45px"}
                      alignItems={"center"}
                      pos={"relative"}
                      overflow={"hidden"}
                      cursor={"pointer"}
                    >
                      <Box
                        color={"black"}
                        fontFamily={"Avenir Book"}
                        border={"none"}
                        fontWeight="300"
                        fontSize={"18px"}
                      >
                        {getValues("active") ? "Enable" : "Disable"}
                      </Box>
                      <Box
                        position={"absolute"}
                        top={"0px"}
                        height={"full"}
                        bg={"#0A47BA"}
                        w={"65px"}
                        right="0px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <ChevronDownIcon
                          border="0px"
                          width={"32px"}
                          height={"32px"}
                          color={"white"}
                        />
                      </Box>
                    </MenuButton>
                    <MenuList w={"240px"}>
                      <MenuOptionGroup type="radio">
                        <MenuItem
                          _hover={{ bgColor: "#C3D52C20" }}
                          onClick={() => {
                            setValue("active", !getValues("active"));
                            trigger("active");
                          }}
                        >
                          {getValues("active") ? "Disable" : "Enable"}
                        </MenuItem>
                      </MenuOptionGroup>
                    </MenuList>
                  </Menu>
                </Flex>
              </Flex>
              <Flex mb={"40px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Secret
                </Text>
                <Box pos={"relative"} mr={"8px"}>
                  <Input
                    readOnly
                    w={"440px"}
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    defaultValue={webhook.secret}
                    type={showSecret ? "" : "password"}
                  ></Input>
                  <Box
                    pos={"absolute"}
                    onClick={() => setShowSecret(() => !showSecret)}
                    top={"13px"}
                    right={"16px"}
                    cursor={"pointer"}
                  >
                    <Icon
                      as={showSecret ? AiOutlineEye : AiOutlineEyeInvisible}
                      width={"23px"}
                      height={"20px"}
                    />
                  </Box>
                </Box>
                {showSecret && (
                  <CopyIcon
                    cursor={"pointer"}
                    stroke="#0A47BA"
                    width={"17px"}
                    height={"17px"}
                    onClick={() =>
                      copyTextNoti(webhook.secret, "Copy webhook secrect")
                    }
                  />
                )}
              </Flex>
              <Flex mb={"40px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Subcribe to<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Menu>
                  <MenuButton
                    disabled
                    as={Box}
                    w={"440px"}
                    bgColor={"white"}
                    borderRadius={"10px"}
                    px={"16px"}
                    display={"flex"}
                    height={"45px"}
                    alignItems={"center"}
                    pos={"relative"}
                    overflow={"hidden"}
                    cursor={"pointer"}
                  >
                    <Text
                      color={"black"}
                      fontFamily={"Avenir Book"}
                      fontWeight="300"
                      fontSize={"18px"}
                    >
                      {LIST_SUBCRIBE[0]}
                    </Text>
                    <Box
                      position={"absolute"}
                      top={"0px"}
                      height={"full"}
                      bg={"#0A47BA"}
                      w={"65px"}
                      right="0px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <ChevronDownIcon
                        border="0px"
                        width={"32px"}
                        height={"32px"}
                        color={"white"}
                      />
                    </Box>
                  </MenuButton>
                  <MenuList w={"440px"}>
                    {LIST_SUBCRIBE.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          _hover={{ bgColor: "#C3D52C20" }}
                          fontSize={"18px"}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Flex>
              <Flex justifyContent={"center"}>
                <Button
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                >
                  Save
                </Button>
              </Flex>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default EditWebhookComponent;
