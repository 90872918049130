import { Box, Button, Text, useToast } from "@chakra-ui/react";

import { useForgotPasswordUserMutation } from "src/hook/auth/auth";
import { getToastAttribute, ToastAttribute } from "src/constants";

export interface EmailProps {
  email: string;
  setStep: () => void;
}

const CheckEmailComponent = ({ email, setStep }: EmailProps) => {
  const [forgotPasswordUser, { isLoading }] = useForgotPasswordUserMutation();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const handleResendEmail = () => {
    forgotPasswordUser({ email: email })
      .unwrap()
      .then(() => {
        showToast({
          title: "Resent reset link",
          dis: "Reset link has been resent successfully.",
        });
        setStep()
      })
      .catch((error) =>
        showToast({
          title: "Error reset link",
          dis: error?.data?.message,
          sts: "error",
        })
      );
  }

  return (
    <Box>
      <Box
        maxW={"565px"}
        px={"62px"}
        pt={"40px"}
        pb={"30px"}
        bg={"#F2F5FC"}
        display={"flex"}
        justifyContent={"center"}
        rounded={"10px"}
        color={"#020D21"}
      >
        <Box w={"full"}>
          <Box textAlign={"center"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              lineHeight={"55px"}
            >
              Check your email
            </Text>
            <Text fontFamily={"Avenir Book"}>We&apos;ve sent a password reset link to your email. Please check your inbox!</Text>
            <Text fontFamily={"Avenir Black"}>Didn&apos;t Receive?</Text>
          </Box>
          <Box mx={"13px"} mt={"13px"}>
            <Button
              fontFamily={"Avenir Black"}
              background={"#0A47BA"}
              borderRadius={"full"}
              h={"54px"}
              isLoading={isLoading}
              fontSize={"20px"}
              w={"full"}
              color={"white"}
              _hover={{
                opacity: 0.8,
              }}
              _disabled={{
                bg: "#BEC3C7",
                _hover: {
                  bg: "#BEC3C7",
                  opacity: "1",
                },
              }}
              onClick={handleResendEmail}
            >
              Resend email
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CheckEmailComponent;
