import { Divider, List, ListItem, Text } from "@chakra-ui/react";
function PolicyTab() {
  return (
    <>
      <Text fontSize={"40px"} fontFamily="Avenir Black" mb={3}>
        Terms & Conditions
      </Text>
      <Text fontSize={"md"} mb={5}>
        This Data Protection Notice (&quot;Notice&quot;) sets out the basis
        which Madison Ci Pte. Ltd. (&quot;we&quot;, &quot;us&quot;, or
        &quot;our&quot;) may collect, use disclose or otherwise process personal
        data of our customers in accordance with the Personal Data Protection
        Act (&quot;PDPA&quot;). This Notice applies to personal data in our
        possession or under our control, including personal data in the
        possession of organisations which we have engaged to collect, use,
        disclose or process personal data for our purposes
      </Text>
      <Divider mb={5} color="#020D21" />
      <List variant="orderListNumber" mb={5}>
        <Text fontSize={"2xl"} fontFamily="Avenir Black" mb={5}>
          Personal Data
        </Text>
        <ListItem>
          As used in this Notice:
          <Text mt={5}>
            &quot;customer&quot; means an individual wha (a) has contacted us
            through any means to find out more about any goods or services we
            provide, or (b) may, or has, entered into a contrac with us for the
            supply of any goods or services by us; and
          </Text>
          <Text mt={5}>
            &quot;personal data&quot; means data, whether true or not, about a
            customer who can be identified: (a) from that data; or (b) from that
            data and other information to which we have or are likely to have
            access
          </Text>
        </ListItem>
        <ListItem>
          Depending on the nature of your interaction with us, some examples of
          personal data which we may collect from you include your name and
          identification information such as your NRIC number, contact
          information such as your address, email address or telephone number,
          nationality, gender, date of birth, marital status, photographs and
          other audio-visual information, employment information such as credit
          card numbers, debit card numbers or bank account
        </ListItem>
        <Divider mb={5} mt={5} color="#020D21" />
        <Text fontSize={"2xl"} fontFamily="Avenir Black" mb={5}>
          Collection, Use and Disclosure of Personal Data
        </Text>
        <ListItem>
          We generally do not collect your personal data unless (a) it is
          provided to us voluntarily by you directly or via a third party who
          has been duly authorised by you to disclose your personal data to us
          (your &quot;authorised representative&quot;) after (i) you (or your
          authorised representative) have been notified of the purposes for
          which the data is collected, and (ii) you (or your authorised
          representative) have provoded written consent is permitted or required
          by the PDPA or other laws, We shall seek your consent before
          collecting any additional personal data and before using your personal
          data for a purpose which has not been notified to you (except where
          permitted or authorised by law).
        </ListItem>
        <ListItem>
          We may collect and use your personal data for any or the following
          purposes:
          <List listStyleType="lower-alpha" variant="orderListLowerText">
            <ListItem>
              performing obligations in the course of or in connection with our
              provision of the goods and/or services repuested by you; verifying
              your identity;
            </ListItem>
            <ListItem>
              respponding to, handling, and processing queries, requests,
              applications, complaints, and feedback from you managing your
              relationship with us;
            </ListItem>
            <ListItem>processing payment or credit transactions;</ListItem>
            <ListItem>
              sending your marketing information about our goods or services
              including notifying you of our marketing events, initiatives and
              promotions, lucky draws, membership and rewards schemes and other
              promotions;
            </ListItem>
            <ListItem>
              complying with any applicable laws, regulation, codes of practice,
              guidelines, or rules, or rules, or to assist in law enforcement
              and investigations conducted by any governmentak and/or regulatory
              authority;
            </ListItem>
            <ListItem>
              any other purposes for which you have provided the information;
            </ListItem>
            <ListItem>
              transmitting to any unaffiliated third parties including our third
              party service providers and agents, and relevant governmental
              and/or g. regulatory authorities, whether in Singapore or abroad,
              for the aforementioned purposes; and
            </ListItem>
            <ListItem>
              any other incidental business purposes related to or in connection
              with the above.
            </ListItem>
          </List>
        </ListItem>
        <ListItem>
          We may disclose your personal data:
          <List listStyleType="lower-alpha" variant="orderListLowerText">
            <ListItem>
              where such disclosure is required for performing obligations in
              the course of or in connection with our provision of the goods
            </ListItem>
          </List>
        </ListItem>
        <Divider mb={5} mt={5} color="#020D21" />
        <Text fontSize={"2xl"} fontFamily="Avenir Black" mb={5}>
          Withdrawing your consent
        </Text>
        <ListItem>
          The consent that you provide for the collection, use and disclosure of
          your personal data will remain valid until such time it is being
          withdrawn by you in writing. You may withdraw consent and request us
          to stop using and/or disclosing your personal data for any or all of
          the purposes listed above by submitting your request in writing or via
          email to our Data Protection Office at the contact details provided
          below.
        </ListItem>
        <ListItem>
          Upon receipt of your written request to withdraw your consent, we may
          require reasonable time (depenfing on the complexity of the request
          and its impact on our relationship with you) for your request to be
          processed and for us to notify you of the consequences of us acceding
          to the same, including any legal consequences which may affect your
          rights and liabilities to us. In general, we shall seek to process
          your request within ten (10) business days of receiving it.
        </ListItem>
        <ListItem>
          Whilst we respect your decision to withdraw your consent, please note
          that depending on the nature and scope of your request, we may not be
          in a position to continue providing our goods
        </ListItem>
        <ListItem>
          Please note that withdrawing consent does not affect our right to
          continue to collect, use and disclose personal data where such
          collection, use and disclose without consent is permitter or required
          under applicable laws
        </ListItem>
      </List>
    </>
  );
}

export default PolicyTab;
