import { createSlice, PayloadAction } from "@reduxjs/toolkit";


interface GlobalFilter {
  companyId?: string[];
}

const initialState = { companyId: [] } as GlobalFilter;

const headerSlice = createSlice({
  name: "header",
  initialState,
  reducers: {
    handleGlobalFilter(state: GlobalFilter, action: PayloadAction<{ companyId: string[] }>) {
      state.companyId = action?.payload?.companyId;
    },
  },
});

export const { handleGlobalFilter } = headerSlice.actions;
export default headerSlice;
