import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef } from "react";

import { ReactComponent as ConfirmIcon } from "src/assets/icon/confirm-icon.svg";
import { PaginationType } from "src/constants";
import { useDeleteChargeStationMutation } from "src/hook/charge-station/charge-station";
import { RowType } from "src/template/network/charge-stations/charge-stations.type";
interface Props {
  idCharge: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  setOpenSuccess: () => void;
  listCharge: RowType[];
  pageIndex: PaginationType;
  setPageIndex: any;
  setSimulator: any;
  setListExpand: any;
}

const ConfirmDeleteChargeComponent = (props: Props) => {
  const {
    isOpen,
    onClose,
    idCharge,
    setOpenSuccess,
    listCharge,
    pageIndex,
    setPageIndex,
    setSimulator,
    setListExpand,
  } = props;
  const [deleteCharge, { isLoading }] = useDeleteChargeStationMutation();
  const toast = useToast();

  const cancelRef = useRef();

  const submitDelete = () => {
    if (idCharge) {
      deleteCharge(idCharge)
        .unwrap()
        .then(() => {
          if (listCharge?.length === 1 && pageIndex?.page > 1) {
            setPageIndex((pre: PaginationType) => ({
              ...pre,
              page: pre?.page - 1,
            }));
          }
          setSimulator((pre: string) => (pre === idCharge ? "" : pre));
          setOpenSuccess();
          setListExpand([]);
        })
        .catch((err) => {
          toast({
            title: "Delete Charge Station.",
            description: err?.data?.message,
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "top",
          });
        });
    }
    onClose();
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"42%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody p={"40px"} textAlign={"center"}>
            <Box display={"flex"} justifyContent={"center"} mb={"10px"}>
              <ConfirmIcon stroke={"#E54E43"} />
            </Box>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              lineHeight={"55px"}
            >
              Confirm deletion
            </Text>
            <Text fontSize={"18px"} fontFamily={"Avenir Medium"} mb={"24px"}>
              Are you sure you want to delete this charge station?
            </Text>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontFamily={"Avenir Black"}
            >
              <Button
                variant={"solid"}
                bg={"#E74D3D"}
                w={"65%"}
                height={"54px"}
                borderRadius={"37px"}
                mr={"16px"}
                color={"#FFFFFF"}
                fontSize={"20px"}
                isDisabled={isLoading}
                isLoading={isLoading}
                _hover={{
                  bg: "#E74D3D",
                  opacity: 0.8,
                }}
                onClick={submitDelete}
              >
                Delete
              </Button>
            </Box>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ConfirmDeleteChargeComponent;
