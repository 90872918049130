import { useState } from "react";
import { Box, Button, Input, Text, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";

import { useLoginUserMutation } from "src/hook/auth/auth";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormValues } from "./login.type";
import schema from "./login.schema";
import { handleCollapse } from "src/hook/sidebar/sidebar";
import { useAppDispatch } from "src/redux";
import { Link } from "react-router-dom";
import {
  getToastAttribute,
  ToastAttribute,
} from "src/constants";

const LoginComponent = () => {
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [shouldValidateEmail, setShouldValidateEmail] = useState(false);
  const dispatch = useAppDispatch();
  const [messError, setMessError] = useState("");
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const validateEmail = (value: string) => {
    if (shouldValidateEmail && value.trim() !== "") {
      try {
        schema.validateSyncAt("email", { email: value });
      } catch (err) {
        return (err as any).message;
      }
    }
    return true;
  };

  const handleEmailBlur = () => {
    setShouldValidateEmail(true);
  };

  const submitForm = (value: FormValues) => {
    setMessError("");
    loginUser(value)
      .unwrap()
      .then((res) => {
        localStorage.setItem("email", value.email);
        localStorage.setItem("token", res?.data[0]?.accessToken);
        dispatch(handleCollapse({ status: true }));
        navigate("/network/location");
      })
      .catch(() => {
        showToast({
          title: "Error",
          dis: "Your email or password is incorrect, please try again",
          sts: "error",
        });
        setMessError("Your email or password is incorrect, please try again")
      }
      );
  };

  return (
    <Box>
      <Box
        display={"none"}
        justifyContent={"center"}
        mx={"auto"}
        mb={"16px"}
        color={"orange.600"}
        px={"20px"}
        py={"5px"}
        w={"fit-content"}
        bgColor={"gray.200"}
        borderRadius={"md"}
        opacity={messError.length > 0 ? "1" : "0"}
        height={"34px"}
      >
        <Text fontFamily={"Avenir Heavy"}>{messError}</Text>
      </Box>
      <Box
        maxW={"765px"}
        px={"162px"}
        py={"20px"}
        bg={"#F2F5FC"}
        display={"flex"}
        justifyContent={"center"}
        rounded={"10px"}
        color={"#020D21"}
      >
        <Box w={"full"}>
          <Box textAlign={"center"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              lineHeight={"55px"}
            >
              Welcome to MadEV!
            </Text>
            <Text fontFamily={"Avenir Book"}>Please enter your details</Text>
          </Box>

          <form onSubmit={handleSubmit(submitForm)}>
            <Box mb={"24px"}>
              <Text fontFamily={"Avenir Heavy"} mb={"4px"} fontSize={"18px"}>
                Email
              </Text>
              <Input
                fontFamily={"Avenir Book"}
                bg={"white"}
                borderRadius={"lg"}
                border={
                  (errors.email && shouldValidateEmail) || messError.length > 0
                    ? "1px solid #E74D3D !important"
                    : "1px solid #ffffff !important"
                }
                placeholder={"Enter your email"}
                height={"2.75rem"}
                _placeholder={{
                  color: "#BEC3C7",
                }}
                _focusVisible={{
                  boxShadow: "none",
                }}
                // _hover={{
                //   border:
                //     errors.email || shouldValidateEmail || messError.length > 0
                //       ? "1px solid #E74D3D"
                //       : "1px solid #ffffff",
                // }}
                fontSize={"18px"}
                p={"10px 17px"}
                {...register("email", {
                  shouldUnregister: true,
                  onBlur: handleEmailBlur,
                  validate: validateEmail,
                  onChange: () => {
                    // setShouldValidateEmail(false);
                    // setMessError("");
                    trigger("email");
                  },
                })}
                id={"email"}
              />
              {errors.email && shouldValidateEmail && (
                <Text
                  fontSize={"14px"}
                  fontFamily={"Avenir Book"}
                  color={"#E74D3D"}
                >
                  {errors.email.message}
                </Text>
              )}
            </Box>

            <Box mb={"32px"}>
              <Text fontFamily={"Avenir Heavy"} mb={"4px"} fontSize={"18px"}>
                Password
              </Text>
              <Input
                fontFamily={"Avenir Book"}
                bg={"white"}
                borderRadius={"lg"}
                border={
                  messError.length > 0 || errors.password
                    ? "1px solid #E74D3D"
                    : "1px solid #ffffff"
                }
                height={"2.75rem"}
                _focusVisible={{
                  border:
                    messError.length > 0 || errors.password
                      ? "1px solid #E74D3D"
                      : "1px solid #ffffff",
                }}
                _placeholder={{
                  color: "#BEC3C7",
                }}
                type={"password"}
                fontSize={"18px"}
                p={"10px 17px"}
                {...register("password", {
                  onChange: () => {
                    trigger("password");
                  },
                })}
                _hover={{
                  border:
                    errors.password || messError.length > 0
                      ? "1px solid #E74D3D"
                      : "1px solid #ffffff",
                }}
              />
              {errors.password && (
                <Text
                  fontSize={"14px"}
                  fontFamily={"Avenir Book"}
                  color={"#E74D3D"}
                >
                  {errors.password.message}
                </Text>
              )}
            </Box>

            <Box mx={"13px"}>
              <Button
                fontFamily={"Avenir Black"}
                background={"#0A47BA"}
                borderRadius={"full"}
                h={"54px"}
                isLoading={isLoading}
                fontSize={"20px"}
                w={"full"}
                color={"white"}
                _hover={{
                  opacity: 0.8,
                }}
                _disabled={{
                  bg: "#BEC3C7",
                  _hover: {
                    bg: "#BEC3C7",
                    opacity: "1",
                  },
                }}
                isDisabled={!isValid}
                type={"submit"}
              >
                Log in
              </Button>
            </Box>

            <Box
              mt={"16px"}
              textAlign={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <Link to={"/password/forgot"}>
                <Text
                  color={"#0A47BA"}
                  textDecoration={"underline"}
                  fontWeight={"900"}
                  px={"10px"}
                  position={"relative"}
                  fontFamily={"Avenir Medium"}
                >
                  Forgot password?
                </Text>
              </Link>
              {/* <Text
                fontFamily={"Avenir Medium"}
                pl={"10px"}
              >{`Don't have an account?`}</Text>
              <Link to={"/sign-up"}>
                <Text
                  fontFamily={"Avenir Medium"}
                  color={"#0A47BA"}
                  textDecoration={"underline"}
                  fontWeight={"900"}
                  pl={"4px"}
                >
                  Sign up here
                </Text>
              </Link> */}
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginComponent;
