import { Box } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import TemplateLayout from "src/components/layout";
import WebhookComponent from "src/template/developers/webhook";

const WebhookPage = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Webhook | Documents</title>
        </Helmet>
        <TemplateLayout>
          <Box minHeight={"calc(100vh - 86px)"}>
            <Box
              minHeight={"calc(100vh - 86px)"}
              display={"flex"}
              justifyContent={"center"}
            >
              <WebhookComponent />
            </Box>
          </Box>
        </TemplateLayout>
      </HelmetProvider>
    </>
  );
};

export default WebhookPage;
