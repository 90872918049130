import {
  Box,
  Button,
  Flex,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { useMemo, useState, useEffect } from "react";
import moment from "moment";

import TableComponent from "src/components/custom-table";
import { ReactComponent as DownloadIcon } from "src/assets/icon/download-icon.svg";
import { ReactComponent as OriginTrueIcon } from "src/assets/icon/origin-true-icon.svg";
import { ReactComponent as OriginFalseIcon } from "src/assets/icon/origin-false-icon.svg";
import { ORIGIN_TYPE, RowType } from "./ocpp-logs.type";
import { ReactComponent as RefreshIcon } from "src/assets/icon/refresh-icon.svg";
import { ReactComponent as CopyIcon } from "src/assets/icon/copy-icon.svg";
import { exportLogsCsv, useLazyGetLogQuery } from "src/hook/logs/logs";
import { PaginationType } from "src/constants";
import { downloadCsv } from "src/constants/download-csv";
import { ItemInterface, SelectType } from "../sessions/session.type";
import { useGetListChargeStationQuery } from "src/hook/charge-station/charge-station";
import { RangePicker } from "src/components/daterange-picker";
import { useAppSelector } from "src/redux";
import { MultiSelectComponent } from "src/components/multi-select/multi-select.component";
import { LogsMessages } from "src/constants/ocpp-logs";
import { ReactComponent as NoDataIcon } from "src/assets/icon/no-data-icon.svg";
import { getCurrentTimeZoneOffset } from "src/constants/timezone";

const today = new Date();
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(today.getDate() - 7);

const LogComponent = () => {
  const [startDay, setStartDay] = useState(moment(sevenDaysAgo).format('YYYY-MM-DD'));
  const [stopDay, setStopDay] = useState(moment(today).format('YYYY-MM-DD'));
  const [isExportLoading, setExportLoading] = useState(false);
  const [listMessages, setListMessages] = useState<SelectType[]>(LogsMessages);
  const [listLog, setListLog] = useState<RowType[]>([]);
  const [listCS, setListCS] = useState<SelectType[]>([]);
  const [filterParams, setFilterParams] = useState("");
  const [pageIndex, setPageIndex] = useState<PaginationType>({
    page: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    pageCount: 0,
  });

  const toast = useToast();
  const filterCompanies = useAppSelector((state) => state?.headerFilter?.companyId)
  const [getListLog, { isFetching }] = useLazyGetLogQuery();
  const { data: listLocationData, isLoading: isCSFetching } =
    useGetListChargeStationQuery({
      take: "full",
      page: "full",
      companyId: filterParams
    });

  const copyTextNoti = () => {
    toast({
      title: "Copy ID Successfully.",
      status: "info",
      duration: 1500,
      isClosable: true,
      position: "top",
    });
  };

  const handleExportAction = async () => {
    setExportLoading(true);
    const chargeStationIdList = listCS?.filter(chargeStation => chargeStation.check).map(chargeStation => chargeStation.value);
    const messageList = listMessages.filter(message => message.check).map(message => message.value);
    const timezoneOffset = getCurrentTimeZoneOffset().toString();
    const logParams = {
      messageList: messageList,
      chargeStationIdList: chargeStationIdList,
      companyIdList: filterCompanies,
      startDay: startDay,
      stopDay: stopDay,
      timezone: timezoneOffset
    }
    await exportLogsCsv(
      logParams)
      .then((data) => downloadCsv(data, "ocpp-logs"))
      .catch(() => {
        toast({
          title: "Export csv",
          description: "Export csv failed",
          duration: 2000,
          status: "error",
          position: "top",
        });
      });
    setExportLoading(false);
  };

  const handlegetListLogs = (page: number) => {
    const chargeStationIdList = listCS?.filter(chargeStation => chargeStation.check).map(chargeStation => chargeStation.value);
    const messageList = listMessages.filter(message => message.check).map(message => message.value);
    const timezoneOffset = getCurrentTimeZoneOffset().toString();
    const logParams = {
      page: page,
      messageList: messageList,
      companyIdList: filterCompanies,
      chargeStationIdList: chargeStationIdList,
      startDay: startDay,
      stopDay: stopDay,
      timezone: timezoneOffset
    }
    setStartDay(startDay);
    setStopDay(stopDay);

    getListLog(logParams)
      .unwrap()
      .then((data) => {
        setListLog(data?.data?.data ? data?.data?.data : []);
        const panigation = data?.data?.meta;
        if (panigation) {
          setPageIndex({
            page: panigation?.page,
            hasNextPage: panigation?.hasNextPage,
            hasPreviousPage: panigation?.hasPreviousPage,
            pageCount: panigation?.pageCount,
          });
        }
      });
  }

  useEffect(() => {
    if (filterCompanies?.length) {
      setFilterParams(filterCompanies.map((companyId) => `companyId[]=${companyId}`).join('&'))
    } else {
      setFilterParams("")
    }
  }, [filterCompanies])

  useEffect(() => {
    const list = listLocationData?.data?.data?.map((item: ItemInterface) => ({
      value: item?.id,
      label: item?.name,
      check: false,
    }));
    setListCS(() => list);
  }, [listLocationData]);

  useEffect(() => {
    handlegetListLogs(1)
  }, [filterCompanies, listCS, startDay, stopDay, listMessages, pageIndex?.page]);

  useEffect(() => {
    if (pageIndex?.page !== 1) {
      handlegetListLogs(pageIndex?.page)
    }
  }, [pageIndex?.page]);

  const originAction = (row: RowType) => {
    return row?.origin === ORIGIN_TYPE.FROM_CS ? (
      <OriginTrueIcon />
    ) : (
      <OriginFalseIcon />
    );
  };

  const convertId = (row: RowType) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Tooltip label={row?.id}>
          <Text
            textDecoration={"underline"}
            w={"calc(100% - 30px)"}
            fontSize={"18px"}
            fontFamily={"Avenir Book"}
            color={"#020D21"}
            mr={"6px"}
            whiteSpace={"nowrap"}
            maxW={"200px"}
            textOverflow={"ellipsis"}
            overflow={"hidden"}
          >
            {row?.id}
          </Text>
        </Tooltip>
        <Box
          cursor={"pointer"}
          w={"fit-content"}
          onClick={() => {
            navigator.clipboard.writeText(row?.id);
            copyTextNoti();
          }}
        >
          <CopyIcon stroke="#0a47ba" width={"20px"} height={"20px"} />
        </Box>
      </Box>
    );
  };

  const convertTimeStamp = (row: RowType) => {
    const date = new Date(row?.createdAt);
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Text
          w={"fit-content"}
          fontSize={"18px"}
          fontFamily={"Avenir Book"}
          color={"#020D21"}
          mr={"6px"}
        >
          {date.toString()}
        </Text>
      </Box>
    );
  };

  const convertChargeStationName = (row: RowType) => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Tooltip label={row?.chargeStation?.name}>
          <Text
            w={"fit-content"}
            fontSize={"18px"}
            fontFamily={"Avenir Book"}
            color={"#020D21"}
            mr={"6px"}
            maxW={"250px"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            overflow={"hidden"}
          >
            {row?.chargeStation?.name}
          </Text>
        </Tooltip>
      </Box>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Message",
        accessor: "message",
      },
      {
        Header: "Chargestation",
        accessor: (row: RowType) => convertChargeStationName(row),
      },
      {
        Header: "Timestamp",
        accessor: (row: RowType) => convertTimeStamp(row),
      },
      {
        Header: "Origin",
        accessor: (row: RowType) => originAction(row),
      },
      {
        Header: "ID",
        accessor: (row: RowType) => convertId(row),
      }
    ],
    [listLog]
  );

  return (
    <Box w={"full"}>
      <Box
        w={"full"}
        h={"full"}
        bgColor={"#ffffff"}
        overflow={"hidden"}
        p={"74px 90px 74px 40px"}
        position={"relative"}
      >
        <Box
          mb={"15px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Text
            fontSize={"38px"}
            lineHeight={"52px"}
            fontWeight={"500"}
            color={"#171E38"}
            w={"fit-content"}
            fontFamily={"Avenir Black"}
          >
            OCPP logs
          </Text>
          <Box display={"flex"} alignItems={"center"} justifyContent={"end"} w={"60%"}>
            <Button
              variant={"solid"}
              mr={"8px"}
              bgColor={"#0A47BA"}
              color={"#FFFFFF"}
              borderRadius={"37px"}
              height={"55px"}
              w={"154px"}
              fontSize={"20px"}
              onClick={() => window.location.reload()}
              _hover={{
                bgColor: "#0A47BA",
                opacity: "0.8",
              }}
            >
              <RefreshIcon stroke="#FFFFFF" />
              <Text ml={"16px"}>Refresh</Text>
            </Button>

            <Button
              variant={"outline"}
              color={"#124BB7"}
              borderRadius={"37px"}
              borderColor={"#124BB7"}
              height={"55px"}
              w={"154px"}
              isLoading={isExportLoading}
              disabled={isExportLoading}
              fontSize={"20px"}
              _hover={{
                opacity: "0.8",
              }}
              onClick={handleExportAction}
            >
              <DownloadIcon />
              <Text ml={"16px"}>Export</Text>
            </Button>
          </Box>
        </Box>
        <Flex mb={"40px"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
          <MultiSelectComponent
            list={listMessages}
            setList={setListMessages}
            title="Message"
          />
          <MultiSelectComponent
            list={listCS}
            title="Chargestation"
            isFetching={isCSFetching}
            setList={setListCS}
          />
          <RangePicker
            setStartDay={setStartDay}
            setStopDay={setStopDay}
            isDefault={true}
          />
        </Flex>
        <TableComponent
          isLoading={isFetching}
          columns={columns as any}
          data={listLog}
          name={"ocpp-log"}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          noDataText={{ currentTable: " " }}
        />
        {listLog?.length === 0 && !isFetching && (
          <Flex
            w={"full"}
            align={"center"}
            direction={"column"}
            pt={"24px"}
          >
            <NoDataIcon />
            <Text
              fontSize={"18px"}
              mt={"20px"}
              fontWeight={"500"}
              color={"#BEC3C7"}
              fontFamily={"Avenir Heavy"}
            >
              {`There's no data recorded currently`}
            </Text>
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default LogComponent;
