import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SidebarState {
  isCollapse: boolean;
}

const initialState = { isCollapse: true } as SidebarState;

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    handleCollapse(state: SidebarState, action: PayloadAction<{ status: boolean }>) {
      state.isCollapse = action?.payload?.status;
    },
  },
});

export const { handleCollapse } = sidebarSlice.actions;
export default sidebarSlice;
