import { Helmet } from "react-helmet";

interface Props {
  title: string;
  description: string;
}

const SeoComponent = (props: Props) => {
  const { description, title } = props;
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content="/evcharging-react/public/MadEV.jpg" />
      <meta property="og:title" content={title} />
      <meta property="og:image:alt" content={title} />
      <meta property="og:image" content="/evcharging-react/public/MadEV.jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
    </Helmet>
  );
};

export default SeoComponent;
