import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import { baseQueryWithReauth } from "src/services";

import {
  BODY_CREATE_LOCATION,
  BODY_GET_LIST_LOCATION,
  BODY_UPDATE_LOCATION,
  LocationApi,
} from "./location.type";

const handleLocationParams = (data: BODY_GET_LIST_LOCATION) => {
  let params = "";
  if (data?.take === "full") {
    params
  } else if (data?.take) {
    params += "?take=" + data?.take
  } else {
    params += "?take=" + 10
  }
  if (data?.page === "full") {
    params
  } else if (data?.page) {
    params += "&page=" + data?.page
  }
  if (data?.take === "full" && data?.page === "full" && data?.companyId) {
    params += "?" + data?.companyId
  } else if (data?.companyId) {
    params += "&" + data?.companyId
  }
  return params
}

export const locationAPI = createApi({
  reducerPath: "locationAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getListLocation: builder.query<any, BODY_GET_LIST_LOCATION>({
      query: (body) => {
        const params = handleLocationParams(body)
        return {
          url: `${LocationApi.GET_LIST_LOCATION}${params}`,
          method: "GET",
        }
      },
      keepUnusedDataFor: 0,
    }),
    getListLocationNoPagination: builder.query<any, void>({
      query: () => ({
        url: LocationApi.GET_LIST_LOCATION_NO_PAGINATION,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    updateLocation: builder.mutation<
      any,
      { body: BODY_UPDATE_LOCATION; id: string }
    >({
      query: ({ body, id }) => ({
        url: `${LocationApi.UPDATE_LOCATION}/${id}`,
        method: "PUT",
        body: body,
      }),
    }),
    createLocation: builder.mutation<any, BODY_CREATE_LOCATION>({
      query: (body) => ({
        url: LocationApi.CREATE_LOCATION,
        method: "POST",
        body: body,
      }),
    }),
    deleteLocation: builder.mutation<any, string>({
      query: (id) => ({
        url: `${LocationApi.CREATE_LOCATION}/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetListLocationQuery,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useDeleteLocationMutation,
  useGetListLocationNoPaginationQuery
} = locationAPI;


export const downloadLocationCsv = async (data: string) => {
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${LocationApi.DOWNLOAD_CSV}?${data}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}