import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";
import { WEBHOOK_BODY, WebhookApi, WEBHOOK_UPDATE_BODY, WEBHOOK_GET_LIST_BODY } from "./webhook.type";

export const webhookApi = createApi({
  reducerPath: "webhookApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Webhook"],
  endpoints: (builder) => ({
    createWebhook: builder.mutation<any, WEBHOOK_BODY>({
      query: (body) => ({
        url: WebhookApi.CREATE,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["Webhook"],
    }),
    getListWebhook: builder.query<any, WEBHOOK_GET_LIST_BODY>({
      query: (body) => ({
        url: `${WebhookApi.GET_LIST}?${body?.companyId}`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
      providesTags: (result) =>
        result?.data
          ? [
            ...result.data.map(({ id }) => ({
              type: "Webhook" as const,
              id,
            })),
            "Webhook",
          ]
          : ["Webhook"],
    }),
    deleteWebhook: builder.mutation<any, string>({
      query: (id) => ({
        url: `${WebhookApi.DELETE}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Webhook"],
    }),
    updateWebhook: builder.mutation<any, WEBHOOK_UPDATE_BODY>({
      query: (data) => ({
        url: `${WebhookApi.DELETE}/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Webhook"],
    }),
  }),
});

export const { useCreateWebhookMutation, useGetListWebhookQuery, useDeleteWebhookMutation, useUpdateWebhookMutation } = webhookApi;
