import { Box, Button, Text, useToast } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { useForgotPasswordUserMutation } from "src/hook/auth/auth";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface ExpiredLinkProps {
  email: string;
  setModal: (modal: string) => void
  ;
}

const ExpiredLinkComponent = ({ email, setModal }: ExpiredLinkProps) => {
  const [forgotPasswordUser, { isLoading }] = useForgotPasswordUserMutation();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const handleResendEmail = () => {
    if (isLoading) return;
    forgotPasswordUser({ email: email })
      .unwrap()
      .then(() => {
        showToast({
          title: "Resent reset link",
          dis: "Reset link has been resent successfully.",
        });
        setModal("resend")
      })
      .catch((error) =>
        showToast({
          title: "Error reset link",
          dis: error?.data?.message,
          sts: "error",
        })
      );
  }

  return (
    <Box
      maxW={"500px"}
      px={"50px"}
      pt={"30px"}
      pb={"30px"}
      bg={"#F2F5FC"}
      display={"flex"}
      justifyContent={"center"}
      rounded={"10px"}
      color={"#020D21"}
    >
      <Box w={"full"}>
        <Box textAlign={"center"}>
          <Text
            fontSize={"40px"}
            fontFamily={"Avenir Black"}
            lineHeight={"55px"}
          >
            Link expired
          </Text>
          <Text fontFamily={"Avenir Book"} pb="20px">
            Unfortunately the password reset link has expired. Kindly request for a new link again
          </Text>
        </Box>
        <Box mx={"13px"}>
          <Button
            isLoading={isLoading}
            fontFamily={"Avenir Black"}
            background={"#0A47BA"}
            borderRadius={"full"}
            h={"54px"}
            fontSize={"20px"}
            w={"full"}
            color={"white"}
            _hover={{
              opacity: 0.8,
            }}
            _disabled={{
              bg: "#BEC3C7",
              _hover: {
                bg: "#BEC3C7",
                opacity: "1",
              },
            }}
            type={"submit"}
            onClick={handleResendEmail}
          >
            Resend new link
          </Button>
        </Box>
        <Box
          mt={"16px"}
          textAlign={"center"}
          display={"flex"}
          justifyContent={"center"}
        >
          <Link to={"/login"}>
            <Text
              color={"#0A47BA"}
              textDecoration={"underline"}
              fontWeight={"900"}
              px={"10px"}
              position={"relative"}
              fontFamily={"Avenir Medium"}
            >
              Back to login
            </Text>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpiredLinkComponent;
