import { Box, ListIcon, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { FC } from "react";

export interface Props {
  color: string;
  variant: string;
}

const RuleList: FC<Props> = ({ color, variant }) => {
  return (
    <Box
      p={5}
      bg="white"
      borderRadius="lg"
      mb={7}
      border="1px"
      borderColor={color}
    >
      <Text fontFamily="Avenir Black">Your password must include:</Text>
      <UnorderedList variant={variant}>
        <ListItem>
          <ListIcon as={CheckIcon} mb={1} />
          At least 8 characters long
        </ListItem>
        <ListItem>
          <ListIcon as={CheckIcon} mb={1} />
          At least one number
        </ListItem>
        <ListItem>
          <ListIcon as={CheckIcon} mb={1} />
          A mix of uppercase and lowercase characters
        </ListItem>
        <ListItem>
          <ListIcon as={CheckIcon} mb={1} />
          Both passwords must match
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

export default RuleList;
