import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axios from "axios";
import { baseQueryWithReauth } from "src/services";

import {
  CHARGER_HISTORY_PARAMS,
  ChargerHistoryApi,
} from "./charger-history.type";

const handleChargerHistoryParams = (data: CHARGER_HISTORY_PARAMS) => {
  let params = "";
  data?.companyIdList?.forEach((item) => {
    if (item) {
      params += "&companyId[]=" + item
    }
  })
  data?.chargeStationIdList?.forEach((item) => {
    if (item) {
      params += "&chargeStationId[]=" + item
    }
  })
  data?.statusList?.forEach((item) => {
    if (item) {
      params += "&status[]=" + item
    }
  })
  data?.startDay && (params += "&startDay=" + data.startDay)
  data?.stopDay && (params += "&stopDay=" + data.stopDay)
  data?.timezone && (params += "&timezone=" + data.timezone)
  return params
}

export const chargerHistoryAPI = createApi({
  reducerPath: "chargerHistoryAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getChargerHistory: builder.query<any, CHARGER_HISTORY_PARAMS>({
      query: (body) => {
        const params = handleChargerHistoryParams(body)
        return {
          url: `${ChargerHistoryApi.GET_CHARGER_HISTORY}?take=${body?.take || 10}&page=${body.page}${params}`,
          method: "GET",
        }
      },
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useLazyGetChargerHistoryQuery,
} = chargerHistoryAPI;

export const exportChargerHistoryCsv = async (data: CHARGER_HISTORY_PARAMS) => {
  const params = handleChargerHistoryParams(data)
  return await axios({
    method: 'GET',
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    url: `${process.env.REACT_APP_API_URL}${ChargerHistoryApi.EXPORT_CHARGER_HISTORY_CSV}${params}`,
    responseType: 'blob'
  })
    .then(response => {
      if (response) {
        const file = new Blob([response.data], { type: 'image/png' })
        return file
      }
      return Promise.reject();
    });
}