import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { ReactComponent as AddIcon } from "src/assets/icon/add-icon.svg";
import { FormValues } from "./add-new-connector.type";
import { useForm, useFieldArray } from "react-hook-form";
import AddConnectorComponent from "../../../components/modal/add-connector/add-connector.modal";
import { useCreateConnectorMutation } from "src/hook/connector/connector";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./add-connector.schema";
import { useGetChargeStationQuery } from "src/hook/charge-station/charge-station";
import { getToastAttribute, ToastAttribute } from "src/constants";

interface Props {
  idCharge: string | undefined;
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

const AddNewConnectorComponent = (props: Props) => {
  const { isOpen, onClose, idCharge, refetch } = props;
  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };
  const { data } = useGetChargeStationQuery(idCharge);
  const [createConnector, { isLoading }] = useCreateConnectorMutation();
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      connectors: [
        {
          rate: { id: "07af1f5d-9e59-4048-a3d3-20d06b1873f7" },
          format: "Cable",
          type: "CCS1",
          powerType: "DC",
          power: "",
        },
      ],
    },
    resolver: yupResolver(schema),
  });
  const [formCount, setFormCount] = useState(1);
  const { fields, append, remove } = useFieldArray<any>({
    control,
    name: "connectors",
  });

  const onSubmit = (data) => {
    const newData = data.connectors.map((item) => {
      return { ...item, power: parseFloat(item.power) };
    });
    createConnector({ body: { connectors: newData }, id: idCharge })
      .unwrap()
      .then(() => {
        showToast({
          title: "Connector creation.",
          dis: "Connector is successfully created.",
        });
        refetch();
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Connector creation.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  const handleAddForm = () => {
    if (
      data?.data?.isSimulator === true &&
      data?.data?.connectors.length < 1 &&
      formCount < 1
    ) {
      append({
        rate: { id: "07af1f5d-9e59-4048-a3d3-20d06b1873f7" },
        format: "Cable",
        type: "CCS1",
        powerType: "DC",
        power: 0,
      });
      setFormCount(formCount + 1);
    } else if (
      data?.data?.isSimulator === false &&
      data?.data?.connectors.length < 3 &&
      formCount < 3 - data?.data?.connectors.length
    ) {
      append({
        rate: { id: "07af1f5d-9e59-4048-a3d3-20d06b1873f7" },
        format: "Cable",
        type: "CCS1",
        powerType: "DC",
        power: 0,
      });
      setFormCount(formCount + 1);
    } else {
      toast({
        title: "Maximum connectors",
        description: "Maximum connectors reached!",
        status: "info",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleRemoveForm = (index) => {
    setFormCount(formCount - 1);
    remove(index);
  };

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          maxH={"83%"}
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"86px"}
          m={0}
          overflow={"scroll"}
          overflowX={"hidden"}
          css={{
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              width: "6px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "gray",
              borderRadius: "20px",
            },
          }}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"} pb={"0px"}>
            <Flex alignItems={"center"} gap={"24px"}>
              <Text fontSize="24px" fontFamily={"Avenir Black"}>
                Add Connector
              </Text>
              <AddIcon cursor={"pointer"} onClick={handleAddForm} />
            </Flex>

            <form onSubmit={handleSubmit(onSubmit)}>
              <Box maxW={"67%"}>
                {fields.map((field, index) => (
                  <AddConnectorComponent
                    key={field.id}
                    index={index}
                    register={register}
                    removeForm={handleRemoveForm}
                    errors={errors}
                  />
                ))}
              </Box>
              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  variant={"outline"}
                  borderColor={"#020E20"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  fontSize={"20px"}
                  onClick={() => reset()}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isDisabled={formCount === 0 || isLoading}
                  isLoading={isLoading}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                >
                  Create
                </Button>
              </Box>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddNewConnectorComponent;
