import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { getConnectorQRCode } from "src/hook/connector/connector";
import { ConnectorType } from "src/template/network/charge-stations/charge-stations.type";
interface Props {
  connectorId: ConnectorType | null;
  isOpen: boolean;
  onClose: () => void;
}

const ConnectorDetailsComponent = (props: Props) => {
  const toast = useToast();
  const { isOpen, onClose, connectorId } = props;
  const cancelRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Connector details
            </Text>
            {connectorId && (
              <Box color={"#2E3F51"} fontSize={"18px"}>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"} flexShrink={0}>
                    ID
                  </Text>
                  <Text fontFamily={"Avenir Book"}>{connectorId?.id}</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"} flexShrink={0}>
                    Status
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {connectorId?.status}
                  </Text>
                </Box>
                <Box h={"1px"} w="full" my={"20px"} bgColor={"#BEC3C7"}></Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Format
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {connectorId?.format}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Power type
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {connectorId?.powerType}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Type
                  </Text>
                  <Text fontFamily={"Avenir Book"}>
                    {connectorId?.type}
                  </Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Created at
                  </Text>
                  <Text fontFamily={"Avenir Book"}>{connectorId?.createdAt}</Text>
                </Box>
                <Box display={"flex"} alignItems={"center"} mb={"16px"}>
                  <Text fontFamily={"Avenir Heavy"} w={"25%"}>
                    Updated at
                  </Text>
                  <Text fontFamily={"Avenir Book"}>{connectorId?.updatedAt}</Text>
                </Box>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontFamily={"Avenir Black"}
                  mb={"25px"}
                >
                  <Button
                    variant={"outline"}
                    borderColor={"#020E20"}
                    w={"300px"}
                    height={"54px"}
                    borderRadius={"37px"}
                    mr={"16px"}
                    fontSize={"20px"}
                    onClick={() => {
                      setIsLoading(() => true);
                      getConnectorQRCode(connectorId?.id)
                        .then((blob) => {
                          const blogUrl = window.URL.createObjectURL(
                            new Blob([blob])
                          );
                          const fileName = (connectorId?.id || "") + ".png";
                          const aTag = document.createElement("a");
                          aTag.href = blogUrl;
                          aTag.setAttribute("download", fileName);
                          document.body.appendChild(aTag);
                          aTag.click();
                          aTag.remove();
                          setIsLoading(() => false);
                        })
                        .catch(() => {
                          toast({
                            title: "Download QR Code",
                            description: "Download QR Code failed",
                          });
                          setIsLoading(() => false);
                        });
                    }}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  >
                    Download QR code
                  </Button>
                </Box>
              </Box>
            )}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default ConnectorDetailsComponent;
