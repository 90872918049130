export enum CompanyApi {
  GET_COMPANY = "/company",
}



export interface CompanyType {
  companyName?: string;
  createdAt: string;
  email?: string;
  id: string;
  phoneNumber?: string | number;
  updatedAt: string

}
