import * as yup from "yup";
import { PASSWORD_REGEX } from "src/constants/validate";

export const schema = yup.object().shape({
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  password: yup.string().matches(PASSWORD_REGEX, "Please enter a valid password"
  ).required("Password is required"),
  phoneNumber: yup.string(),
  role: yup.string(),
  active: yup.string(),
});
