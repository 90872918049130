import chakraTheme from "@chakra-ui/theme";

const { List } = chakraTheme.components;
const orderListNumber = {
  container: {
    counterReset: "counter-number",
  },
  item: {
    pl: 4,
    ml: 3,
    mb: 6,
    counterIncrement: "counter-number",
    "&::marker": {
      content: "counter(counter-number) ')'",
      fontWeight: "bold",
      fontSize: "md",
    },
  },
};

const orderListLowerText = {
  container: {
    mt: 5,
  },
  item: {
    ml: 4,
  },
};

const successValidate = {
  container: {
    marginInlineStart: "0",
    WebkitMarginStart: "0",
  },
  item: {
    "&::marker": {
      fontSize: "0",
    },
  },
  icon: {
    color: "green.500",
  },
};

const failValidate = {
  item: {
    color: "red.550",
    "&::marker": {
      color: "red.550",
    },
  },
  icon: {
    display: "none",
  },
};

const defaultValidate = {
  icon: {
    display: "none",
  },
};

const ListTheme = {
  ...List,
  variants: {
    orderListNumber,
    orderListLowerText,
    successValidate,
    failValidate,
    defaultValidate,
  },
};

export default ListTheme;
