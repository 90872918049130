import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";

import { AUTH_BODY, AUTH_CHECK_LINK_RESET_BODY, AUTH_FORGOT_PASSWORD_BODY, AUTH_RESET_PASSWORD_BODY, AuthApi } from "./auth.type";

export const authAPI = createApi({
  reducerPath: "authAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    loginUser: builder.mutation<any, AUTH_BODY>({
      query: (users) => ({
        url: AuthApi.LOGIN,
        method: "POST",
        body: users,
      }),
    }),
    signUpUser: builder.mutation<any, AUTH_BODY>({
      query: (users) => ({
        url: AuthApi.SIGN_UP,
        method: "POST",
        body: users,
      }),
    }),
    forgotPasswordUser: builder.mutation<any, AUTH_FORGOT_PASSWORD_BODY>({
      query: (users) => ({
        url: AuthApi.FORGOT_PASSWORD,
        method: "POST",
        body: users,
      }),
    }),
    checkLinkResetUser: builder.mutation<any, AUTH_CHECK_LINK_RESET_BODY>({
      query: (users) => ({
        url: AuthApi.CHECK_LINK_RESET,
        method: "POST",
        body: users,
      }),
    }),
    resetPasswordUser: builder.mutation<any, AUTH_RESET_PASSWORD_BODY>({
      query: (users) => ({
        url: AuthApi.RESET_PASSWORD,
        method: "POST",
        body: users,
      }),
    }),
  }),
});

export const { useLoginUserMutation, useSignUpUserMutation, useForgotPasswordUserMutation, useCheckLinkResetUserMutation, useResetPasswordUserMutation } = authAPI;
