export const downloadCsv = (blob: any, filename: string) => {
    const blogUrl = window.URL.createObjectURL(
        new Blob([blob])
    );
    const fileName = (filename || "") + ".csv";
    const aTag = document.createElement("a");
    aTag.href = blogUrl;
    aTag.setAttribute("download", fileName);
    document.body.appendChild(aTag);
    aTag.click();
    aTag.remove();
}