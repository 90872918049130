import { Box, Flex, Input, Select, Text } from "@chakra-ui/react";
import { ReactComponent as DeleteIcon } from "src/assets/icon/delete-icon.svg";
import {
  CONNECTOR_TYPE,
  CONNECTOR_FORMAT,
  CONNECTOR_POWER_TYPE,
} from "src/constants/connector";
interface Props {
  index: number;
  register: any;
  removeForm: any;
  errors: any;
}

const AddConnectorComponent = (props: Props) => {
  const { index, register, removeForm, errors } = props;

  return (
    <>
      <Box bg={"#dae3f5"} p={"25px"} borderRadius={"8px"} mt={"20px"}>
        <Flex justifyContent={"space-between"}>
          <Text fontSize={"15px"} fontFamily={"Avenir Heavy"}>
            Connector {index + 1}
          </Text>
          <DeleteIcon cursor={"pointer"} onClick={() => removeForm(index)} />
        </Flex>
        <Box color={"#171E38"} top={"50%"} m={0}>
          <Box pt={"30px"} pb={"0px"}>
            {/* <Box
              display={"flex"}
              mb={"40px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                Rate<span style={{ color: "#E74D3D" }}>*</span>
              </Text>
              <Select
                w={"63.7%"}
                border={"0px"}
                bg={"white"}
                borderRadius={"10px"}
                variant={"base"}
                size={"lg"}
                height={"45px"}
                fontFamily={"Avenir Book"}
                fontSize={"18px"}
                {...register(`connectors.${index}.rate.id`)}
              >
                <option
                  style={{
                    fontSize: "18px",
                    fontFamily: "Avenir Book",
                  }}
                >
                  07af1f5d-9e59-4048-a3d3-20d06b1873f7
                </option>
              </Select>
            </Box> */}

            <Box
              display={"flex"}
              mb={"40px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                Format<span style={{ color: "#E74D3D" }}>*</span>
              </Text>
              <Select
                w={"63.7%"}
                border={"0px"}
                bg={"white"}
                borderRadius={"10px"}
                variant={"base"}
                size={"lg"}
                height={"45px"}
                fontFamily={"Avenir Book"}
                fontSize={"18px"}
                {...register(`connectors.${index}.format`)}
              >
                {CONNECTOR_FORMAT.map((item) => (
                  <option
                    key={item.id}
                    style={{
                      fontSize: "18px",
                      fontFamily: "Avenir Book",
                    }}
                  >
                    {item.value}
                  </option>
                ))}
              </Select>
            </Box>

            <Box
              display={"flex"}
              mb={"40px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                Type<span style={{ color: "#E74D3D" }}>*</span>
              </Text>
              <Select
                required
                w={"63.7%"}
                border={"0px"}
                bg={"white"}
                borderRadius={"10px"}
                variant={"base"}
                size={"lg"}
                height={"45px"}
                fontFamily={"Avenir Book"}
                fontSize={"18px"}
                {...register(`connectors.${index}.type`)}
              >
                {CONNECTOR_TYPE.map((item) => (
                  <option
                    key={item.id}
                    style={{
                      fontSize: "18px",
                      fontFamily: "Avenir Book",
                    }}
                  >
                    {item.value}
                  </option>
                ))}
              </Select>
            </Box>

            <Box
              display={"flex"}
              mb={"40px"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                Power type<span style={{ color: "#E74D3D" }}>*</span>
              </Text>
              <Select
                required
                w={"63.7%"}
                border={"0px"}
                bg={"white"}
                borderRadius={"10px"}
                variant={"base"}
                size={"lg"}
                height={"45px"}
                fontFamily={"Avenir Book"}
                fontSize={"18px"}
                {...register(`connectors.${index}.powerType`)}
              >
                {CONNECTOR_POWER_TYPE.map((item) => (
                  <option
                    key={item.id}
                    style={{
                      fontSize: "18px",
                      fontFamily: "Avenir Book",
                    }}
                  >
                    {item.value}
                  </option>
                ))}
              </Select>
            </Box>

            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                Power (kW)<span style={{ color: "#E74D3D" }}>*</span>
              </Text>
              <Box w={"63.7%"}>
                <Input
                  type="number"
                  step="any"
                  onWheel={(event) => event.currentTarget.blur()}
                  w={"100%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  pr={"45px"}
                  _placeholder={{
                    color: "#171E38",
                  }}
                  {...register(`connectors.${index}.power`)}
                />
                {errors?.connectors?.[index]?.power && (
                  <Text
                    fontSize={"12px"}
                    mt={"4px"}
                    ml={"4px"}
                    color={"red.400"}
                    fontFamily={"Avenir Medium"}
                  >
                    {errors?.connectors?.[index]?.power.message}
                  </Text>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddConnectorComponent;
