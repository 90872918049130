import { useEffect, useState } from "react";

export const useTimerCountdown = (time: number) => {
    const [countdown, setCountdown] = useState<number>(time);
    const [isCounting, setIsCounting] = useState(false);

    const minute = Math.floor((countdown % 3600) / 60);
    const second = Math.floor(countdown % 60);
    const fulltime = `${minute < 10 ? '0' + minute : minute}:${second < 10 ? '0' + second : second
        }`;

    useEffect(() => {
        setIsCounting(true);
        const interval = setTimeout(() => {
            if (countdown === 0) {
                setIsCounting(false);
            } else {
                setCountdown((prev) => prev - 1);
            }
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [time, countdown]);

    return { isCounting, fulltime, setCountdown };
};