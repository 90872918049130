import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";

// import { ReactComponent as SuccessIcon } from "src/assets/icon/success-icon.svg";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  statusSuccess: string;
}

const SuccessDeleteComponent = (props: Props) => {
  const { isOpen, onClose, statusSuccess } = props;

  const cancelRef = useRef();

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"42%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody p={"40px"} textAlign={"center"}>
            <Box display={"flex"} justifyContent={"center"} mb={"24px"}>
              {/* <SuccessIcon stroke={"#E54E43"} /> */}
              <Box
                w={"96px"}
                h={"73px"}
                // bg={"#C3D52C 0% 0% no-repeat padding-box"}
                position={"relative"}
                ml={"40px"}
              >
                <Box
                  position="absolute"
                  height="50%"
                  w="17%"
                  bg="#C3D52C"
                  bottom={0}
                  borderRadius={"3px"}
                  transform={"rotate(-45deg)"}
                ></Box>
                <Box
                  position="absolute"
                  height="110%"
                  w="17%"
                  bg="#C3D52C"
                  bottom={"-14px"}
                  left={"37px"}
                  borderRadius={"3px"}
                  transform={"rotate(45deg)"}
                ></Box>
              </Box>
            </Box>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              lineHeight={"55px"}
              mb={"2px"}
            >
              Success!
            </Text>
            <Text fontSize={"16px"} fontFamily={"Avenir Book"} mb={"24px"}>
              {statusSuccess}
            </Text>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              fontFamily={"Avenir Black"}
            >
              <Button
                variant={"solid"}
                bg={"#0A47BA"}
                w={"65%"}
                height={"54px"}
                borderRadius={"37px"}
                mr={"16px"}
                color={"#FFFFFF"}
                fontSize={"20px"}
                _hover={{
                  bg: "#0A47BA",
                  opacity: 0.8,
                }}
                onClick={onClose}
              >
                Back
              </Button>
            </Box>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SuccessDeleteComponent;
