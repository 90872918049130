import TCicon from "src/assets/icon/TCicon.svg";
import PPicon from "src/assets/icon/PPicon.svg";
import TermTab from "src/template/term-policy/temp";
import PolicyTab from "src/template/term-policy/policy";
import {
  Box,
  Divider,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import FooterComponent from "src/components/footer";
import HeaderComponent from "src/components/header";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

function TermsConditions() {
  const [defaultTab, setDefaultTab] = useState<number>();
  const params = useParams();

  useEffect(() => {
    if (params?.param === "terms-conditions") {
      setDefaultTab(1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      setDefaultTab(0);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [params]);

  return (
    <Box bg="blue.850">
      <HeaderComponent />
      <VStack>
        <Box pt="16" px="32" pb="5">
          <Box
            borderRadius={10}
            bg="white"
            pt="20"
            pr="60"
            pb="0.5"
            pl="24"
            position={"relative"}
          >
            <Tabs
              index={defaultTab}
              variant="soft-rounded"
              display={"flex"}
              pt="1.5"
            >
              <TabList display="block" aria-orientation="vertical">
                <Tab
                  fontSize="xl"
                  w="80"
                  display="flex"
                  justifyContent="flex-start"
                  pt="6"
                  pr="10"
                  pb="6"
                  pl="8"
                  _selected={{ background: "#0A47BA", color: "white" }}
                  borderRadius=" 37px 0px 0px 37px"
                  fontFamily={"Avenir Black"}
                  onClick={() => setDefaultTab(0)}
                >
                  <Image src={TCicon} w="7" mr="4" alt={"Tab Icon"} />
                  Privacy Policy
                </Tab>
                <Tab
                  fontSize="xl"
                  w="80"
                  display="flex"
                  justifyContent="flex-start"
                  pt="6"
                  pr="10"
                  pb="6"
                  pl="8"
                  _selected={{ background: "#0A47BA", color: "white" }}
                  borderRadius=" 37px 0px 0px 37px"
                  fontFamily={"Avenir Black"}
                  onClick={() => setDefaultTab(1)}
                >
                  <Image src={PPicon} w="7" mr="4" alt={"Tab Icon"} />
                  Terms & Conditions
                </Tab>
              </TabList>
              <Divider
                orientation="vertical"
                mb="16"
                height="auto"
                color="gray.450"
              />
              <TabPanels pl="16">
                <TabPanel p={0}>
                  <PolicyTab />
                </TabPanel>
                <TabPanel p={0}>
                  <TermTab />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Box>
      </VStack>
      <FooterComponent />
    </Box>
  );
}

export default TermsConditions;
