import { useMemo, useState } from "react";

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;

export const validatePassword = (password) => {
  return PASSWORD_REGEX.test(password);
};

const PASSWORD_USE_CASES = {
  SUCCESS: {
    color: "green.200",
    variant: "successValidate",
  },
  DEFAULT: {
    color: "blue.400",
    variant: "defaultValidate",
  },
  FAIL: {
    color: "red.500",
    variant: "failValidate",
  },
};

export const useValidatePasswords = () => {
  const [password, updatePassword] = useState("");
  const [isMatch, setIsMatch] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(
    PASSWORD_USE_CASES.DEFAULT
  );

  useMemo(() => {
    const checkPasswordValid = validatePassword(password);
    if (checkPasswordValid && isMatch) {
      setPasswordValidation(PASSWORD_USE_CASES.SUCCESS);
    } else if (!password) {
      setPasswordValidation(PASSWORD_USE_CASES.DEFAULT);
    } else {
      setPasswordValidation(PASSWORD_USE_CASES.FAIL);
    }
  }, [password, isMatch]);

  return {
    updatePassword,
    setIsMatch,
    passwordValidation,
  };
};
