import { Box } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import TemplateLayout from "src/components/layout";
import UsersSettingComponent from "src/template/users/user-settings";

const UserSettingsPage = () => {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Users Setting | Documents</title>
        </Helmet>
        <TemplateLayout>
          <Box minHeight={"calc(100vh - 86px)"}>
            <Box
              minHeight={"calc(100vh - 86px)"}
              display={"flex"}
              justifyContent={"center"}
            >
              <UsersSettingComponent />
            </Box>
          </Box>
        </TemplateLayout>
      </HelmetProvider>
    </>
  );
};

export default UserSettingsPage;
