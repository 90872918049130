import { ToastPosition } from "@chakra-ui/react";

export const RoleEnum = Object.freeze({
  SUPER_ADMIN: 1,
  MANAGER: 2,
  STAFF: 3,
});

export const BriefStatusEnum = {
  CREATED: "Created",
  PROCESSING: "Processing",
  BLOCKED: "Blocked",
  CANCELLED: "Cancelled",
  ARCHIVED: "Archived",
  WAITING_TO_RETURN: "Waiting to return",
  COMPLETED: "Completed",
  PROCESSED: "Processed",
};

export interface PaginationType {
  page: number;
  pageCount: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}

const convertTimeNumber = (num: number) => {
  return num < 10 ? "0" + num : "" + num
}

export const convertSeccondToHour = (seccond: number) => {
  if (seccond === null || isNaN(seccond)) {
    return ""
  }
  const value = Math.round(seccond || 0)
  const h = Math.floor(value / 3600)
  const m = Math.floor((value - h * 3600) / 60)
  const s = Math.floor(value - m * 60 - h * 3600)
  return `${convertTimeNumber(h)}:${convertTimeNumber(m)}:${convertTimeNumber(s)}`
}

export const formatDateTime = (timestamp: string) => {
  const date = new Date(timestamp);
  if (isNaN(Date.parse(timestamp))) {
    return "Invalid Date"
  }

  const day = date.getDate();
  const month = date.getMonth() + 1
  const year = date.getFullYear().toString().slice(-2);

  let hour = date.getHours();
  const minute = date.getMinutes();
  const amPM = hour >= 12 ? 'PM' : 'AM';

  if (hour > 12) {
    hour %= 12;
  }
  if (hour === 0) {
    hour = 12;
  }

  return `${hour.toString()}:${minute.toString().padStart(2, '0')}${amPM} ${day}/${month}/${year}`;
}

export const ListPageNumber = (page: number, max: number) => {
  const mang: (number | string)[] = [];
  if (max <= 9) {
    for (let i = 1; i <= max; i++) {
      mang.push(i);
    }
    return mang;
  }
  if (page <= 4) {
    for (let i = 1; i <= page + 2; i++) {
      mang.push(i);
    }
    mang.push("...");
    while (mang.length !== 9) {
      mang.push(max - 8 + mang.length);
    }
    return mang;
  }
  if (page > 4 && page < max - 2) {
    mang.push(1, "...");
    for (let i = page - 2; i <= page + 2; i++) {
      mang.push(i);
    }
    mang.push("...", max);
    return mang;
  }
  if (page >= max - 2) {
    for (let i = page - 2; i <= max; i++) {
      mang.push(i);
    }
    mang.unshift("...");
    for (let i = 9 - mang.length; i >= 1; i--) {
      mang.unshift(i);
    }
    return mang;
  }
}

export interface ToastAttribute {
  title: string;
  dis: string;
  sts?: "error" | "success" | "info" | "warning" | "loading" | undefined;
  dur?: number;
  pos?: ToastPosition;
  isClosable?: boolean;
}
export const getToastAttribute = ({
  title,
  dis,
  sts = "success",
  dur = 2000,
  pos = "top",
  isClosable = true,
}: ToastAttribute) => {
  const result = {
    title: title,
    description: dis,
    status: sts,
    duration: dur,
    position: pos,
    isClosable: isClosable,
  }
  if (sts === "success") {
    return { ...result, variant: "customSuccess" }
  }
  return { ...result }
  // return {
  //   title: title,
  //   description: dis,
  //   status: sts,
  //   duration: dur,
  //   position: pos,
  //   variant: `${sts === "success" ? "customSuccess" : undefined}`,
  //   isClosable: isClosable,
  // };
};