import { useTable, usePagination, useExpanded } from "react-table";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Text,
  Tooltip,
  Box,
  Spinner,
  ScaleFade,
} from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";
import TableComponent from "src/components/custom-table";
import { useLocation } from "react-router-dom";
import { ListPageNumber, PaginationType } from "src/constants";
// import { leftbarList } from "../left-bar/leftbarItems";
import { ReactComponent as NoDataIcon } from "src/assets/icon/no-data-icon.svg";

interface TableProp {
  columns: any;
  childColumns?: any;
  data: any;
  isLoading: boolean;
  name: string;
  setListBrief?: any;
  showFooter?: any;
  setColumnSelect?: any;
  pageIndexDefault?: {
    page: number;
    hasNextPage?: boolean;
    hasPreviousPage?: boolean;
    pageCount?: number;
  };
  listExpand?: string[];
  setPageIndex?: any;
  noDataText?: {
    currentTable: string;
    childTable?: string;
  };
}
const CustomTable = ({
  columns,
  data,
  isLoading,
  showFooter = true,
  pageIndexDefault,
  childColumns,
  listExpand,
  setPageIndex,
  noDataText,
}: TableProp) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
      },
      useExpanded,
      usePagination
    );
  const path = useLocation();
  if (isLoading)
    return (
      <Box
        position={"absolute"}
        left={"50%"}
        top={"50%"}
        transform={"translate(-50%, -50%)"}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Box>
    );

  const handlePreviousPage = () => {
    if (pageIndexDefault && pageIndexDefault?.hasPreviousPage) {
      setPageIndex((pre: PaginationType) => ({ ...pre, page: pre.page - 1 }));
    }
  };

  const handleNextPage = () => {
    if (pageIndexDefault && pageIndexDefault?.hasNextPage) {
      setPageIndex((pre: PaginationType) => ({ ...pre, page: pre.page + 1 }));
    }
  };

  return (
    <Box h={"100%"} w={"100%"}>
      <Box
        w={"full"}
        overflowX={"auto"}
        css={{
          "&::-webkit-scrollbar": {
            height: "6px",
          },
          "&::-webkit-scrollbar-track": {
            height: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray",
            borderRadius: "20px",
          },
        }}
        pb={showFooter ? "80px" : ""}
      >
        <Table {...getTableProps()}>
          <Thead backgroundColor={"#F2F5FC"}>
            {headerGroups.map((headerGroup, index) => (
              <Tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    key={index}
                    {...column.getHeaderProps()}
                    color={"#020D21"}
                    borderBottom={"0px"}
                    textTransform={"none"}
                    fontSize={"18px"}
                    fontFamily={"Avenir Heavy"}
                    whiteSpace={"nowrap"}
                    py={"15px"}
                    _first={{
                      borderRadius: "37px 0 0 37px",
                      pl: listExpand ? "56px" : "",
                    }}
                    _last={{
                      borderRadius: "0 37px 37px 0",
                    }}
                  >
                    {column.render("Header")}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>

          {data.length === 0 && !isLoading ? (
            <Td
              colSpan={columns?.length}
              textAlign="center"
              borderBottom={"0px"}
            >
              {path?.pathname?.includes("session") ? (
                <Flex
                  w={"full"}
                  align={"center"}
                  direction={"column"}
                  pt={"24px"}
                >
                  <NoDataIcon />
                  <Text
                    fontSize={"18px"}
                    mt={"20px"}
                    fontWeight={"500"}
                    color={"#BEC3C7"}
                    fontFamily={"Avenir Heavy"}
                  >
                    {`There's no data recorded currently`}
                  </Text>
                </Flex>
              ) : (
                <Text fontSize={"20px"} mt={"20px"} fontWeight={"500"}>
                  {noDataText?.currentTable || "Data not available"}
                </Text>
              )}
            </Td>
          ) : (
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <>
                    <Tr
                      key={index}
                      {...row.getRowProps()}
                      transition={"all 0.2s ease"}
                      borderRadius={"80px"}
                      rounded={"md"}
                      overflow="hidden"
                      _hover={{
                        bgColor: "#BEC3C710",
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <Td
                            key={index}
                            {...cell.getCellProps()}
                            fontSize={"18px"}
                            fontFamily={"Avenir Book"}
                            lineHeight={"20px"}
                            borderBottom={
                              listExpand?.includes(row?.original?.id)
                                ? ""
                                : "1px solid #BEC3C7"
                            }
                            _first={{
                              borderRadius: "8px 0 0 8px",
                            }}
                            _last={{
                              borderRadius: "0 8px 8px 0",
                            }}
                          >
                            {cell.render("Cell")}
                          </Td>
                        );
                      })}
                    </Tr>
                    {listExpand?.includes(row?.original?.id) &&
                      childColumns && (
                        <Tr transition={"all 0.2s ease"}>
                          <Td colSpan={8} pl={"50px"} pt={"0px"} pr={"0px"}>
                            <ScaleFade initialScale={0.9} in={true}>
                              <TableComponent
                                isLoading={false}
                                columns={childColumns as any}
                                data={row?.subRows}
                                name={"Connection"}
                                showFooter={false}
                                noDataText={{
                                  currentTable:
                                    noDataText?.childTable ||
                                    "Data not available",
                                }}
                              />
                            </ScaleFade>
                          </Td>
                        </Tr>
                      )}
                  </>
                );
              })}
            </Tbody>
          )}
        </Table>
      </Box>

      {showFooter && data?.length > 0 && (
        <Flex
          w={"full"}
          alignItems="center"
          justifyContent={"space-between"}
          mt={"-60px"}
          wrap={"wrap"}
        >
          <Text color={"#020D21"} fontSize={"18px"} fontFamily={"Avenir Book"}>
            {`Showing ${pageIndexDefault?.page ?? 1} out of ${pageIndexDefault?.pageCount ?? 1
              }`}
          </Text>
          <Flex alignItems={"center"} gap={"4px"}>
            <Tooltip label="Previous Page">
              <IconButton
                aria-label=""
                onClick={handlePreviousPage}
                isDisabled={!pageIndexDefault?.hasPreviousPage}
                bg={"none"}
                _disabled={{
                  bg: "none",
                }}
                _hover={{
                  bg: "none",
                }}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
            {ListPageNumber(
              pageIndexDefault?.page || 1,
              pageIndexDefault?.pageCount || 1
            )?.map((item, index) => {
              return (
                <Box
                  key={index}
                  minW={"38px"}
                  px={"3px"}
                  h={"44px"}
                  bgColor={"#F2F5FC"}
                  borderRadius={"10px"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  cursor={"pointer"}
                  onClick={() => {
                    if (item != "..." && item !== pageIndexDefault?.page) {
                      setPageIndex((pre: PaginationType) => ({
                        ...pre,
                        page: item,
                      }));
                    }
                  }}
                >
                  <Text
                    color={
                      item === pageIndexDefault?.page ? "#0A47BA" : "#020D21"
                    }
                    fontFamily={"Avenir Heavy"}
                  >
                    {item}
                  </Text>
                </Box>
              );
            })}
            <Tooltip label="Next Page">
              <IconButton
                aria-label=""
                onClick={handleNextPage}
                bg={"none"}
                _disabled={{
                  bg: "none",
                }}
                _hover={{
                  bg: "none",
                }}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>
        </Flex>
      )}
    </Box>
  );
};

export default CustomTable;
