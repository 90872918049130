export const inputStyles = {
  baseStyle: {
    field: {
      borderRadius: "5px",
      padding: "9.5px 15px",
    },
  },
  sizes: {
    md: {
      field: {
        fontSize: "md",
        lineHeight: "19px",
        border: "1px solid #CACACA",
      },
    },
    sm: {
      field: {
        fontSize: "sm",
        lineHeight: "17px",
      },
    },
    xs: {
      field: {
        fontSize: "xs",
        lineHeight: "14px",
      },
    },
  },
};
