import { Box, Flex, Text, Icon } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { leftbarList } from "./leftbarItems";
import { useAppDispatch, useAppSelector } from "src/redux";
import { handleCollapse } from "src/hook/sidebar/sidebar";
import { useEffect, useMemo, useRef } from "react";
import { FiLogOut } from "react-icons/fi";
import { useGetUserDetailQuery } from "src/hook/user/user";

const LeftBar = () => {
  const pathname = useLocation().pathname;
  const dispatch = useAppDispatch();
  const collapse = useAppSelector((state) => state?.sidebar?.isCollapse);
  const ref = useRef<HTMLDivElement>(null);

  const { data: userDetailData } = useGetUserDetailQuery();

  const menuFilter = useMemo(() => {
    const menuList = leftbarList.map(menu => ({
      ...menu,
      child_list: menu.child_list.filter(child => child.role.includes(userDetailData?.data?.role?.id))
    })).filter(menu => menu.child_list.length > 0);
    return menuList;
  }, [userDetailData?.data?.role?.id])

  const handleCollapseAction = (status: boolean) => {
    dispatch(handleCollapse({ status: status }));
  };

  useEffect(() => {
    const funcEnter = () => handleCollapseAction(false);
    const funcLeave = () => handleCollapseAction(true);

    ref.current?.addEventListener("mouseenter", funcEnter);
    ref.current?.addEventListener("mouseleave", funcLeave);

    return () => {
      ref.current?.removeEventListener("mouseenter", funcEnter);
    };
  }, []);

  return (
    <>
      <Flex
        direction={"column"}
        gap={"24px"}
        justifyContent={"space-between"}
        ref={ref}
        transition={"all 0.3s ease"}
        w={collapse ? "80px" : "280px"}
        maxH={"calc(100vh - 86px)"}
        h={"calc(100vh - 86px)"}
        color={"#ecf0f1"}
        display={"flex"}
        flexFlow={"column"}
        onClick={() => handleCollapseAction(false)}
        letterSpacing={"1px"}
        overflowY={"hidden"}
        pb={"30px"}
        pl={collapse ? "13px" : "20px"}
        position={"fixed"}
        bgColor={"#020D21"}
        zIndex={100}
        overflowX={"hidden"}
        _hover={{
          overflowY: "auto",
        }}
        css={{
          "&::-webkit-scrollbar": {
            width: "6px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "gray",
            borderRadius: "20px",
          },
        }}
      >
        <Box mt={"20px"}>
          {menuFilter.map((menu, index) => {
            const menuNameLowcase = menu.name.toLowerCase();
            return (
              <Box
                key={index}
                mt={"20px"}
                _first={{ mt: "0px" }}
                width={collapse ? "60px" : "full"}
              >
                <Link
                  to={`/${menuNameLowcase}/${menu.child_list[0].name
                    .toLowerCase()
                    .split(" ")
                    .join("-")}`}
                >
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={collapse ? "center" : ""}
                    pl={collapse ? "0px" : "20px"}
                    bgColor={
                      pathname.includes(menuNameLowcase) ? "#C3D33E" : "unset"
                    }
                    w={collapse ? "54px" : "full"}
                    height={"54px"}
                    borderRadius={collapse ? "50%" : "100px 0px 0px 100px"}
                    cursor={"pointer"}
                  >
                    <Box h={"27px"} w={"27px"} mr={collapse ? "" : "20px"}>
                      <menu.icon
                        fill="#fcfcfc"
                        stroke={
                          pathname.includes(menuNameLowcase)
                            ? "#124BB7"
                            : "#FCFCFC"
                        }
                      />
                    </Box>
                    {!collapse && (
                      <Text
                        fontSize={"20px"}
                        color={
                          pathname.includes(menuNameLowcase)
                            ? "#124BB7"
                            : "#FFFFFF"
                        }
                        fontFamily={"Avenir Black"}
                        textTransform={"capitalize"}
                        lineHeight={"27px"}
                      >
                        {menu.name}
                      </Text>
                    )}
                  </Box>
                </Link>

                {!collapse && (
                  <Box
                    pl={"22px"}
                    height={"full"}
                    opacity={collapse ? "0" : "1"}
                  >
                    {menu.child_list.map((child, child_index) => {
                      const childMenuNameLowCase = child.name
                        .toLowerCase()
                        .split(" ")
                        .join("-");
                      return (
                        <Box
                          key={child_index}
                          display={"block"}
                          pl={"47px"}
                          mt={"15px"}
                          cursor={"pointer"}
                        >
                          <Link
                            to={`/${menuNameLowcase}/${childMenuNameLowCase}`}
                          >
                            <Text
                              fontSize={"20px"}
                              color={
                                pathname.includes(
                                  `${menuNameLowcase}/${childMenuNameLowCase}`
                                )
                                  ? "#C3D52C"
                                  : "#FFFFFF"
                              }
                              fontFamily={"Avenir Medium"}
                              textTransform={"capitalize"}
                              lineHeight={"27px"}
                              whiteSpace={"nowrap"}
                            >
                              {child.name}
                            </Text>
                          </Link>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
        <Flex
          cursor={"pointer"}
          width={collapse ? "60px" : "full"}
          pl={collapse ? "13px" : "20px"}
          transition={"all 0s"}
          onClick={() => {
            handleCollapseAction(false);
            localStorage.removeItem("token");
            window.location.pathname = "/login"
          }}
        >
          <Flex justify={"center"} align={"center"}>
            <Icon w={"27px"} h={"27px"} as={FiLogOut}></Icon>
          </Flex>
          {!collapse && (
            <Text
              ml={"20px"}
              fontSize={"20px"}
              fontFamily={"Avenir Medium"}
              textTransform={"capitalize"}
              lineHeight={"27px"}
            >
              Logout
            </Text>
          )}
        </Flex>
      </Flex>
    </>
  );
};

export default LeftBar;
