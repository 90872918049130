import { Box, Button, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SuccessIcon } from "src/assets/icon/success-icon.svg";

const SuccessPasswordComponent = () => {
  const navigate = useNavigate()
  return (
    <Box
      maxW={"500px"}
      px={"50px"}
      pt={"30px"}
      pb={"30px"}
      bg={"#F2F5FC"}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      rounded={"10px"}
      color={"#020D21"}
    >
      <SuccessIcon />
      <Box textAlign={"center"}>
        <Text
          fontSize={"40px"}
          fontFamily={"Avenir Black"}
          lineHeight={"55px"}
        >
          Success!
        </Text>
        <Text fontFamily={"Avenir Book"} pb="20px">
          You have successfully reset your password
        </Text>
      </Box>
      <Box mx={"13px"}>
        <Button
          fontFamily={"Avenir Black"}
          background={"#0A47BA"}
          borderRadius={"full"}
          h={"54px"}
          fontSize={"20px"}
          w={"full"}
          color={"white"}
          _hover={{
            opacity: 0.8,
          }}
          _disabled={{
            bg: "#BEC3C7",
            _hover: {
              bg: "#BEC3C7",
              opacity: "1",
            },
          }}
          onClick={() => navigate("/login")}
        >
          Go to Log in
        </Button>
      </Box>
    </Box>
  );
};

export default SuccessPasswordComponent;
