import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  Grid,
  GridItem,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { ReactComponent as SearchIcon } from "src/assets/icon/search-icon.svg";
import { schema } from "./add-new-location.schema";
import { FormValues } from "./add-new-location.type";
import { useCreateLocationMutation } from "src/hook/location/location";
import { LIST_COUNTRY } from "src/constants/country";
import { getListTimezone } from "src/constants/timezone";
import {
  getToastAttribute,
  PaginationType,
  ToastAttribute,
} from "src/constants";
import { useGetListCompanyQuery } from "src/hook/company/company";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
  userDetailData?: any;
  setPageIndex?: any;
}

const AddNewLocationComponent = (props: Props) => {
  const { isOpen, onClose, refetch, userDetailData, setPageIndex } = props;
  const [listTimezone, setListTimezone] = useState<string[]>([]);
  const cancelRef = useRef();
  const toast = useToast();
  const showToast = (props: ToastAttribute) => {
    toast(getToastAttribute(props));
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      name: "",
      role: userDetailData?.data?.role?.id,
      company: { id: "" },
      long: 0,
      lat: 0,
      timezone: "UTC+08:00",
      address: {
        streetNumber: "",
        postalCode: "",
        city: "",
        state: "",
        country: "Malaysia",
      },
      notes: "",
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { data: companies } =
    useGetListCompanyQuery();
  const [createLocation, { isLoading }] = useCreateLocationMutation();

  const handleSubmitForm = (data: FormValues) => {
    createLocation(data)
      .unwrap()
      .then(() => {
        showToast({
          title: "Location created.",
          dis: "Location is successfully created.",
        });
        refetch();
        reset();
        setPageIndex &&
          setPageIndex((pre: PaginationType) => ({ ...pre, page: 1 }));
        onClose();
      })
      .catch((err) => {
        showToast({
          title: "Connector update.",
          dis: err?.data?.message,
          sts: "error",
        });
      });
  };

  useEffect(() => {
    setListTimezone(() => getListTimezone());
  }, []);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay bg={"#052A6E"} opacity={"0.7 !important"} />
        <AlertDialogContent
          minW={"56%"}
          color={"#171E38"}
          bg={"#F2F5FC"}
          top={"50%"}
          // left={"50%"}
          transform={"translateY(-50%) !important"}
          m={0}
        >
          <AlertDialogCloseButton
            _focusVisible={{
              outline: "none",
              border: "none",
            }}
            size={"lg"}
          />
          <AlertDialogBody pt={"38px"} pl={"40px"}>
            <Text
              fontSize={"40px"}
              fontFamily={"Avenir Black"}
              mb={"39px"}
              w={"fit-content"}
            >
              Add location
            </Text>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Location name<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Input
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    maxLength={255}
                    {...register("name", {
                      shouldUnregister: true,
                    })}
                  />
                  {errors.name && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.name.message}
                    </Text>
                  )}
                </Box>
              </Box>
              {userDetailData?.data?.role?.id === 1 &&
                <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                  <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                    Company name<span style={{ color: "#E74D3D" }}>*</span>
                  </Text>
                  <Box w={"60%"}>
                    <Select
                      border={"0px"}
                      bg={"white"}
                      borderRadius={"10px"}
                      variant={"base"}
                      size={"lg"}
                      textTransform={"capitalize"}
                      height={"45px"}
                      fontFamily={"Avenir Book"}
                      fontSize={"18px"}
                      {...register("company.id", {
                        shouldUnregister: true,
                      })}
                    >
                      {companies?.data?.map((item) => (
                        item?.companyName && <option key={item?.id} value={item?.id}>{item?.companyName}</option>
                      ))}
                    </Select>
                    {errors.company && (
                      <Text
                        fontSize={"12px"}
                        mt={"4px"}
                        ml={"4px"}
                        color={"red.400"}
                        fontFamily={"Avenir Medium"}
                      >
                        {errors.company.message}
                      </Text>
                    )}
                  </Box>
                </Box>}

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Address<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Grid
                    templateRows="repeat(3, 1fr)"
                    templateColumns="repeat(2, 1fr)"
                    gap={"8px"}
                  >
                    <GridItem rowSpan={1} colSpan={2}>
                      <Input
                        border={"0px"}
                        maxLength={255}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book !important"}
                        fontSize={"18px"}
                        placeholder={"Street name and number"}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.streetNumber", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        placeholder={"City"}
                        maxLength={50}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.city", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        placeholder={"Zip code"}
                        maxLength={10}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.postalCode", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Input
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        placeholder={"State"}
                        maxLength={50}
                        _placeholder={{
                          color: "#171E38",
                        }}
                        {...register("address.state", {
                          shouldUnregister: true,
                        })}
                      />
                    </GridItem>
                    <GridItem colSpan={1}>
                      <Select
                        border={"0px"}
                        bg={"white"}
                        borderRadius={"10px"}
                        variant={"base"}
                        size={"lg"}
                        textTransform={"capitalize"}
                        height={"45px"}
                        fontFamily={"Avenir Book"}
                        fontSize={"18px"}
                        {...register("address.country", {
                          shouldUnregister: true,
                        })}
                      >
                        {LIST_COUNTRY?.map((item, index) => (
                          <option key={index}>{item?.name}</option>
                        ))}
                      </Select>
                    </GridItem>
                  </Grid>
                  {errors.address && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.address.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Timezone<span style={{ color: "#E74D3D" }}>*</span>
                </Text>
                <Box w={"60%"}>
                  <Select
                    border={"0px"}
                    bg={"white"}
                    borderRadius={"10px"}
                    variant={"base"}
                    size={"lg"}
                    textTransform={"capitalize"}
                    height={"45px"}
                    fontFamily={"Avenir Book"}
                    fontSize={"18px"}
                    {...register("timezone", {
                      shouldUnregister: true,
                    })}
                  >
                    {listTimezone?.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </Select>
                  {errors.timezone && (
                    <Text
                      fontSize={"12px"}
                      mt={"4px"}
                      ml={"4px"}
                      color={"red.400"}
                      fontFamily={"Avenir Medium"}
                    >
                      {errors.timezone.message}
                    </Text>
                  )}
                </Box>
              </Box>

              <Box display={"flex"} mb={"24px"} alignItems={"center"}>
                <Text fontFamily={"Avenir Heavy"} fontSize={"18px"} w={"25%"}>
                  Notes
                </Text>
                <Input
                  w={"60%"}
                  border={"0px"}
                  bg={"white"}
                  borderRadius={"10px"}
                  height={"45px"}
                  fontFamily={"Avenir Book"}
                  fontSize={"18px"}
                  {...register("notes")}
                />
              </Box>

              <Box
                mt={"40px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                fontFamily={"Avenir Black"}
                mb={"25px"}
              >
                <Button
                  onClick={() => reset()}
                  variant={"outline"}
                  borderColor={"#020E20"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  fontSize={"20px"}
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant={"solid"}
                  bg={"#0A47BA"}
                  w={"191px"}
                  height={"55px"}
                  borderRadius={"37px"}
                  mr={"16px"}
                  color={"#FFFFFF"}
                  fontSize={"20px"}
                  _hover={{
                    bg: "#0A47BA",
                    opacity: 0.8,
                  }}
                  isLoading={isLoading}
                  disabled={isLoading}
                  _disabled={{
                    bg: "#BEC3C7",
                    _hover: {
                      bg: "#BEC3C7",
                      opacity: "1",
                    },
                  }}
                >
                  Create
                </Button>
              </Box>
            </form>
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default AddNewLocationComponent;
