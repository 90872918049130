import { Flex, HStack, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

const FooterComponent = () => {
  const pathname = useLocation().pathname;

  return (
    <>
      <Flex
        height={"70px"}
        color={"white"}
        justifyContent={"center"}
        alignItems={"center"}
        w={"full"}
        flexShrink={0}
      >
        <HStack spacing={8}>
          <Link to={"/term-policy/privacy-policy"}>
            <Text
              position={"relative"}
              _after={{
                content: '""',
                height: "15px",
                width: "1px",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
                backgroundColor: pathname.includes("create-document")
                  ? "#2e3f51"
                  : "#ecf0f1",
                right: "-1rem",
              }}
            >
              Privacy policy
            </Text>
          </Link>
          <Link to={"/term-policy/terms-conditions"}>
            <Text>Terms & conditions</Text>
          </Link>
        </HStack>
      </Flex>
    </>
  );
};

export default FooterComponent;
