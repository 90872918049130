import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "src/services";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  PlaygroundApi,
} from "./playground.type";

export const playgroundAPI = createApi({
  reducerPath: "playgroundAPI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getConnector: builder.query<any, void>({
      query: () => ({
        url: PlaygroundApi.GET_CONNECTOR,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getListSessionStarted: builder.query<any, any>({
        query: (body) => ({
          url: `${PlaygroundApi.GET_SESSION_STARTED}?status=${body}`,
          method: "GET",
        }),
        keepUnusedDataFor: 0,
      }),
    getSession: builder.query<any, void>({
      query: () => ({
        url: PlaygroundApi.GET_SESSION,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetConnectorQuery,
  useGetListSessionStartedQuery,
  useGetSessionQuery
} = playgroundAPI;

interface playgroundState {
  data: any
  dataSession: any
  case: any
}

const initialState: playgroundState = {
  data: [],
  dataSession: [],
  case: ""
}


const playgroundSlice = createSlice({
  name: "playground",
  initialState,
  reducers: {
    getDataPlaygroundStart(state, action: PayloadAction) {
      state.data = action?.payload;
      state.case = "start"
    },
    getDataPlaygroundStop(state, action: PayloadAction) {
      state.data = action?.payload;
      state.case = "stop"
    },
    getDataPlayground(state, action: PayloadAction) {
      state.dataSession = action?.payload;
      state.case = "session"
    },
  },
});

export const { getDataPlaygroundStart, getDataPlaygroundStop, getDataPlayground } = playgroundSlice.actions;
export default playgroundSlice;
