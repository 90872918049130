import * as yup from "yup";

const schema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
      "Please enter a valid e-mail address"
    )
    .required("Email is required")
});

export default schema;
