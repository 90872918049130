import { RoleEnum } from "src/constants";
import { ReactComponent as NetworkIcon } from "src/assets/icon/network-icon.svg";
import { ReactComponent as LogsIcon } from "src/assets/icon/logs-icon.svg";
import { ReactComponent as DevelopersIcon } from "src/assets/icon/developers-icon.svg";
import { ReactComponent as UsersIcon } from "src/assets/icon/users-icon.svg";

export const netWorkItems = [
    {
        name: 'dashboard',
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    },
    {
        name: 'location',
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    },
    {
        name: 'charge stations',
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    }
]
export const logItems = [
    {
        name: 'Sessions',
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    },
    {
        name: 'OCPP logs',
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    },
    {
        name: 'Charger History',
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    }
]
export const devItems = [
    {
        name: "API tokens",
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER]
    },
    {
        name: "Playground",
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER]
    },
    {
        name: "webhooks",
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    }
]
export const userItems = [
    {
        name: "user settings",
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER]
    },
    {
        name: "account details",
        role: [RoleEnum.SUPER_ADMIN, RoleEnum.MANAGER, RoleEnum.STAFF]
    },
]

export const leftbarList = [
    {
        name: "network",
        icon: NetworkIcon,
        child_list: netWorkItems
    },
    {
        name: "logs",
        icon: LogsIcon,
        child_list: logItems
    },
    {
        name: "developers",
        icon: DevelopersIcon,
        child_list: devItems
    },
    {
        name: "users",
        icon: UsersIcon,
        child_list: userItems
    },
];