import { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useParams, useLocation } from "react-router-dom";

import TemplateLayout from "src/components/layout";
import ResetPasswordComponent from "src/template/reset-password";
import ExpiredLinkComponent from "src/template/error-password";
import SuccessPasswordComponent from "src/template/success-password";
import { useCheckLinkResetUserMutation } from "src/hook/auth/auth";
import ResendEmailComponent from "src/template/resend-email";

const ResetPasswordPage = () => {
  const [modal, setModal] = useState("reset")
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const [checkLinkResetUser] = useCheckLinkResetUserMutation();

  useEffect(() => {
    if (token && email) {
      checkLinkResetUser({ token, email })
        .unwrap()
        .then(() => {
          setModal("reset")
        })
        .catch(() =>
          setModal("error")
        );
    };
  }, [token, email]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Reset Password</title>
        </Helmet>
        <TemplateLayout>
          <Box height={"calc((100vh - 100px) - 70px)"}>
            <Box
              height={"full"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              {modal === "reset" && <ResetPasswordComponent email={email as string} token={token as string} setModal={setModal} />}
              {modal === "error" && < ExpiredLinkComponent setModal={setModal} email={email as string} />}
              {modal === "resend" && <ResendEmailComponent email={email as string} />}
              {modal === "success" && <SuccessPasswordComponent />}
            </Box>
          </Box>
        </TemplateLayout>
      </HelmetProvider>
    </>
  );
};

export default ResetPasswordPage;
